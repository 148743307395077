import React from 'react'
import { useParams } from 'react-router-dom'
import ShopVideos from '../../shop/ShopVideos'

const ManageShopVideos = () => {
  const { shopId } = useParams()

  return (
    <ShopVideos shopId={shopId}/>
  )
}

export default ManageShopVideos

ManageShopVideos.propTypes = {}
