import React, { useEffect, useMemo, useState } from 'react'
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable
} from 'react-table'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash/lang'
import { keys } from 'lodash/object'
import { Search } from '@styled-icons/material'
import {
  selectOrganizationId
} from '../../../app/selectors'
import {
  useLazyGetManagersQuery,
  usePostDeleteManagersMutation
} from '../../../api/api.generated'
import {
  DashboardInfoWrap,
  ManageEntityDashboardWrap,
  ButtonWrap
} from '../../../global/styles'
import { Toolbar  } from '../../shops/ManageShops/styles'
import { getEntityPropertyByIds, getErrorMessage } from '../../../utils/helpers'
import { getMetrics, tableColumns } from '../helpers/customersHelpers'
import { INPUT_SIZES, BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import DashboardHeader from '../../../components/DashboardHeader'
import MetricItem from '../../../components/MetricItem/MetricItem'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button/Button'
import CustomTextInput from '../../../components/UI/Inputs/TextInput/CustomTextInput'
import TableCheckboxCell from '../../../components/TableCheckboxCell'
import PaginatedEntityTable from '../../../components/EntityTable/PaginatedEntityTable'
import DetailsAsidePanel from '../../../components/DetailsAsidePanel'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import TableButtonCell from '../../../components/TableButtonCell'
import CustomerDetails from '../CustomerDetails'
import CustomersTableRow from '../CustomersTableRow'
import AddManagerModal from '../AddManagerModal'
import { MetricsItem } from './styles'

const ManageManagers = () => {
  const organizationId = useSelector(selectOrganizationId)
  const [showAddNewModal, setShowAddNewModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [deleteOneId, setDeleteOneId] = useState(null)
  const [error, setError] = useState('')
  const [deleteError, setDeleteError] = useState('')
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [metrics, setMetrics] = useState([])
  const [activeCustomerId, setActiveCustomerId] = useState(null)
  const [getManagers, { data: customersData, isLoading, error: customerError }] = useLazyGetManagersQuery()
  const [deleteSelectedIds, { isLoading: deletingItems }] = usePostDeleteManagersMutation()  
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [search, setSearch] = useState('')

  const columns = useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
      customersData
        ? customersData?.users?.map((customer) => CustomersTableRow(customer))
        : [],
    [customersData]
  )

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { selectedRowIds, pageIndex, pageSize },
    pageOptions,
    toggleAllPageRowsSelected,
    toggleAllRowsSelected,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.id,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
  
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
          Cell: TableCheckboxCell
        },
        ...columnsList,
        {
          id: 'deleteIcon',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableButtonCell
              cell={cell}
              handleSetIdToDelete={setDeleteOneId}
              handleShowEditModal={setShowEditModal}
              handleShowConfirmModal={() => {
                setDeleteError("")
                setShowConfirmDelete(true)
              }}
              canEdit
            />
          )
        }
      ])
    }
  )
  
  const loadManagers = () => {
    setError('')
    getManagers({ page: pageIndex, limit: pageSize, search })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  const handleShowCustomerDetails = (customerId) => {
    if (customerId !== activeCustomerId) {
      setShowDetails(true)
      setActiveCustomerId(customerId)
    } else {
      setShowDetails(false)
      setActiveCustomerId(null)
    }
  }

  const handleCloseDetails = () => {
    setShowDetails(false)
    setActiveCustomerId(null)
  }

  const handleCloseConfirmationModal = () => {
    toggleAllPageRowsSelected(false)
    toggleAllRowsSelected(false)
    setShowConfirmDelete(false)
    setDeleteOneId(null)
  }

  const handleDeleteSelected = () => {
    setError("");
    setDeleteError("");
    if (deleteOneId) {
      deleteSelectedIds({ body: { usersIdsToDelete: [deleteOneId] } })
        .unwrap()
        .then(() => {
          handleCloseConfirmationModal()
          handleCloseDetails()
        })
        .catch((err) => {
          setDeleteError(getErrorMessage(err))
        })
      return
    }
    if (!isEmpty(selectedRowIds)) {
      deleteSelectedIds({ body: { ids: keys(selectedRowIds) } })
        .unwrap()
        .then(() => {
          handleCloseConfirmationModal()
          handleCloseDetails()
        })
        .catch((err) => {
          setDeleteError(getErrorMessage(err))
        })
    }
  }

  const handleShowAddNewModal = () => {
    setError("");
    setShowAddNewModal(true)
  }

  const handleCloseAddNewModal = () => {
    setShowAddNewModal(false)
  }

  const handleCloseEditModal = () => {
    setError("");
    setShowEditModal(false)
    setDeleteOneId(null)
  }

  const handleSearch = () => {
    if (pageIndex === 0) {
      loadManagers()
    } else {
      gotoPage(0)
    }
  }
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    loadManagers();
  }, [])

  useEffect(() => {
    if (customersData) {
      setMetrics(getMetrics(customersData.dashboardInfo))
      setTotalPages(customersData.pagination.pages)
      setTotalRows(customersData.pagination.total)
      if (pageIndex >= customersData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [customersData])

  useEffect(() => {
    if (customersData) {
      loadManagers()
    }
  }, [pageIndex, pageSize]);

  return (
    <ManageEntityDashboardWrap>
      <DashboardInfoWrap>
        <DashboardHeader
          headline="Managers"
          handleDelete={() => {
            setDeleteError("")
            setShowConfirmDelete(true)
          }}
          showDelete={false}
          disableDelete={deletingItems || isEmpty(selectedRowIds)}
          addNewLabel="Add User"
          handleAddNew={handleShowAddNewModal}
          showAddButton={!customerError}
        />
        {isLoading && <LoadingSpinner />}
        {error && 
          <Toolbar>
            <Typography variant="textS" color="red">{error}</Typography>
          </Toolbar>
        }
        {customerError && 
          <Toolbar>
            <Typography variant="textS" color="red">Network Error</Typography>
          </Toolbar>
        }
        {customersData && (
          <>
            <MetricsItem>
              {metrics.map((metric) => (
                <MetricItem
                  key={metric.metricName}
                  description={metric.description}
                  icon={metric.icon}
                  iconBgColor={metric.iconBgColor}
                  id={metric.metricName}
                  isActive={metric.isActive}
                  metricName={metric.metricName}
                  numberAndIconColor={metric.numberAndIconColor}
                  onClick={metric.onClick}
                  value={metric.value}
                />
              ))}
            </MetricsItem>
            <Toolbar>
              <CustomTextInput
                handleKeyPress={handleKeyPress}
                handleChange={(e) => {
                  setSearch(e.target.value)
                  // setGlobalFilter(e.target.value)
                }}
                placeholder="Search user by name, email or phone number"
                inputName="search"
                size={INPUT_SIZES.SMALL}
                value={search}
                leftIcon={<Search />}
                isWhite
              />
              <ButtonWrap>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </ButtonWrap>              
            </Toolbar>
            <PaginatedEntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                pageOptions,
                pageIndex,
                gotoPage,
                totalRows
              }}
              handleShowDetails={handleShowCustomerDetails}
              activeItemId={activeCustomerId}
            />
          </>
        )}
      </DashboardInfoWrap>
      {showDetails &&
      <DetailsAsidePanel isOpen={showDetails} closePanel={handleCloseDetails}>
        <CustomerDetails
          {...{ activeCustomerId }}
          closePanel={handleCloseDetails}
        />
      </DetailsAsidePanel>
      }
      {showAddNewModal && 
      <AddManagerModal
        isOpen={showAddNewModal}
        onClose={handleCloseAddNewModal}
        isCreate
        showOrganization={organizationId == null}
      />
      }
      {showEditModal && 
      <AddManagerModal
        isOpen={showEditModal}
        onClose={handleCloseEditModal}
        isCreate={false}
        userId={deleteOneId}
        showOrganization={false}
      />
      }      
      {showConfirmDelete && (
        <DeleteConfirmationModal
          isOpen={showConfirmDelete}
          onClose={handleCloseConfirmationModal}
          entityName={getEntityPropertyByIds(
            selectedRowIds,
            deleteOneId,
            customersData?.users,
            'fullName'
          )}
          entityType="Manager"
          handleDelete={handleDeleteSelected}
          error={deleteError}
        />
      )}
    </ManageEntityDashboardWrap>
  )
}

export default ManageManagers

ManageManagers.propTypes = {}
