import styled from 'styled-components'
import Typography from '../../../components/UI/Typography'

export const TopBannerWrapper = styled.section`
  box-shadow: 0 6px 16px -4px rgba(15, 18, 20, 0.1);
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  flex-grow: 0;
  max-height: 231px;
  min-height: 231px;
  margin-bottom: 24px;
  overflow: hidden;
`
export const ShopDetailsHead = styled.div`
  width: 100%;
  max-height: 104px;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export const ShopNameAndLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export const ShopNameAndAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 24px;
`
export const ShopAddressWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.skyBase};
`
export const ShopAddress = styled(Typography)`
  font-weight: 400;
`
export const ButtonWrap = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
`
export const ShopName = styled(Typography)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.skyNeutral_2};
`
export const stub = styled.div``
