import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

// eslint-disable-next-line import/prefer-default-export
export const Headline = styled(Typography).attrs({
  variant: 'headingM',
  bold: true
})`
  margin-bottom: 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
export const ModalBody = styled(VStack)`
  align-items: stretch;
  width: 90vw;
  justify-content: flex-start;
  padding: 32px;
`
export const HoursWrapper = styled(VStack)`
  height: 280px;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
`
export const OpenHoursWrap = styled(VStack)`
  height: 234px;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
`
export const WorkingDayWrap = styled(HStack)`
  margin-bottom: 20px;
  max-width: 50%;
  flex-wrap: wrap;
  align-items: center;
  min-width: 46%;
  justify-content: space-between;
  &:nth-child(-n + 4) {
    padding-right: 10px;
  }
  &:nth-child(n + 5) {
    padding-left: 10px;
  }
`
export const OperationalHoursErrorWrap = styled.div`
  padding-bottom: 20px;
`
export const SwitchInputWrapper = styled.div`
  width: 180px;
  label:first-child {
    justify-content: left;
  }
`
export const ErrorWrap = styled(HStack)`
  padding: 0;
  max-width: 50vw;
  align-items: stretch;
`
export const ButtonWrap = styled(HStack)`
  width: 100%;
  margin-top: 24px;
  justify-content: space-between;
  & > :first-child {
    margin-right: 12px;
  }
`
export const ShopDetailsHead = styled.div`
  width: 100%;
  max-height: 104px;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
