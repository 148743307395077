import React from 'react'
import PropTypes from 'prop-types'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsPanelTopSection
} from '../../../global/styles'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Typography from '../../../components/UI/Typography'
import { useGetOrderQuery } from '../../../api/api.generated'
import {
  ShopDetailsWrap,
  ShopInfoBlock
} from './styles'

const OrderDetails = ({ activeId }) => {
  const { data: orderData, isLoading, error: loadError } = useGetOrderQuery(activeId)

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {loadError && 
        <Typography variant="textS" color="red">Network Error</Typography>
      }        
      {orderData && (
        <>
          <DetailsPanelTopSection>
            <AsideDetailsTitle align="center">Order Details</AsideDetailsTitle>
          </DetailsPanelTopSection>
          <ShopDetailsWrap>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Order #</AsideDetailsLabel>
              <AsideDetailsDescription>
                {orderData.code}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Date</AsideDetailsLabel>
              <AsideDetailsDescription>
                {orderData.createdAt}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">First Name</AsideDetailsLabel>
              <AsideDetailsDescription>
                {orderData.firstName}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Last Name</AsideDetailsLabel>
              <AsideDetailsDescription>
                {orderData.lastName}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            { orderData.phone &&
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Phone</AsideDetailsLabel>
              <AsideDetailsDescription>
                {orderData.phone === null ? '' : '+' }{orderData.phone}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            }
            { orderData.address &&
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Address</AsideDetailsLabel>
              <AsideDetailsDescription>
                {orderData.address}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            }
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Total</AsideDetailsLabel>
              <AsideDetailsDescription>
                ${orderData.amount}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Fee</AsideDetailsLabel>
              <AsideDetailsDescription>
                ${orderData.fee}
              </AsideDetailsDescription>
            </ShopInfoBlock>
          </ShopDetailsWrap>
        </>
      )}
    </>
  )
}

export default OrderDetails

OrderDetails.defaultProps = {}

OrderDetails.propTypes = {
  activeId: PropTypes.number.isRequired
}
