import React, { useState } from 'react'
import Modal from 'react-modal'
// import * as Yup from 'yup'
import { capitalize } from 'lodash/string'
import { some } from 'lodash/collection'
import { isNull } from 'lodash/lang'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { useGetCategoriesQuery } from '../../../api/generalApi'
import { 
  usePatchShopsMutation 
} from '../../../api/api.generated'
import { VStack, HStack } from '../../../global/styles'
import { globalTheme } from '../../../global/theme'
import {
  WEEK_DAYS,
  DEFAULT_OPEN_TIME,
  DEFAULT_CLOSE_TIME
} from '../../../const/app'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import TextInput from '../../../components/UI/Inputs/TextInput'
import Typography from '../../../components/UI/Typography'
import TextAreaInput from '../../../components/UI/Inputs/TextAreaInput'
import SelectManyInput from '../../../components/UI/Inputs/SelectManyInput'
import Button from '../../../components/UI/Buttons/Button/Button'
import SwitchInput from '../../../components/UI/Inputs/SwitchInput'
import LoadingSpinner from '../../../components/LoadingSpinner'
import EditWorkingHours from '../../../components/EditWorkingHours'
import ShowErrorModal from '../../../components/ShowErrorModal'
import ShopOperationHoursModal from '../ShopOperationHoursModal'
import {
  Headline,
  ModalBody,
  InputsWrapper,
  ShopDetailsHeadlineWrap,
  ShopDetailsDescription,
  HoursWrapper,
  OpenHoursWrap,
  WorkingDayWrap,
  OperationalHoursErrorWrap,
  TextInputWrapper,
  SwitchInputWrapper,
  ErrorWrap,
  ButtonWrap,
  DividerLine
} from './styles'

const EditShopModal = ({
  isOpen,
  handleCloseModal,
  shopIds
}) => {
  const [error, setError] = useState('')
  const [selectedDay, setSelectedDay] = useState(null)
  const { data: tagsData, isLoading: isLoadingTags, error: tagsError } = useGetCategoriesQuery()
  const [ patchShops, { isLoading: isUpdating } ] = usePatchShopsMutation()

  const shopProperties = {
    shopOpenDays: 'openDays',
    mondayOpen: 'mondayOpen',
    mondayClose: 'mondayClose',
    tuesdayOpen: 'tuesdayOpen',
    tuesdayClose: 'tuesdayClose',
    wednesdayOpen: 'wednesdayOpen',
    wednesdayClose: 'wednesdayClose',
    thursdayOpen: 'thursdayOpen',
    thursdayClose: 'thursdayClose',
    fridayOpen: 'fridayOpen',
    fridayClose: 'fridayClose',
    saturdayOpen: 'saturdayOpen',
    saturdayClose: 'saturdayClose',
    sundayOpen: 'sundayOpen',
    sundayClose: 'sundayClose',
  }

  const validate = values => {
    const errors = {};
    if (values.editDescription.length > 0 && values.description.length > 500) {
      errors.description = 'Too long, max 500 characters';
    }
    if (values.editSchedule.length > 0 && values.openDays.length === 0) {
      errors.openDays = 'Your store should be open at least one day per week';
    }
    if (values.editWebsite.length > 0 && values.website.length > 255) {
      errors.website = 'Too long, max 255 characters';
    }
    if (values.editOnlineShop.length > 0 && values.onlineShop.length > 255) {
      errors.onlineShop = 'Too long, max 255 characters';
    }
    if (values.editPhone.length > 0 && values.phone.length > 255) {
      errors.phone = 'Too long, max 255 characters';
    }
    if (values.editFacebook.length > 0 && values.facebook.length > 255) {
      errors.facebook = 'Too long, max 255 characters';
    }
    if (values.editInstagram.length > 0 && values.instagram.length > 255) {
      errors.instagram = 'Too long, max 255 characters';
    }
    if (values.editTwitter.length > 0 && values.twitter.length > 255) {
      errors.twitter = 'Too long, max 255 characters';
    }
    if (values.editChatMessage.length > 0 && values.chatMessage.length > 255) {
      errors.chatMessage = 'Too long, max 255 characters';
    }
    if (values.editVolunteerTitle.length > 0 && values.volunteerTitle.length > 255) {
      errors.volunteerTitle = 'Too long, max 255 characters';
    }
    if (values.editVolunteerMessage.length > 0 && values.volunteerMessage.length > 255) {
      errors.volunteerMessage = 'Too long, max 255 characters';
    }

    return errors;
  }

  const handleSubmit = (values) => {
    if (values.editDescription.length === 0 
      && values.editCategories.length === 0 
      && values.editDonations.length === 0 
      && values.editSchedule.length === 0 
      && values.editWebsite.length === 0 
      && values.editOnlineShop.length === 0 
      && values.editPhone.length === 0 
      && values.editFacebook.length === 0 
      && values.editInstagram.length === 0 
      && values.editTwitter.length === 0 
      && values.editStoreEnabled.length === 0 
      && values.editShowProducts.length === 0 
      && values.editChatEnabled.length === 0 
      && values.editShowChatMessage.length === 0 
      && values.editChatMessage.length === 0 
      && values.editVolunteerMessage.length === 0 
    ) {
      setError('No data selected for update.');
    } else {
      patchShops({ 
        body: { 
          shopIds,
          editDescription: values.editDescription.length > 0,
          description: values.description,
          editCategories: values.editCategories.length > 0,
          categories: values.categories,
          editDonations: values.editDonations.length > 0,
          donations: values.donations,
          editSchedule: values.editSchedule.length > 0,
          schedule: WEEK_DAYS.reduce(
            (result, DAY) => ({
              ...result,
              [DAY]: {
                isOpen: some(values.openDays, (openDay) => openDay === DAY),
                open: values[`${DAY}Open`],
                close: values[`${DAY}Close`]
              }
            }),
            {}
          ),
          editWebsite: values.editWebsite.length > 0,
          website: values.website,
          editOnlineShop: values.editOnlineShop.length > 0,
          onlineShop: values.onlineShop,
          editPhone: values.editPhone.length > 0,
          phone: values.phone,
          editFacebook: values.editFacebook.length > 0,
          facebook: values.facebook,
          editInstagram: values.editInstagram.length > 0,
          instagram: values.instagram,
          editTwitter: values.editTwitter.length > 0,
          twitter: values.twitter,
          editStoreEnabled: values.editStoreEnabled.length > 0,
          isStoreEnabled: values.isStoreEnabled.length > 0,
          editShowProducts: values.editShowProducts.length > 0,
          showProducts: values.showProducts.length > 0,
          editChatEnabled: values.editChatEnabled.length > 0,
          isChatEnabled: values.isChatEnabled.length > 0,
          editShowChatMessage: values.editShowChatMessage.length > 0,
          showChatMessage: values.showChatMessage.length > 0,
          editChatMessage: values.editChatMessage.length > 0,
          chatMessage: values.chatMessage,
          editVolunteerTitle: values.editVolunteerTitle.length > 0,
          volunteerTitle: values.volunteerTitle,
          editVolunteerMessage: values.editVolunteerMessage.length > 0,
          volunteerMessage: values.volunteerMessage
        } 
      })
      .unwrap()
      .then(() => {
        setError('');
        handleCloseModal()
      })
      .catch((err) => {
        if (err instanceof Error) {
          setError(err.message)
        } else if (err instanceof Object) {
          let message = '';
          Object.keys(err).forEach((key) => {
            message += `${err[key]} `;
          });
          setError(message)
        } else {
          setError('Network error')
        }
      })
    }
  }  

  return (
    <Modal {...{ isOpen }}>
      <ModalBody>
        {isLoadingTags && <LoadingSpinner />}
        { tagsError && 
        <ShowErrorModal
          title="Edit Stores"
          error="Network Error"
          onClose={handleCloseModal}
        />
        }  
        {tagsData && 
        <Formik
          initialValues={{        
            editDescription: [],
            description: '',
            editCategories: [],
            categories: [],
            editDonations: [],
            donations: [],
            editSchedule: [],
            [shopProperties.shopOpenDays]: [],
            [shopProperties.mondayOpen]: DEFAULT_OPEN_TIME,
            [shopProperties.mondayClose]: DEFAULT_CLOSE_TIME,
            [shopProperties.tuesdayOpen]: DEFAULT_OPEN_TIME,
            [shopProperties.tuesdayClose]: DEFAULT_OPEN_TIME,
            [shopProperties.wednesdayOpen]: DEFAULT_OPEN_TIME,
            [shopProperties.wednesdayClose]: DEFAULT_OPEN_TIME,
            [shopProperties.thursdayOpen]: DEFAULT_OPEN_TIME,
            [shopProperties.thursdayClose]: DEFAULT_OPEN_TIME,
            [shopProperties.fridayOpen]: DEFAULT_OPEN_TIME,
            [shopProperties.fridayClose]: DEFAULT_OPEN_TIME,
            [shopProperties.saturdayOpen]: DEFAULT_OPEN_TIME,
            [shopProperties.saturdayClose]: DEFAULT_OPEN_TIME,
            [shopProperties.sundayOpen]: DEFAULT_OPEN_TIME,
            [shopProperties.sundayClose]: DEFAULT_OPEN_TIME,
            editWebsite: [],
            website: '',
            editOnlineShop: [],
            onlineShop: '',
            editPhone: [],
            phone: '',
            editFacebook: [],
            facebook: '',
            editInstagram: [],
            instagram: '',
            editTwitter: [],
            twitter: '',
            editStoreEnabled: [],
            isStoreEnabled: [],
            editShowProducts: [],
            showProducts: [],
            editChatEnabled: [],
            isChatEnabled: [],
            editShowChatMessage: [],
            showChatMessage: [],
            editChatMessage: [],
            chatMessage: '',
            editVolunteerTitle: [],
            volunteerTitle: '',
            editVolunteerMessage: [],
            volunteerMessage: ''
          }}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({ values, errors }) => (
            <Form>
              <Headline>Edit Stores</Headline>

              <ShopOperationHoursModal
                isOpen={!isNull(selectedDay)}
                setSelectedDay={setSelectedDay}
                selectedDay={selectedDay}
              />

              <InputsWrapper>
                <SwitchInput
                  inputName="editDescription"
                  label="Description about your store"
                  value="editDescription"
                />
                <TextAreaInput
                  label=""
                  rows={5}
                  placeholder="Describe your store"
                  inputName="description"
                />

                <SwitchInput
                  inputName="editCategories"
                  label="Select Categories"
                  value="editCategories"
                />
                <SelectManyInput
                  options={tagsData.map((category) => ({
                    value: category.id,
                    label: category.tagName
                  }))}
                  label=""
                  inputName="categories"
                />

                <SwitchInput
                  inputName="editDonations"
                  label="Select Donations"
                  value="editDonations"
                />
                <SelectManyInput
                  options={tagsData.map((category) => ({
                    value: category.id,
                    label: category.tagName
                  }))}
                  label=""
                  inputName="donations"
                />

                <DividerLine />

                <HoursWrapper>
                  <VStack>
                    <ShopDetailsHeadlineWrap>
                      <SwitchInput
                        inputName="editSchedule"
                        label="Hours Operational"
                        value="editSchedule"
                        labelVariant="textL"
                      />
                      <ShopDetailsDescription>
                        Please select when your store is open
                      </ShopDetailsDescription>
                    </ShopDetailsHeadlineWrap>
                    <OpenHoursWrap>
                      {WEEK_DAYS.map((dayOfWeek) => (
                        <WorkingDayWrap key={dayOfWeek}>
                          <SwitchInput
                            inputName={shopProperties.shopOpenDays}
                            value={dayOfWeek}
                            label={capitalize(dayOfWeek)}
                          />
                          <EditWorkingHours
                            open={values[`${dayOfWeek}Open`]}
                            close={values[`${dayOfWeek}Close`]}
                            day={dayOfWeek}
                            isOpen={some(
                              values[shopProperties.shopOpenDays],
                              (dayShopIsOpened) => dayShopIsOpened === dayOfWeek
                            )}
                            setDay={setSelectedDay}
                          />
                        </WorkingDayWrap>
                      ))}
                    </OpenHoursWrap>

                    {errors[shopProperties.shopOpenDays] && (
                      <OperationalHoursErrorWrap>
                        <Typography
                          variant="textXS"
                          color={globalTheme.colors.skyNeutral1}
                        >
                          {errors[shopProperties.shopOpenDays]}
                        </Typography>
                      </OperationalHoursErrorWrap>
                    )}
                  </VStack>
                </HoursWrapper>

                <DividerLine />

                <SwitchInput
                  inputName="editWebsite"
                  label="Website"
                  value="editWebsite"
                />
                <TextInputWrapper>
                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName="website"
                    label=""
                    placeholder="Enter Website URL"
                  />
                </TextInputWrapper>

                <SwitchInput
                  inputName="editOnlineShop"
                  label="Online Shop"
                  value="editOnlineShop"
                />
                <TextInputWrapper>
                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName="onlineShop"
                    label=""
                    placeholder="Enter Online Shop URL"
                  />
                </TextInputWrapper>

                <SwitchInput
                  inputName="editPhone"
                  label="Phone Number"
                  value="editPhone"
                />
                <TextInputWrapper>
                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName="phone"
                    label=""
                    placeholder="Enter Phone Number"
                  />
                </TextInputWrapper>

                <SwitchInput
                  inputName="editFacebook"
                  label="Facebook"
                  value="editFacebook"
                />
                <TextInputWrapper>
                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName="facebook"
                    label=""
                    placeholder="Enter Facebook URL"
                  />
                </TextInputWrapper>

                <SwitchInput
                  inputName="editInstagram"
                  label="Instagram"
                  value="editInstagram"
                />
                <TextInputWrapper>
                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName="instagram"
                    label=""
                    placeholder="Enter Instagram URL"
                  />
                </TextInputWrapper>

                <SwitchInput
                  inputName="editTwitter"
                  label="Twitter"
                  value="editTwitter"
                />
                <TextInputWrapper>
                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName="twitter"
                    label=""
                    placeholder="Enter Twitter URL"
                  />
                </TextInputWrapper>

                <HStack>
                  <SwitchInput
                    inputName="editStoreEnabled"
                    label="Store Enabled"
                    value="editStoreEnabled"
                  />
                  <SwitchInputWrapper>
                    <SwitchInput
                      inputName="isStoreEnabled"
                      value="isStoreEnabled"
                      label=""
                    />
                  </SwitchInputWrapper>
                </HStack>

                <HStack>
                  <SwitchInput
                    inputName="editShowProducts"
                    label="Show Products"
                    value="editShowProducts"
                  />
                  <SwitchInputWrapper>
                    <SwitchInput
                      inputName="showProducts"
                      value="showProducts"
                      label=""
                    />
                  </SwitchInputWrapper>
                </HStack>

                <HStack>
                  <SwitchInput
                    inputName="editChatEnabled"
                    label="Chat Enabled"
                    value="editChatEnabled"
                  />
                  <SwitchInputWrapper>
                    <SwitchInput
                      inputName="isChatEnabled"
                      value="isChatEnabled"
                      label=""
                    />
                  </SwitchInputWrapper>
                </HStack>

                <HStack>
                  <SwitchInput
                    inputName="editShowChatMessage"
                    label="Show Chat Message"
                    value="editShowChatMessage"
                  />
                  <SwitchInputWrapper>
                    <SwitchInput
                      inputName="showChatMessage"
                      value="showChatMessage"
                      label=""
                    />
                  </SwitchInputWrapper>
                </HStack>

                <SwitchInput
                  inputName="editChatMessage"
                  label="Chat Message"
                  value="editChatMessage"
                />
                <TextInputWrapper>
                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName="chatMessage"
                    label=""
                    placeholder="Enter Chat Message"
                  />
                </TextInputWrapper>

                <SwitchInput
                  inputName="editVolunteerTitle"
                  label="Volunteer Title"
                  value="editVolunteerTitle"
                />
                <TextInputWrapper>
                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName="volunteerTitle"
                    label=""
                    placeholder="Enter Volunteer Title"
                  />
                </TextInputWrapper>                

                <SwitchInput
                  inputName="editVolunteerMessage"
                  label="Volunteer Message"
                  value="editVolunteerMessage"
                />
                <TextInputWrapper>
                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName="volunteerMessage"
                    label=""
                    placeholder="Enter Volunteer Message"
                  />
                </TextInputWrapper>                
              </InputsWrapper>

              {error && 
                <ErrorWrap>
                  <Typography variant="textS" color="red">
                  {error}
                  </Typography>
                </ErrorWrap>          
              }
              <ButtonWrap>
                <Button
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                  type="submit"
                  isLoading={isUpdating}
                >
                  Save
                </Button>
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTONS_SIZES.MED}
                  onClick={handleCloseModal}
                  type="button"
                >
                  Cancel
                </Button>
              </ButtonWrap>
            </Form>
          )}
        </Formik>
        }
      </ModalBody>
    </Modal>
  )
}

export default EditShopModal

EditShopModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  shopIds: PropTypes.array.isRequired,
}
