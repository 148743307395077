import styled from 'styled-components'
import { Form  } from 'formik'
import { VStack } from '../../../global/styles'

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
export const InputsWrap = styled(VStack)`
  width: 100%;
  padding: 24px;
`
export const FormInputsWrap = styled(VStack)`
  padding: 0 32px 0 32px;
  width: 100%;
  align-items: stretch;
`
export const FormInputWrap = styled.div`
  width: 100%;
  padding: 0;
`
export const ActionButtonsSection = styled.div`
  display: flex;
  padding: 32px;
`
export const ButtonWrap = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
`
