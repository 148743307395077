import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ButtonWrap, ProductDetailsWrap, TagsWrap } from './styles'
import {
  useGetOrganizationProductQuery,
  useDeleteOrganizationProductMutation
} from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import EntityPicture from '../../../components/EntityPicture'
import {
  BADGE_SIZES,
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  USER_AVATAR_SIZES
} from '../../../const/UIvariants'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsInfoBlock,
  DetailsPanelTopSection,
  ErrorMessageTopWrap
} from '../../../global/styles'
import Badge from '../../../components/UI/Badge'
import Button from '../../../components/UI/Buttons/Button/Button'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import Typography from '../../../components/UI/Typography'

const ProductDetails = ({ activeProductId, closePanel }) => {
  const [error, setError] = useState('')
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const { data: productData, isLoading, error: loadError } = useGetOrganizationProductQuery(activeProductId)
  const [deleteProduct, { isLoading: deletingShop }] = useDeleteOrganizationProductMutation()

  const displayError = (err) => {
    if (err instanceof Error) {
      setError(err.message)
    } else if (err instanceof Object) {
      let message = '';
      Object.keys(err).forEach((key) => {
        message += `${err[key]} `;
      });
      setError(message)
    } else {
      setError('Network error')
    }    
  }

  const toggleConfirmationModal = () => {
    setShowConfirmDelete((prev) => !prev)
  }

  const handleDeleteProduct = () => {
    deleteProduct({ productId: activeProductId })
      .unwrap()
      .then(() => {
        closePanel()
      })
      .catch((err) => {
        toggleConfirmationModal()
        displayError(err);
      })
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {loadError &&
        <ErrorMessageTopWrap>
          <Typography variant="textS" color="red">Network Error</Typography>
        </ErrorMessageTopWrap>
      }       
      {productData && (
        <>
          <DetailsPanelTopSection>
            <EntityPicture
              size={USER_AVATAR_SIZES.LARGE}
              image={productData.image}
              isRounded={false}
            />
            <AsideDetailsTitle align="center">
              {productData.name}
            </AsideDetailsTitle>
          </DetailsPanelTopSection>
          <ProductDetailsWrap>
            <AsideDetailsTitle>Product Details</AsideDetailsTitle>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
              Organisation
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {productData.organizationName}
              </AsideDetailsDescription>
            </DetailsInfoBlock>

            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Price
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                ${productData.price}
              </AsideDetailsDescription>
            </DetailsInfoBlock>

            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Shipping Cost
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                ${productData.shippingCost}
              </AsideDetailsDescription>
            </DetailsInfoBlock>

            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Shipping
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                <Badge
                  key="isShippingEnabled"
                  variant={productData.isShippingEnabled ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
                  size={BADGE_SIZES.SMALL}
                  label={productData.isShippingEnabled ? "Yes" : "No"}
                />
              </AsideDetailsDescription>
            </DetailsInfoBlock>

            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Click & Collect
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                <Badge
                    key="isCollectEnabled"
                    variant={productData.isCollectEnabled ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
                    size={BADGE_SIZES.SMALL}
                    label={productData.isCollectEnabled ? "Yes" : "No"}
                  />
              </AsideDetailsDescription>
            </DetailsInfoBlock>

            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Product Categories</AsideDetailsLabel>
              <TagsWrap>
                {productData.tagNames.map((tag) => (
                  <Badge
                    key={tag}
                    variant={BADGE_VARIANTS.LIGHT}
                    size={BADGE_SIZES.SMALL}
                    label={tag}
                  />
                ))}
              </TagsWrap>
            </DetailsInfoBlock>

            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTONS_SIZES.MED}
                onClick={toggleConfirmationModal}
                isLoading={deletingShop}
                isFullWidth
              >
                Delete
              </Button>
            </ButtonWrap>
            {error && 
              <Typography variant="textS" color="red">
              {error}
              </Typography>
            }  
          </ProductDetailsWrap>
          <DeleteConfirmationModal
            isOpen={showConfirmDelete}
            onClose={toggleConfirmationModal}
            entityName={productData.name}
            entityType="Product"
            handleDelete={handleDeleteProduct}
          />
        </>
      )}
    </>
  )
}

export default ProductDetails
ProductDetails.defaultProps = {
  activeProductId: null
}
ProductDetails.propTypes = {
  activeProductId: PropTypes.string,
  closePanel: PropTypes.func.isRequired
}
