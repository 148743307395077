import React from 'react'
import PropTypes from 'prop-types'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsPanelTopSection
} from '../../../global/styles'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Typography from '../../../components/UI/Typography'
import { useGetTransferQuery } from '../../../api/api.generated'
import {
  ShopDetailsWrap,
  ShopInfoBlock
} from './styles'

const BankTransferDetails = ({ activeId, showStore }) => {
  const { data: detailData, isLoading, error: loadError } = useGetTransferQuery(activeId)

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {loadError && 
        <Typography variant="textS" color="red">Network Error</Typography>
      }        
      {detailData && (
        <>
          <DetailsPanelTopSection>
            <AsideDetailsTitle align="center">Transfer Details</AsideDetailsTitle>
          </DetailsPanelTopSection>
          <ShopDetailsWrap>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Date</AsideDetailsLabel>
              <AsideDetailsDescription>
                {detailData.createdAt}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            {showStore &&
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Store</AsideDetailsLabel>
              <AsideDetailsDescription>
                {detailData.shopName}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            }
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Total</AsideDetailsLabel>
              <AsideDetailsDescription>
                ${detailData.total}
              </AsideDetailsDescription>
            </ShopInfoBlock>
          </ShopDetailsWrap>
        </>
      )}
    </>
  )
}

export default BankTransferDetails

BankTransferDetails.defaultProps = {}

BankTransferDetails.propTypes = {
  activeId: PropTypes.number.isRequired,
  showStore: PropTypes.bool.isRequired
}
