/*eslint-disable*/
import React from 'react'
import { BADGE_VARIANTS } from '../../../const/UIvariants'
import ShopTableOpenDays from '../../../components/ShopTableOpenDays'
import Badge from '../../../components/UI/Badge'
import { TableDarkText, TableText } from '../../../global/styles'
import TableImageLabelCell from '../../../components/TableImageLabelCell'

const ShopTableRow = (shopItem) => ({
  shopId: shopItem.id,
  shopName: (
    <TableImageLabelCell
      image={shopItem.logotype}
      bgColor={shopItem.avatarBackground}
      label={shopItem.shopName}
      isRounded
    />
  ),
  organizationName: <TableDarkText>{shopItem.organizationName}</TableDarkText>,
  location: <TableDarkText>{shopItem.displayAddress}</TableDarkText>,
  forSearch: `${shopItem.displayAddress} ${shopItem.shopName}`,
  daysOperational: <ShopTableOpenDays shopSchedule={shopItem.schedule} />,
  email: <TableText>{shopItem.email}</TableText>,
  status: (
    <Badge
      label={shopItem.isActive ? 'Active' : 'Inactive'}
      variant={shopItem.isActive ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
    />
  )
})
// const ShopTableRow = (shopItem) => ({
//   shopName: <TableImageLabelCell image={shopItem.logotype} label={shopItem.shopName} isRounded/>,
//   shopId: shopItem.id,
//   location: <TableDarkText>{shopItem.address}</TableDarkText>,
//   daysOperational: <ShopTableOpenDays shopSchedule={shopItem.schedule} />,
//   email: <TableText>{shopItem.email}</TableText>,
//   status: (
//     <Badge
//       label={shopItem.isActive ? 'Active' : 'Inactive'}
//       variant={shopItem.isActive ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
//     />
//   )
// })
export default ShopTableRow

ShopTableRow.propTypes = {}
