import React, { useState, useMemo, useEffect } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { Formik, Form } from 'formik'
import { isNull } from 'lodash/lang'
import * as Yup from 'yup'
import { 
  useLazyGetOrganizationsQuery,
  useLazyGetManagerQuery,
  useGetAllShopsQuery,
  usePostManagerMutation,
  usePutManagerMutation
} from '../../../api/api.generated'
import { getErrorMessage  } from '../../../utils/helpers'
import TextInput from '../../../components/UI/Inputs/TextInput'
import Typography from '../../../components/UI/Typography'
import PhoneNumberInput from '../../../components/UI/Inputs/PhoneNumberInput'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import FormSelectOneInput from '../../../components/UI/Inputs/SelectOneInput/FormSelectOneInput'
import SelectOneInput from '../../../components/UI/Inputs/SelectOneInput'
import Button from '../../../components/UI/Buttons/Button/Button'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import ShowErrorModal from '../../../components/ShowErrorModal'
import {
  ButtonWrap,
  Headline,
  ModalBody,
  ErrorWrap,
  InputRow,
  SelectRow
} from './styles'

const SUPER_ROLES_OPTIONS = [
  { value: 3, label: 'Store Admin' },
  { value: 4, label: 'Organisation Admin' },
  { value: 10, label: 'Super Admin' }
]

const ORG_ROLES_OPTIONS = [
  { value: 3, label: 'Store Admin' },
  { value: 4, label: 'Organisation Admin' }
]

const AddManagerModal = ({
  isCreate,
  isOpen,
  onClose,
  userId,
  organizationId, 
  showOrganization
}) => {
  const emptyUser = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    role: '',
    shop: null,
    organization: organizationId
  }

  const [isSuccess, setIsSuccess] = useState(false)
  const [initialData, setInitialData] = useState(emptyUser)
  const [error, setError] = useState('')
  const [selectedShop, setSelectedShop] = useState(-1)
  const [selectedOrganization, setSelectedOrganization] = useState(-1)
  const [getManager, { data: userData, error: managerError }] = useLazyGetManagerQuery()
  const { data: shopsData, isShopsLoading, error: shopsError } = useGetAllShopsQuery();
  const [getOrganizations, { data: organizationsData }] = useLazyGetOrganizationsQuery()
  const [addManager, { isAdding }] = usePostManagerMutation()
  const [updateManager, { isUpdating }] = usePutManagerMutation()

  const title = (isNull(userId)) ? 'Add Manager' : 'Edit Manager';

  let validationSchema
  if (isCreate) {
    validationSchema = Yup.object({
      firstName: Yup.string().max(50, 'First name is too long').required('First name is required'),
      lastName: Yup.string().max(50, 'Last name is too long').required('Last name is required'),
      email: Yup.string().max(255, 'Email is too long').email('Invalid email address').required('Email is required'),
      phoneNumber: Yup.string().max(50, 'Phone numeber is too long').matches(/^[0-9]+$/, 'Please enter valid phone number'),
      password: Yup.string()
        .min(6, 'Password must contain at least 6 characters')
        .max(100, 'Password is too long')
        .required('Required'),
      confirmPassword: Yup.string()
        .required('Please confirm your password')
        .when('password', {
          is: (password) => password && password.length > 0,
          then: Yup.string().oneOf([Yup.ref('password')], "Password doesn't match")
        }),  
      role: Yup.string().required('Role is required'),    
    })
  } else {
    validationSchema = Yup.object({
      firstName: Yup.string().max(50, 'First name is too long').required('First name is required'),
      lastName: Yup.string().max(50, 'Last name is too long').required('Last name is required'),
      email: Yup.string().max(255, 'Email is too long').email('Invalid email address').required('Email is required'),
      phoneNumber: Yup.string().max(50, 'Phone numeber is too long').matches(/^[0-9]+$/, 'Please enter valid phone number'),
      role: Yup.string().required('Role is required'),    
    })
  } 

  const organizationList = useMemo(
    () => {
      let arr = [];
      if (organizationsData) {
        arr = organizationsData?.organizations?.map((organization) => ({
            value: organization.id,
            label: organization.name
          }))
      }
      arr.unshift({
        value: -1,
        label: 'None'
      })
      return arr
    },
    [organizationsData]
  )

  const shopList = useMemo(
    () => {
      let arr = [];
      if (shopsData) {
        arr = shopsData?.shops?.map((shop) => ({
            value: shop.id,
            label: shop.shopName
          }))
      }
      arr.unshift({
        value: -1,
        label: 'None'
      })
      return arr
    },
    [shopsData]
  )

  const displayError = (err) => {
    if (err instanceof Error) {
      setError(err.message)
    } else if (err instanceof Object) {
      let message = '';
      Object.keys(err).forEach((key) => {
        message += `${err[key]} `;
      });
      setError(message)
    } else {
      setError('Network error')
    }    
  }

  const handleSubmit = (values) => {
    setError('')

    if (isCreate) {
      addManager({
        body: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phoneNumber,
          password: values.password,
          role: values.role,
          shop: selectedShop === -1 ? null : selectedShop,
          organization: selectedOrganization === -1 ? null : selectedOrganization
        }
      })
      .unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        displayError(err);
      })
    } else {
      updateManager({
        userId,
        body: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phoneNumber,
          role: values.role,
          shop: selectedShop === -1 ? null : selectedShop,
          organization: selectedOrganization === -1 ? null : selectedOrganization
        }
      })
      .unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        displayError(err);
      })
    }
  }

  useEffect(() => {
    if (showOrganization) {
      getOrganizations()
      .unwrap()
      .then(() => {
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })      
    }
    if (!isNull(userId)) {
      getManager(userId)
    } else {
      setInitialData(emptyUser)
      if (!showOrganization) {
        setIsSuccess(true)
      }
    }
  }, [])

  useEffect(() => {
    if (userData && (organizationsData || !showOrganization)) {
      setInitialData({
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phoneNumber: userData.phoneNumber ?? '',
        role: userData.roleId,
        shop: userData.shopId,
        organization: userData.organizationId
      })
      setSelectedShop(userData.shopId === null ? -1 : userData.shopId);
      setSelectedOrganization(userData.organizationId === null ? -1 : userData.organizationId);
      setIsSuccess(true)
    }
    if ((isNull(userId)) && (organizationsData)) {
      setIsSuccess(true)
    }
  }, [userData, organizationsData])

  return (
    <Modal {...{ isOpen }}>
      {!isSuccess && !shopsError && !managerError &&
      <LoadingSpinner />
      }
      {(shopsError || managerError) && 
        <ShowErrorModal
          title={title}
          error="Network Error"
          onClose={onClose}
        />
      } 
      {isSuccess && !shopsError && !managerError && (
      <ModalBody>
        <Formik
          initialValues={initialData}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            { isCreate && 
            <Headline>Add Manager</Headline>
            } 
            { !isCreate && 
            <Headline>Edit Manager</Headline>
            }
            <InputRow>
              <TextInput
                label="First Name"
                placeholder="Enter first name"
                inputName="firstName"
                size={INPUT_SIZES.SMALL}
              />
              <TextInput
                label="Last Name"
                placeholder="Enter last name"
                inputName="lastName"
                size={INPUT_SIZES.SMALL}
              />
            </InputRow>
            <InputRow>
              <TextInput
                label="Email Address"
                placeholder="Email Address"
                inputName="email"
                size={INPUT_SIZES.SMALL}
              />
              <PhoneNumberInput
                label="Phone Number"
                placeholder="Phone Number"
                inputName="phoneNumber"
                type="tel"
                size={INPUT_SIZES.SMALL}
              />
            </InputRow>
            <InputRow>
              <FormSelectOneInput
                inputName="role"
                label="Role"
                options={showOrganization ? SUPER_ROLES_OPTIONS : ORG_ROLES_OPTIONS }
                size="100%"
                value={initialData.role}
                isGrey
              />
            </InputRow>
            <SelectRow>
              <SelectOneInput
                inputName="shop"
                options={shopList}
                selected={selectedShop}
                setSelected={setSelectedShop}
                isGrey
                size="100%"
              />
              { showOrganization &&
              <SelectOneInput
                inputName="organization"
                options={organizationList}
                selected={selectedOrganization}
                setSelected={setSelectedOrganization}
                size="100%"
                isGrey
              />               
              }
            </SelectRow>
            { isCreate && 
            <InputRow>
              <TextInput
                label="Password"
                placeholder="Enter your password"
                inputName="password"
                size={INPUT_SIZES.SMALL}
              />
              <TextInput
                label="Confirm Password"
                placeholder="Repeat your password"
                inputName="confirmPassword"
                size={INPUT_SIZES.SMALL}
              />            
            </InputRow>
            }
            {error && 
              <ErrorWrap>
                <Typography variant="textS" color="red">
                {error}
                </Typography>
              </ErrorWrap>          
            }
            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.MED}
                type="submit"
                isLoading={isShopsLoading || isAdding || isUpdating}
              >
                Save
              </Button>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTONS_SIZES.MED}
                onClick={onClose}
                type="button"
              >
                Cancel
              </Button>
            </ButtonWrap>
          </Form>
        </Formik>
      </ModalBody>
      )}
    </Modal>
  )
}

export default withTheme(AddManagerModal)

AddManagerModal.defaultProps = {
  userId: null,
  organizationId: null
}

AddManagerModal.propTypes = {
  isCreate: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.string,
  organizationId: PropTypes.string,
  showOrganization: PropTypes.bool.isRequired
}
