import { api as apiSlice} from './api.generated'

const shopHolidaysApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationShopHolidays: build.query({
      query: ({ organizationId, search, page, limit }) => ({ url: `/admin/organizations/${organizationId}/shop-holidays?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['ShopHolidays']
    }),
    getShopHolidays: build.query({
      query: ({shopId, page, limit }) => ({
        url: `/shop/${shopId}/holidays?page=${page}&limit=${limit}`,
        method: 'get'
      }),
      providesTags: ['ShopHolidays']
    }),
    getShopHoliday: build.query({
      query: (holidayId) => ({ url: `/shop/holidays/${holidayId}` }),
      providesTags: ['ShopHoliday']
    }),    
    postShopHoliday: build.mutation({
      query: (data) => ({
        url: `/shop/holidays`,
        method: 'post',
        data
      }),
      invalidatesTags: ['ShopHolidays']
    }),
    putShopHoliday: build.mutation({
      query: ({ holidayId, data }) => ({
        url: `/shop/holidays/${holidayId}`,
        method: 'put',
        data
      }),
      invalidatesTags: ['ShopHolidays', 'ShopHoliday'],
    }),
    deleteShopHoliday: build.mutation({
      query: (holidayId) => ({
        url: `/shop/holidays/${holidayId}`,
        method: 'delete'
      }),
      invalidatesTags: ['ShopHolidays'],
    }),
    postDeleteShopHolidays: build.mutation({
      query: (queryArg) => ({
        url: `/admin/deleteHolidays`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['ShopHolidays'],
    }),
  }),

  overrideExisting: true
})

export const {
  useGetOrganizationShopHolidaysQuery,
  useLazyGetOrganizationShopHolidaysQuery,
  useGetShopHolidaysQuery,
  useLazyGetShopHolidaysQuery,
  useGetShopHolidayQuery,
  useLazyGetShopHolidayQuery,
  usePostShopHolidayMutation,
  usePutShopHolidayMutation,
  useDeleteShopHolidayMutation,
  usePostDeleteShopHolidaysMutation
} = shopHolidaysApi
