import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash/lang'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { withTheme } from 'styled-components'
import { Flag, LocalMall, PeopleAlt, Store } from '@styled-icons/material'
import { useLazyGetDashboardDataQuery } from '../../../api/api.generated'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../../const/UIvariants'
import ROUTES from '../../../const/routes'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import DashboardRecentItem from '../../../components/DashboardRecentItem'
import MetricItem from '../../../components/MetricItem'
import DashboardStatistics from '../../../components/DashboardStatistics'
import SelectOneInput from '../../../components/UI/Inputs/SelectOneInput'
import DateRangeModal from '../../../components/DateRangeModal/DateRangeModal'
import {
  DashboardWrap,
  MetricsWrap,
  RecentBlock,
  RecentItemsWrap,
  RecentWrap,
  HeadlineWrap,
  ButtonWrap
} from './styles'

const TIME_FRAME_OPTIONS = [
  { value: 1, label: '1 Month summary' },
  { value: 3, label: '3 Month summary' },
  { value: 6, label: '6 Month summary' },
  { value: 12, label: '12 Month summary' }
]
const Dashboard = ({ theme }) => {
  const history = useHistory()
  const [metricsData, setMetricsData] = useState([])
  const [activeMetric, setActiveMetric] = useState('Customers')
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(1)
  const [dateRange, setDateRange] = useState({from: "", to: ""})
  const [dateRangeModalIsOpen, setDateRangeModalIsOpen] = useState(false)
  const [getDashboard, { data: dashboardData, isLoading }] = useLazyGetDashboardDataQuery()

  const handleGoToSupport = () => {
    history.push(ROUTES.SUPPORT)
  }

  const handleGoToShops = () => {
    history.push(ROUTES.SHOPS)
  }

  const handleChangeSelectedMetric = (metric) => {
    if (metric !== activeMetric) {
      setActiveMetric(metric)
    }
  }

  const adaptMetrics = (data) => [
    {
      total: `${data.customers.total}`,
      metricName: 'Customers',
      icon: <PeopleAlt />,
      description: 'User already register',
      iconBgColor: 'skyNeutral4',
      numberAndIconColor: 'skyNeutral1'
    },
    {
      total: `${data.shops.total}`,
      metricName: 'Stores',
      icon: <Store />,
      description: 'Stores already registered',
      iconBgColor: 'greenNeutral3',
      numberAndIconColor: 'greenNeutral_1'
    },
    {
      total: `${data.products.total}`,
      metricName: 'Product Posted',
      icon: <LocalMall />,
      description: 'Product already active',
      iconBgColor: 'green3',
      numberAndIconColor: 'green_1'
    },
    {
      total: `${data.support.total}`,
      metricName: 'Support',
      icon: <Flag />,
      description: 'Report request',
      iconBgColor: 'pinkBase3',
      numberAndIconColor: 'pinkBase'
    }
  ]

  const onDateRangeSubmit = (values) => {
    setDateRangeModalIsOpen(false)
    setDateRange({from: values.dateFrom, to: values.dateTo})
    getDashboard({ timeFrame: 0, from: dayjs(values.dateFrom).format('YYYY-MM-DD'), to: dayjs(values.dateTo).format('YYYY-MM-DD') })
  }

  useEffect(() => {
    getDashboard({ timeFrame: selectedTimeFrame, from: "", to: "" })
  }, [])

  useEffect(() => {
    getDashboard({ timeFrame: selectedTimeFrame, from: "", to: "" })
  }, [selectedTimeFrame])


  useEffect(() => {
    if (dashboardData) {
      setMetricsData(adaptMetrics(dashboardData))
    }
  }, [dashboardData])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {dashboardData && (
        <DashboardWrap>
          <HeadlineWrap>
            <Typography variant="headingL" color={theme.colors.skyNeutral_2}>
              Activity
            </Typography>
            
            {!isEmpty(dashboardData) &&
            <ButtonWrap>
              <SelectOneInput
                options={TIME_FRAME_OPTIONS}
                selected={selectedTimeFrame}
                setSelected={setSelectedTimeFrame}
                size="250px"
              />
              <Button
                type="button"
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.SMALL}
                onClick={() => setDateRangeModalIsOpen(true)}
              >
                Date Range
              </Button>          
            </ButtonWrap>
            }
          </HeadlineWrap>

          <MetricsWrap>
            {metricsData.map((metricItem) => (
              <MetricItem
                description={metricItem.description}
                icon={metricItem.icon}
                iconBgColor={metricItem.iconBgColor}
                key={metricItem.metricName}
                isActive={metricItem.metricName === activeMetric}
                metricName={metricItem.metricName}
                numberAndIconColor={metricItem.numberAndIconColor}
                onClick={() => {
                  handleChangeSelectedMetric(metricItem.metricName)
                }}
                value={metricItem.total}
              />
            ))}
          </MetricsWrap>
          
          {!isEmpty(dashboardData) && (
            <DashboardStatistics dashboardData={dashboardData.dashboard} />
          )}

          <RecentWrap>
            <RecentBlock>
              <Typography variant="textL" color={theme.colors.skyNeutral_2}>
                Recent Support
              </Typography>
              <RecentItemsWrap>
                {dashboardData.recentSupport.map((supportItem) => (
                  <DashboardRecentItem
                    key={supportItem.id}
                    image={supportItem.senderImage}
                    title={supportItem.topic}
                    description={supportItem.description}
                    isActiveBadge={supportItem.isResolved ?? false}
                    bgColor={supportItem.avatarBackground}
                    onClick={() => {}}
                    showBadge
                  />
                ))}
              </RecentItemsWrap>
              <Button
                onClick={handleGoToSupport}
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTONS_SIZES.SMALL}
              >
                See all Support
              </Button>
            </RecentBlock>
            <RecentBlock>
              <Typography variant="textL" color={theme.colors.skyNeutral_2}>
                Most Active Stores
              </Typography>
              <RecentItemsWrap>
                {dashboardData.mostActiveShops.map((shopItem) => (
                  <DashboardRecentItem
                    key={shopItem.id}
                    image={shopItem.senderImage}
                    title={shopItem.shopName}
                    description={shopItem.description}
                    onClick={() => {}}
                    showBadge={false}
                    bgColor={shopItem.avatarBackground}
                  />
                ))}
              </RecentItemsWrap>
              <Button
                onClick={handleGoToShops}
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTONS_SIZES.SMALL}
              >
                See all Stores
              </Button>
            </RecentBlock>
          </RecentWrap>

          <DateRangeModal
            isOpen={dateRangeModalIsOpen}
            onClose={() => setDateRangeModalIsOpen(false)}
            onSubmit={onDateRangeSubmit}
            dateFrom={dateRange.from}
            dateTo={dateRange.to}
          />            
        </DashboardWrap>
      )}
    </>
  )
}

export default withTheme(Dashboard)

Dashboard.propTypes = {
  theme: PropTypes.object.isRequired
}
