import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { Add } from '@styled-icons/material'
import {
  useLazyGetShopHolidaysQuery,
  useDeleteShopHolidayMutation
} from '../../../api/shopHolidaysApi'
import { getErrorMessage } from '../../../utils/helpers'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import Button from '../../../components/UI/Buttons/Button'
import Typography from '../../../components/UI/Typography'
import Paginator from '../../../components/Paginator'
import ShopHolidayItem from '../../../components/ShopHolidayItem'
import AddHolidayModal from '../../../components/AddHolidayModal'
import { Footer, ErrorMessageTopWrap } from '../../../global/styles'
import {
  AddShopQuestions,
  QuestionList
} from './styles'

const ITEMS_PER_PAGE = 30

const ShopHolidays = ({ theme, shopId }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [error, setError] = useState('')
  const [addNewModalIsOpen, setAddNewModalIsOpen] = useState(false)
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [editHolidayId, setEditHolidayId] = useState(null)
  const [getHolidays, { data: shopHolidays, isLoading }] = useLazyGetShopHolidaysQuery()
  const [deleteShopHoliday] = useDeleteShopHolidayMutation()

  const handleOpenAdd = () => {
    setError("")
    setAddNewModalIsOpen(true)
  }

  const handleCloseAdd = () => {
    setAddNewModalIsOpen(false)
    setEditHolidayId(null)
  }
  
  const handleEdit = (holidayId) => {
    setError("")
    setEditModalIsOpen(true)
    setEditHolidayId(holidayId)
  }

  const handleCloseEdit = () => {
    setEditModalIsOpen(false)
    setEditHolidayId(null)
  }

  const handleDelete = (holidayId) => {
    setError("")
    deleteShopHoliday(holidayId)
      .unwrap()
      .then(() => {
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })     
  }

  const loadHolidays = () => {
    setError('')
    getHolidays({ shopId, page: currentPage, limit: ITEMS_PER_PAGE })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  useEffect(() => {
    loadHolidays()
  }, [])

  useEffect(() => {
    loadHolidays()
  }, [currentPage])

  useEffect(() => {
    if (shopHolidays) {
      setTotalPages(shopHolidays.pagination.pages)
      setTotalRows(shopHolidays.pagination.total)
      if (currentPage >= shopHolidays.pagination.pages) {
        setCurrentPage(0)
      }
    }
  }, [shopHolidays])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {error &&
        <ErrorMessageTopWrap>
          <Typography variant="textS" color="red">{error}</Typography>
        </ErrorMessageTopWrap>
      }       
      {addNewModalIsOpen &&
        <AddHolidayModal
          isOpen={addNewModalIsOpen}
          onClose={handleCloseAdd}
          shopId={shopId}
        />
      }
      {editModalIsOpen &&
        <AddHolidayModal
          isOpen={editModalIsOpen}
          onClose={handleCloseEdit}
          editHolidayId={editHolidayId}
        />
      }      
      {shopHolidays && (
        <>
          <AddShopQuestions>
            <div>
              <Button
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.SMALL}
                fillColor={theme.colors.green_1}
                type="button"
                onClick={handleOpenAdd}
              >
                <Add size={18} color={theme.colors.white} />
                Add Holiday
              </Button>
            </div>
          </AddShopQuestions>
          <QuestionList>
            {shopHolidays.holidays.map((item) => (
            <ShopHolidayItem
              key={item.id}
              id={item.id}
              name={item.name}
              dateFrom={item.dateFrom}
              dateTo={item.dateTo}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              canEdit={!item.isOrganizationHoliday}
            />
          ))}
          </QuestionList>
        </>
      )}
      {shopHolidays && (
        <Footer>
          <Typography variant="textM" color={theme.colors.skyNeutral2}>
            {`${shopHolidays.holidays.length} from ${totalRows} holidays`}
          </Typography>
          <Paginator
            amountOfPages={totalPages}
            {...{ currentPage, setCurrentPage }}
          />
        </Footer>
      )}
    </>
  )
}

export default withTheme(ShopHolidays)

ShopHolidays.propTypes = { 
  theme: PropTypes.object.isRequired,
  shopId: PropTypes.string.isRequired
}
