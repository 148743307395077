import React from 'react'
import { useParams } from 'react-router-dom'
import ShopQuestions from '../../shop/ShopQuestions'

const ManageShopQuestions = () => {
  const { shopId } = useParams()

  return (
    <ShopQuestions shopId={shopId}/>
  )
}

export default ManageShopQuestions

ManageShopQuestions.propTypes = {}
