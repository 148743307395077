import React, { useEffect, useState, useMemo } from 'react'
import Modal from 'react-modal'
import ImageUploading from 'react-images-uploading'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { withTheme } from 'styled-components'
import dayjs from 'dayjs'
import { isNull } from 'lodash/lang'
import { getImageFromServer, getImageBase64, getErrorMessage } from '../../../utils/helpers'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import UploadImagePreview from '../../../components/UploadImagePreview'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import TextInput from '../../../components/UI/Inputs/TextInput'
import TextAreaInput from '../../../components/UI/Inputs/TextAreaInput'
import DateInput from '../../../components/UI/Inputs/DateInput'
import SwitchInput from '../../../components/UI/Inputs/SwitchInput'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import FormSelectOneInput from '../../../components/UI/Inputs/SelectOneInput/FormSelectOneInput'
import ShowErrorModal from '../../../components/ShowErrorModal'
import { 
  useGetOrganizationShopsQuery
} from '../../../api/api.generated'
import {
  useLazyGetShopPostQuery, 
  usePostShopPostMutation,
  usePutShopPostMutation
} from '../../../api/shopPostsApi'
import { ErrorMessageWrap, InputError } from '../../../global/styles'
import {
  TitleWrap,
  OrganisationWrapper,
  ActionButtonsSection,
  ButtonWrap,
  DateWrap,
  FormInputsWrap,
  FormWrap,
  ImageControlButtonsWrap,
  ImageUploadContent,
  ImageUploadWrap,
  LeftHalf,
  RightHalf,
  StyledForm
} from './styles'

const emptyPost = {
  shopId: '',
  title: '',
  description: '',
  postImage: '',
  deadline: [],
  deadlineTypeId: 1,
  code: '',
  dateStart: '',
  dateEnd: '',
  timeOver: ''
}

const DEADLINE_OPTIONS = [
  { value: 1, label: 'Attended' },
  { value: 2, label: 'Got Deal' }
]

const AddNewPostModal = ({ isOpen, onClose, editPostId, theme, showShops, organizationId, shopId }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [initialData, setInitialData] = useState(emptyPost)
  const [error, setError] = useState('')
  const [getPost, { data: shopPost, error: postError }] = useLazyGetShopPostQuery()
  const [postShopPost] = usePostShopPostMutation()
  const [updateShopPost] = usePutShopPostMutation()

  const title = (isNull(editPostId)) ? 'Add Post' : 'Edit Post';

  let validationSchema
  let shopList = [];
  if (showShops) {
    validationSchema = Yup.object({
      shopId: Yup.string().required('Store is required'),
      title: Yup.string().required('Post title is required'),
      description: Yup.string().required('Post Description is required'),
      // postImage: Yup.string().required('Post image is required'),
      dateStart: Yup.date(),
      dateEnd: Yup.date(),
      code: Yup.string().max(20, 'Code is too long')
    })

    const { data: shopsData } = useGetOrganizationShopsQuery(organizationId)
    shopList = useMemo(
      () =>
        shopsData
          ? shopsData?.shops?.map((shop) => ({value: shop.id, label: shop.shopName })) 
          : [],
      [shopsData]
    )
  } else {
    validationSchema = Yup.object({
      title: Yup.string().required('Post title is required'),
      description: Yup.string().required('Post Description is required'),
      // postImage: Yup.string().required('Post image is required'),
      dateStart: Yup.date(),
      dateEnd: Yup.date(),
      code: Yup.string().max(20, 'Code is too long')
    })
  } 

  useEffect(() => {
    if (!isNull(editPostId)) {
      getPost(editPostId)
    } else {
      setInitialData(emptyPost)
      setIsSuccess(true)
    }
  }, [])
  
  useEffect(() => {
    if (shopPost) {
      setInitialData({
        shopId: shopPost.shopId,
        title: shopPost.title,
        description: shopPost.description,
        postImage: getImageFromServer(shopPost.postImage),
        deadline: shopPost.deadline ? ['1'] : [],
        deadlineTypeId: shopPost.deadlineTypeId,
        code: shopPost.code,
        dateStart: shopPost.dateStart ?? '',
        dateEnd: shopPost.dateEnd ?? '',
        timeOver: shopPost.timeOver
      })
      setIsSuccess(true)
    }
  }, [shopPost])

  const displayError = (err) => {
    setError(getErrorMessage(err))
  }

  const handleSubmitForm = ( values ) => {
    setError('')
    const data = values;
    if (!isNull(shopId)) {
      data.shopId = shopId
    }
    data.deadline = values.deadline?.[0] === '1'
    data.hasDeadline = data.deadline ? values.dateStart : null
    data.hasDeadline = data.deadline ? values.dateEnd : null
    if (editPostId) {
      const image = values?.postImage?.includes('data:image')
        ? values.postImage
        : undefined
      data.postImage = image;
      updateShopPost({
        postId: editPostId,
        data
      })
      .unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        displayError(err);
      })
    } else {
      postShopPost(data)
      .unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        displayError(err);
      })      
    }
  }

  return (
    <Modal {...{ isOpen }}>
      {!isSuccess && !postError && 
      <LoadingSpinner />
      }      
      {postError && 
        <ShowErrorModal
          title={title}
          error="Network Error"
          onClose={onClose}
        />
      }  
      {isSuccess && 
        <FormWrap>
          <Formik
            initialValues={initialData}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmitForm(values)
            }}
          >
            {({ values, setFieldValue, errors }) => (
              <StyledForm>
                <TitleWrap>
                  <Typography align="center" variant="headingM">{ title }</Typography>
                </TitleWrap>
                {showShops &&
                <OrganisationWrapper>
                  <FormSelectOneInput
                    inputName="shopId"
                    label="Store"
                    options={shopList}
                    size="100%"
                    value={initialData.shopId}
                    isGrey
                  />
                </OrganisationWrapper>
                }
                <FormInputsWrap>
                  <LeftHalf>
                    {(isNull(editPostId) || !shopPost.isOrganizationPost) &&
                    <>
                      <TextInput
                        size={INPUT_SIZES.SMALL}
                        inputName="title"
                        label="Post Title"
                        placeholder="Enter post name"
                      />
                      <TextAreaInput
                        inputName="description"
                        label="Description"
                        rows={13}
                        placeholder="Enter captions"
                        labelMarginBottom={0}
                      />
                    </>
                    }
                    <TextInput
                      size={INPUT_SIZES.SMALL}
                      inputName="code"
                      label="Code"
                      placeholder="Enter code"
                      labelMarginBottom={0}
                    />                      
                  </LeftHalf>
                  {(isNull(editPostId) || !shopPost.isOrganizationPost) &&
                  <RightHalf>
                    <ImageUploading
                      value={values.postImage}
                      onChange={(image) => {
                        setFieldValue(
                          'postImage',
                          getImageBase64({ source: image })
                        )
                      }}
                      acceptType={['jpg', 'gif', 'png']}
                      maxFileSize={4000000}
                      dataURLKey="data_url"
                    >
                      {({ onImageUpload }) => (
                        <>
                          <ImageUploadWrap>
                            <ImageUploadContent>
                              <Typography
                                variant="headingS"
                                color={theme.colors.skyNeutral_2}
                              >
                                Image
                              </Typography>
                              <Typography
                                variant="textXS"
                                color={theme.colors.skyNeutral2}
                              >
                                File type (JPG, JPEG, or PNG) max. 4MB
                              </Typography>
                              <ImageControlButtonsWrap>
                                <Button
                                  type="button"
                                  variant={BUTTON_VARIANTS.OUTLINED}
                                  size={BUTTONS_SIZES.SMALL}
                                  onClick={onImageUpload}
                                >
                                  Upload
                                </Button>
                                <Button
                                  type="button"
                                  variant={BUTTON_VARIANTS.SECONDARY}
                                  size={BUTTONS_SIZES.SMALL}
                                  onClick={() => {
                                    setFieldValue('postImage', '')
                                  }}
                                >
                                  Remove
                                </Button>
                              </ImageControlButtonsWrap>
                              <UploadImagePreview
                                height={176}
                                uploadedImage={values.postImage}
                              />
                              <InputError>{errors.postImage ? 'Image is required!' : null}</InputError>
                            </ImageUploadContent>
                          </ImageUploadWrap>
                        </>
                      )}
                    </ImageUploading>
                    <DateWrap>
                      <Typography variant="textM">Event</Typography>
                      <SwitchInput inputName="deadline" label="" value="1" />
                    </DateWrap>
                    {values.deadline[0] === '1' && (
                      <>
                        <FormSelectOneInput
                          inputName="deadlineTypeId"
                          label=""
                          options={DEADLINE_OPTIONS}
                          size="100%"
                          value={initialData.deadlineTypeId}
                          isGrey
                        />
                        <DateWrap>
                          <DateInput
                            label="Start"
                            inputName="dateStart"
                            placeholder="Post on"
                            minValue={dayjs().format()}
                          />
                          <DateInput
                            label="End"
                            inputName="dateEnd"
                            placeholder="Delete on"
                            minValue={values.dateStart}
                          />
                        </DateWrap>
                      </>
                    )}
                  </RightHalf>
                  }
                </FormInputsWrap>
                {error && 
                  <ErrorMessageWrap>
                    <Typography variant="textS" color="red">
                    {error}
                    </Typography>
                  </ErrorMessageWrap>          
                }                
                <ActionButtonsSection>
                  <ButtonWrap>
                    <Button
                      type="submit"
                      variant={BUTTON_VARIANTS.PRIMARY}
                      size={BUTTONS_SIZES.MED}
                    >
                      Save
                    </Button>
                    <Button
                      type="button"
                      variant={BUTTON_VARIANTS.SECONDARY}
                      size={BUTTONS_SIZES.MED}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                  </ButtonWrap>
                </ActionButtonsSection>
              </StyledForm>
            )}
          </Formik>
        </FormWrap>
      }
    </Modal>
  )
}

export default withTheme(AddNewPostModal)

AddNewPostModal.defaultProps = {
  editPostId: null,
  showShops: false,
  organizationId: null,
  shopId: null
}

AddNewPostModal.propTypes = {
  theme: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editPostId: PropTypes.string,
  showShops: PropTypes.bool,
  organizationId: PropTypes.string,
  shopId: PropTypes.string
}
