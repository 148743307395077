import React from 'react'
import PropTypes from 'prop-types'
import {
  HeaderRow,
  StyledRow,
  StyledTable,
  StyledTableBody,
  TableCell,
  TableHeader,
  TableHeaderTitle,
  TableWrap
} from './styles'
import TableFooter from '../TableFooter'

const PaginatedEntityTable = ({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  page,
  prepareRow,
  handleShowDetails,
  pageOptions,
  pageIndex,
  gotoPage,
  activeItemId,
  pageName,
  totalRows
}) => (
  <>
    <TableWrap>
      <StyledTable {...getTableProps()} cellspacing="0">
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <HeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(
                (column) =>
                  column.Header !== 'forSearch' && (
                    <th {...column.getHeaderProps()}>
                      <TableHeaderTitle variant="textXS">
                        {column.render('Header')}
                      </TableHeaderTitle>
                    </th>
                  )
              )}
            </HeaderRow>
          ))}
        </TableHeader>
        <StyledTableBody {...getTableBodyProps()} cellspacing="0">
          {page.map((row) => {
            prepareRow(row)
            return (
              <StyledRow
                {...row.getRowProps()}
                onClick={() => {
                  handleShowDetails(row.id)
                }}
                isSelected={row.id === activeItemId}
              >
                {row.cells.map(
                  (cell) =>
                    cell.column.Header !== 'forSearch' && (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    )
                )}
              </StyledRow>
            )
          })}
        </StyledTableBody>
      </StyledTable>
    </TableWrap>
    <TableFooter
      itemsDisplayedPerPage={page.length}
      entityName="Stores"
      itemsCount={totalRows}
      amountOfPages={pageOptions.length}
      currentPage={pageIndex}
      setCurrentPage={gotoPage}
      pageName={pageName}
    />
  </>
)

export default PaginatedEntityTable

PaginatedEntityTable.defaultProps = {
  handleShowDetails: () => {},
  activeItemId: null,
  pageName: null
}

PaginatedEntityTable.propTypes = {
  getTableProps: PropTypes.func.isRequired,
  headerGroups: PropTypes.array.isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  page: PropTypes.array.isRequired,
  prepareRow: PropTypes.func.isRequired,
  handleShowDetails: PropTypes.func,
  pageOptions: PropTypes.array.isRequired,
  pageIndex: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  activeItemId: PropTypes.any,
  pageName: PropTypes.string,
  totalRows: PropTypes.number.isRequired
}
