import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import store, { persistor } from './app/store'
import { GlobalStyle, globalTheme } from './global/theme'
import { apiV1 } from './config/axios'
import { logout } from './features/auth/authSlice'

// to avoid import cycle this is a best place to add interceptors that needs access to app store
// todo: refactor to a one interceptors file
apiV1.interceptors.response.use(
  (response) => response,
  (e) => {
    const { status } = e.response
    if (status === 401) {
      store.dispatch(logout())
      persistor.purge()
    }
    return Promise.reject(e)
  }
)
apiV1.interceptors.request.use((req) => {
  const { token } = store.getState().auth
  if (token) {
    // eslint-disable-next-line no-param-reassign
    req.headers = {
      Authorization: `Bearer ${token}`
    }
  }
  return req
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalStyle />
        <ThemeProvider theme={globalTheme}>
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
