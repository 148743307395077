import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { usePostShopStripeConnectMutation } from '../../../api/shopApi'
import { getErrorMessage } from '../../../utils/helpers'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Button from '../../../components/UI/Buttons/Button'
import { 
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
} from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import {
  ContentWrap,
  HeadlineWrap,
} from './styles'

const StripeConnect = ({ theme }) => {
  const { shopId } = useParams()
  const [error, setError] = useState('')
  const [ postShopStripeConnect, { data: stripeData, isLoading }] = usePostShopStripeConnectMutation()

  useEffect(() => {
    postShopStripeConnect(shopId)
    .unwrap()
    .then(() => {
    })
    .catch((err) => {
      setError(getErrorMessage(err))
    })    
  }, [])

  const linkClick = () => {
    window.location.href = stripeData.url;
  }

  return (
    <ContentWrap>
      <HeadlineWrap>
        <Typography variant="headingL" color={theme.colors.skyNeutral_2}>
          Stripe Connect
        </Typography>
      </HeadlineWrap>

      {isLoading && <LoadingSpinner />}
      {error && 
      <Typography variant="textXS" color={theme.colors.skyNeutral1}>{error}</Typography>
      }
      {stripeData && stripeData.chargesEnabled &&
        <div>Store is connected.</div>
      }
      {stripeData && !stripeData.chargesEnabled &&
        <Button
          type="button"
          variant={BUTTON_VARIANTS.PRIMARY}
          size={BUTTONS_SIZES.MED}
          onClick={linkClick}
        >
          Link
        </Button>
      }
    </ContentWrap>
  )
 }

export default withTheme(StripeConnect)

StripeConnect.propTypes = {
  theme: PropTypes.object.isRequired
}
