import React, { useState } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { Formik, Form } from 'formik'
import {
  ButtonWrap,
  Headline,
  ModalBody,
  ErrorWrap,
  SelectWrapper,
  SwitchInputWrapper
} from './styles'
import TextInput from '../../../components/UI/Inputs/TextInput'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button/Button'
import Typography from '../../../components/UI/Typography'
import SelectOneInput from '../../../components/UI/Inputs/SelectOneInput'
import TimeInput from '../../../components/UI/Inputs/TimeInput'
import SwitchInput from '../../../components/UI/Inputs/SwitchInput'
import {
  usePostSettingsMutation
} from '../../../api/api.generated'
import { getHour24 } from '../../../utils/helpers'

const EditSettingsModal = ({
  isOpen,
  handleClose,
  stripeSecretKey,
  transferDay,
  transferTime,
  locationOverride,
  isLocationOverrideVisible,
  theme
}) => {
  const TIME_FRAME_OPTIONS = [
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },
    { value: 7, label: 'Sunday' }
  ]

  const [selectedTransferDay, setSelectedTransferDay] = useState(transferDay)
  const [postSettings, { isLoading }] = usePostSettingsMutation()
  const [error, setError] = useState('')

  const handleSave = (values) => {
    const d = new Date(values.transferTime)
    const transferTimeStr = getHour24(d)
    setError('')
    postSettings({
      body: {
        stripeSecretKey: values.stripeSecretKey,
        transferDay: selectedTransferDay,
        transferTime: transferTimeStr,
        locationOverride: values.locationOverride?.[0] === 'locationOverride',
        isLocationOverrideVisible: values.isLocationOverrideVisible?.[0] === 'isLocationOverrideVisible'
      }
    })
      .unwrap()
      .then(() => {
        handleClose()
      })
      .catch((err) => {
        if (err) {
          let message = '';
          Object.keys(err).forEach((key) => {
            message += `${err[key]} `;
          });
          setError(message)
        } else {
          setError('Network error')
        }
      }) 
  }

  return (
    <Modal {...{ isOpen }}>
      <ModalBody>
        <Formik
          initialValues={{
            stripeSecretKey,
            transferDay,
            transferTime,
            locationOverride: locationOverride ? ['locationOverride'] : [],
            isLocationOverrideVisible: isLocationOverrideVisible ? ['isLocationOverrideVisible'] : []
          }}
          onSubmit={handleSave}
        >
            <Form>
              <Headline>Edit Settings</Headline>

              <TextInput
                label="Stripe secret"
                placeholder="Enter Stripe secret"
                inputName="stripeSecretKey"
                size={INPUT_SIZES.SMALL}
              />

              <SelectWrapper>
                <Typography variant="textS" color={theme.colors.skyNeutral_2}>Payment day</Typography>
                <SelectOneInput
                  inputName="transferDay"
                  options={TIME_FRAME_OPTIONS}
                  selected={selectedTransferDay}
                  setSelected={setSelectedTransferDay}
                  size=""
                  isGrey
                />
              </SelectWrapper>

              <TimeInput
                inputName="transferTime"
                label="Payment Time"
                minTime={new Date('2021-07-15T00:00:00.0000')}
                maxTime={new Date('2021-07-15T23:30:00.0000')}
              />

              <SwitchInputWrapper>
                <SwitchInput
                    inputName="locationOverride"
                    value="locationOverride"
                    label="Location override"
                />
              </SwitchInputWrapper>

              <SwitchInputWrapper>
                <SwitchInput
                    inputName="isLocationOverrideVisible"
                    value="isLocationOverrideVisible"
                    label="Location override visible"
                />
              </SwitchInputWrapper>

              <ErrorWrap>
              {error && 
                <Typography variant="textS" color="red">
                {error}
                </Typography>
              }
              </ErrorWrap>

              <ButtonWrap>
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTONS_SIZES.MED}
                  onClick={handleClose}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  isLoading={isLoading}
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                  type="submit"
                >
                  Save
                </Button>
              </ButtonWrap>
            </Form>
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default withTheme(EditSettingsModal)

EditSettingsModal.propTypes = {
  theme: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  stripeSecretKey: PropTypes.string.isRequired,
  transferDay: PropTypes.number.isRequired,
  transferTime: PropTypes.instanceOf(Date).isRequired,
  locationOverride: PropTypes.bool.isRequired,
  isLocationOverrideVisible: PropTypes.bool.isRequired
}
