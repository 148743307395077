import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import {
  VolunteerDetailsWrap,
  VolunteerTitleWrap,
  VolunteerWrap,
  MessageWrap
} from './styles'
import EditDeleteMenu from '../EditDeleteMenu/EditDeleteMenu'
import Typography from '../UI/Typography'

const ShopVolunteerItem = ({
  theme,
  volunteerId,
  volunteerName,
  volunteerEmail,
  volunteerPhone,
  volunteerMessage,
  volunteerUser,
  handleEditVolunteer,
  handleDeleteVolunteer
}) => (
  <VolunteerWrap>
    <VolunteerDetailsWrap>
      <VolunteerTitleWrap>
        <Typography variant="textXS" color={theme.colors.skyNeutral1}>
          {volunteerName}
        </Typography>        
        {handleEditVolunteer && handleDeleteVolunteer && (
          <EditDeleteMenu
            onEdit={() => {
              handleEditVolunteer(volunteerId)
            }}
            onDelete={() => {
              handleDeleteVolunteer(volunteerId)
            }}
          />
        )}
      </VolunteerTitleWrap>
      <Typography variant="textXS" color={theme.colors.skyNeutral_1}>
      {volunteerEmail}
      </Typography>
      <Typography variant="textXS" color={theme.colors.skyNeutral_1}>
      {volunteerPhone}
      </Typography>
      <MessageWrap variant="textXS" color={theme.colors.skyNeutral_1}>
      {volunteerMessage}
      </MessageWrap>
      <Typography variant="textXS" color={theme.colors.skyNeutral_1}>
      {volunteerUser}
      </Typography>
    </VolunteerDetailsWrap>
  </VolunteerWrap>
)

export default withTheme(ShopVolunteerItem)

ShopVolunteerItem.defaultProps = {
  handleEditVolunteer: null,
  handleDeleteVolunteer: null
}

ShopVolunteerItem.propTypes = {
  theme: PropTypes.object.isRequired,
  volunteerId: PropTypes.string.isRequired,
  volunteerName: PropTypes.string.isRequired,
  volunteerEmail: PropTypes.string.isRequired,
  volunteerPhone: PropTypes.string.isRequired,
  volunteerMessage: PropTypes.string.isRequired,
  volunteerUser: PropTypes.string.isRequired,
  handleEditVolunteer: PropTypes.func,
  handleDeleteVolunteer: PropTypes.func,
}
