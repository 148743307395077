import { keys, get } from 'lodash/object'
import { filter, find, includes } from 'lodash/collection'
import { slice } from 'lodash/array'
import { isEmpty } from 'lodash/lang'
import dayjs from 'dayjs'
import * as _ from 'lodash'
import { STORAGE_URL } from '../const/api'

export const getHourAMPM = (dateTime) => dayjs(dateTime).format('h:mm a')
export const getHour24 = (dateTime) => dayjs(dateTime).format('HH:mm:ss')

export const getImageFromServer = (address, storageUrl = STORAGE_URL) => {
  if (address && address.includes('data:image')) {
    return address
  }
  return `${storageUrl}${address ?? ''}`
}

export const getImageBase64 = ({ source, imageIndex = 0 }) =>
  get(source, `[${imageIndex}].data_url`, null)

export const getCategoryById = ({ categoryId, categories }) =>
  get(find(categories, { id: categoryId }), 'tagName', 'Category')

export const getArraySlice = (array, options = { page: 1, perPage: 50 }) => {
  const { page, perPage } = options
  return slice(array, (page - 1) * perPage, page * perPage)
}

export const shopProductsFilter = (products, options = { tags: [] }) => {
  const { tags } = options
  if (isEmpty(tags)) {
    return products
  }
  return filter(
    products,
    (product) =>
      !isEmpty(
        filter(product.categories, (category) => includes(tags, category))
      )
  )
}

export const formatDate = (date) => dayjs(date).format('DD MMM YYYY')

export const formatPostDeadline = (dateStart, dateEnd) => {
    if (dateStart && dateEnd) {
    const endYear = dayjs(dateEnd).format('YYYY')
    const startYear = dayjs(dateStart).format('YYYY')
    const postDeadline = `${dayjs(dateStart).format('DD MMM')} ${
        startYear !== endYear ? startYear : ''
      } - ${dayjs(dateEnd).format('DD MMM YYYY')}`
    return postDeadline
  }
  return 'Not Event'
}

const minutesFromDayStart = (time) =>
  parseInt(dayjs(time).format('H'), 10) * 60 +
  parseInt(dayjs(time).format('m'), 10)

export const isShopOpen = (shopSchedule) => {
  const currentDay = dayjs().format('dddd').toLowerCase()
  const { open, close } = shopSchedule[currentDay]
  const minutesFromDayStartToOpenTime = minutesFromDayStart(open)
  const minutesFromDayStartToCloseTime = minutesFromDayStart(close)

  if (shopSchedule[currentDay].isOpen === false) {
    return false
  }
  return (
    minutesFromDayStartToOpenTime < minutesFromDayStart(dayjs()) &&
    minutesFromDayStartToCloseTime > minutesFromDayStart(dayjs())
  )
}

export const getEntityPropertyByIds = (
  idsArray = [],
  singleId,
  collection,
  propertyName
) => {
  const idsToGet = isEmpty(idsArray) ? [singleId] : keys(idsArray)
  return _.chain(collection)
    .keyBy('id')
    .at([...idsToGet])
    .reduce(
      (res, currShop, index) =>
        `${res}${index === 0 ? '' : ', '}${currShop?.[propertyName] ?? ''}`,
      ''
    )
    .value()
}

export const truncateString = (message, limit = 90) => {
  if (message && typeof message === 'string') {
    return `${message?.substring(0, limit).trim()}${
      message?.length > 90 ? '...' : ''
    }`
  }
  return ''
}

export const getErrorMessage = (err) => {
  if (err instanceof Error) {
    return err.message
  } 
  if (err instanceof Object) {
    let message = '';
    Object.keys(err).forEach((key) => {
      message += `${err[key]} `;
    });
    return message
  }
  return 'Network error'
}