import React from 'react'
import { useSelector } from 'react-redux'
import {
  Analytics,
  Store,
  QuestionAnswer,
  Brightness5,
  SensorDoor,
  Preview,
  PeopleAlt,
  LocalMall,
  LocalActivity,
  LocalOffer,
  Mail,
  Flag,
  AdminPanelSettings,
  Today,
  Money
} from '@styled-icons/material'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import {
  ContentContainer,
  LogoutItem,
  LogoWrap,
  MainMenu,
  MainNavWrapper
} from './styles'
import ROUTES from '../../../const/routes'
import MainMenuItem from '../../../components/UI/MainMenuItem'
import OphopLogo from '../../../assets/icons/OphopLogo'
import ErrorBoundary from '../../errorBoundaries/ErrorBoundary'

const mainMenuItems = [
  // storeAdmin
  {
    role: 'storeAdmin',
    label: 'Dashboard',
    icon: <Analytics />,
    destination: ROUTES.DASHBOARD
  },
  {
    role: 'storeAdmin',    
    label: 'Store Profile',
    icon: <Store />,
    destination: ROUTES.SHOP_PROFILE
  },
  {
    role: 'storeAdmin',
    label: 'Order History',
    icon: <Preview />,
    destination: ROUTES.STORE_ORDER_HISTORY
  },
  {
    role: 'storeAdmin',
    label: 'Bank Transfers',
    icon: <Money />,
    destination: ROUTES.SHOP_TRANSFERS
  },
  {
    role: 'storeAdmin',    
    label: 'Messages',
    icon: <QuestionAnswer />,
    destination: ROUTES.MESSAGES
  },
  {
    role: 'storeAdmin',
    label: 'Contacts',
    icon: <PeopleAlt />,
    destination: ROUTES.CONTACTS
  },
  {
    role: 'storeAdmin',
    label: 'Settings',
    icon: <Brightness5 />,
    destination: ROUTES.SETTINGS
  },

  // organisationAdmin
  {
    role: 'organisationAdmin',
    label: 'Dashboard',
    icon: <Analytics />,
    destination: ROUTES.DASHBOARD
  },  
  {
    role: 'organisationAdmin',
    label: 'Organisation Products',
    icon: <LocalMall />,
    destination: ROUTES.ORGANIZATION_PRODUCTS
  },
  {
    role: 'organisationAdmin',
    label: 'Organisation Posts',
    icon: <LocalActivity />,
    destination: ROUTES.ORGANIZATION_POSTS
  },
  {
    role: 'organisationAdmin',
    label: 'Organisation FAQ',
    icon: <Today />,
    destination: ROUTES.ORGANIZATION_QUESTIONS
  },
  {
    role: 'organisationAdmin',
    label: 'Organisation Holidays',
    icon: <Today />,
    destination: ROUTES.ORGANIZATION_HOLIDAYS
  },
  {
    role: 'organisationAdmin',
    label: 'Stores',
    icon: <Store />,
    destination: ROUTES.SHOPS
  },
  {
    role: 'organisationAdmin',
    label: 'Store Products',
    icon: <LocalMall />,
    destination: ROUTES.PRODUCTS
  },
  {
    role: 'organisationAdmin',
    label: 'Store Posts',
    icon: <LocalActivity />,
    destination: ROUTES.POSTS
  }, 
  {
    role: 'organisationAdmin',
    label: 'Store FAQ',
    icon: <Today />,
    destination: ROUTES.QUESTIONS
  },
  {
    role: 'organisationAdmin',
    label: 'Store Holidays',
    icon: <Today />,
    destination: ROUTES.HOLIDAYS
  },
  {
    role: 'organisationAdmin',
    label: 'Managers',
    icon: <PeopleAlt />,
    destination: ROUTES.MANAGERS
  },
  {
    role: 'organisationAdmin',
    label: 'Donations',
    icon: <LocalMall />,
    destination: ROUTES.DONATIONS
  },
  {
    role: 'organisationAdmin',
    label: 'Store Orders',
    icon: <Preview />,
    destination: ROUTES.STORE_ORDERS
  },
  {
    role: 'organisationAdmin',
    label: 'Bank Transfers',
    icon: <Money />,
    destination: ROUTES.BANK_TRANSFERS
  },
  {
    role: 'organisationAdmin',    
    label: 'Messages',
    icon: <QuestionAnswer />,
    destination: ROUTES.MESSAGES
  },

  // superAdmin
  {
    role: 'superAdmin',
    label: 'Dashboard',
    icon: <Analytics />,
    destination: ROUTES.DASHBOARD
  },
  {
    role: 'superAdmin',
    label: 'Organisations',
    icon: <PeopleAlt />,
    destination: ROUTES.ORGANIZATIONS
  },
  {
    role: 'superAdmin',
    label: 'Organisation Products',
    icon: <LocalMall />,
    destination: ROUTES.ORGANIZATION_PRODUCTS
  },
  {
    role: 'superAdmin',
    label: 'Organisation Posts',
    icon: <LocalActivity />,
    destination: ROUTES.ORGANIZATION_POSTS
  },
  {
    role: 'superAdmin',
    label: 'Organisation FAQ',
    icon: <Today />,
    destination: ROUTES.ORGANIZATION_QUESTIONS
  },
  {
    role: 'superAdmin',
    label: 'Organisation Holidays',
    icon: <Today />,
    destination: ROUTES.ORGANIZATION_HOLIDAYS
  },
  {
    role: 'superAdmin',
    label: 'Stores',
    icon: <Store />,
    destination: ROUTES.SHOPS
  },
  {
    role: 'superAdmin',
    label: 'Store Products',
    icon: <LocalMall />,
    destination: ROUTES.PRODUCTS
  },
  {
    role: 'superAdmin',
    label: 'Store Posts',
    icon: <LocalActivity />,
    destination: ROUTES.POSTS
  },
  {
    role: 'superAdmin',
    label: 'Store FAQ',
    icon: <Today />,
    destination: ROUTES.QUESTIONS
  },  
  {
    role: 'superAdmin',
    label: 'Store Holidays',
    icon: <Today />,
    destination: ROUTES.HOLIDAYS
  },  
  {
    role: 'superAdmin',
    label: 'Managers',
    icon: <PeopleAlt />,
    destination: ROUTES.MANAGERS
  },
  {
    role: 'superAdmin',
    label: 'Customers',
    icon: <PeopleAlt />,
    destination: ROUTES.CUSTOMERS
  },
  {
    role: 'superAdmin',
    label: 'Donations',
    icon: <LocalMall />,
    destination: ROUTES.DONATIONS
  },
  {
    role: 'superAdmin',
    label: 'Orders',
    icon: <Preview />,
    destination: ROUTES.ORDERS
  },
  {
    role: 'superAdmin',
    label: 'Store Orders',
    icon: <Preview />,
    destination: ROUTES.STORE_ORDERS
  },  
  {
    role: 'superAdmin',
    label: 'Bank Transfers',
    icon: <Money />,
    destination: ROUTES.BANK_TRANSFERS
  },
  {
    role: 'superAdmin',
    label: 'Categories',
    icon: <LocalOffer />,
    destination: ROUTES.TAGS
  },
  {
    role: 'superAdmin',
    label: 'Email Templates',
    icon: <Mail />,
    destination: ROUTES.TEMPLATES
  },
  {
    role: 'superAdmin',
    label: 'Support',
    icon: <Flag />,
    destination: ROUTES.SUPPORT
  },
  {
    role: 'superAdmin',
    label: 'Store Reports',
    icon: <Flag />,
    destination: ROUTES.SHOP_REPORTS
  },
  {
    role: 'superAdmin',
    label: 'Settings',
    icon: <AdminPanelSettings />,
    destination: ROUTES.SETTINGS
  }
]

const NavigationHoc = ({ children }) => {
  const location = useLocation()
  const { role } = useSelector((state) => state.auth)
  return (
    <MainNavWrapper>
      <MainMenu>
        <LogoWrap>
          <OphopLogo />
        </LogoWrap>
        {mainMenuItems.filter(item => item.role === role).map((item) => (
          <MainMenuItem
            key={item.label}
            icon={item.icon}
            label={item.label}
            destination={item.destination}
            isActive={location.pathname.includes(item.destination)}
          />
        ))}

        <LogoutItem>
          <MainMenuItem
            icon={<SensorDoor />}
            label="Logout"
            destination={ROUTES.LOGOUT}
            isActive={false}
          />
        </LogoutItem>
      </MainMenu>
      <ContentContainer>
        <ErrorBoundary>{children}</ErrorBoundary>
      </ContentContainer>
    </MainNavWrapper>
  )
}

export default NavigationHoc

NavigationHoc.propTypes = {
  children: PropTypes.node.isRequired
}
