import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsPanelTopSection,
  ErrorMessageWrap
} from '../../../global/styles'
import {
  BADGE_SIZES,
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../../const/UIvariants'
import { getErrorMessage } from '../../../utils/helpers'
import Badge from '../../../components/UI/Badge'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Button from '../../../components/UI/Buttons/Button'
import Typography from '../../../components/UI/Typography'
import { 
  useGetShopOrderQuery, 
  usePutShopOrderMutation 
} from '../../../api/ordersApi'
import {
  ButtonWrap,
  ShopDetailsWrap,
  ShopInfoBlock,
  TagsWrap
} from './styles'

const OrderDetails = ({ activeOrderId }) => {
  const [error, setError] = useState('')
  const { data: orderData, isLoading, error: loadError } = useGetShopOrderQuery(activeOrderId)
  const [updateStatus] = usePutShopOrderMutation()

  const handleUpdateStatus = () => {
    setError("");
    const statusId = orderData.statusId === 1 ? 2 : 1;
    updateStatus({
      orderId: orderData.id,
      data: { statusId }
    })
    .unwrap()
    .then(() => {
    })
    .catch((err) => {
      setError(getErrorMessage(err))
    })
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {loadError && 
        <Typography variant="textS" color="red">Network Error</Typography>
      }        
      {orderData && (
        <>
          <DetailsPanelTopSection>
            <AsideDetailsTitle align="center">Order Details</AsideDetailsTitle>
          </DetailsPanelTopSection>
          <ShopDetailsWrap>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Order #</AsideDetailsLabel>
              <AsideDetailsDescription>
                {orderData.code}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Date</AsideDetailsLabel>
              <AsideDetailsDescription>
                {orderData.createdAt}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">First Name</AsideDetailsLabel>
              <AsideDetailsDescription>
                {orderData.firstName}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Last Name</AsideDetailsLabel>
              <AsideDetailsDescription>
                {orderData.lastName}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            { orderData.phone &&
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Phone</AsideDetailsLabel>
              <AsideDetailsDescription>
                {orderData.phone === null ? '' : '+' }{orderData.phone}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            }
            { orderData.address &&
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Address</AsideDetailsLabel>
              <AsideDetailsDescription>
                {orderData.address}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            }
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Total</AsideDetailsLabel>
              <AsideDetailsDescription>
                ${orderData.total}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Fee</AsideDetailsLabel>
              <AsideDetailsDescription>
                ${orderData.fee}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Shipping</AsideDetailsLabel>
              <TagsWrap>
                <Badge
                  size={BADGE_SIZES.LARGE}
                  label={orderData.isDelivery ? 'Delivery' : 'Click and Collect'}
                  variant={orderData.isDelivery ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.PINK}
                />
              </TagsWrap>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Withdrawn</AsideDetailsLabel>
              <TagsWrap>
                <Badge
                  size={BADGE_SIZES.LARGE}
                  label={orderData.transfer ? 'Yes' : 'No'}
                  variant={orderData.transfer ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.PINK}
                />
              </TagsWrap>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Status</AsideDetailsLabel>
              <TagsWrap>
                <Badge
                  size={BADGE_SIZES.LARGE}
                  label={orderData.statusId === 1 ? 'Not Fullfiled' : 'Fullfiled'}
                  variant={orderData.statusId === 1 ? BADGE_VARIANTS.PINK : BADGE_VARIANTS.GREEN}
                />
              </TagsWrap>
            </ShopInfoBlock>
            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTON_SIZES.MED}
                onClick={handleUpdateStatus}
              >
                { orderData.statusId === 1 ? 'Fullfilled' : 'Not Fullfilled' }
              </Button>
            </ButtonWrap>
            {error &&
              <ErrorMessageWrap>
                <Typography variant="textS" color="red">{error}</Typography>
              </ErrorMessageWrap>
            }             
          </ShopDetailsWrap>
        </>
      )}
    </>
  )
}

export default OrderDetails

OrderDetails.defaultProps = {}

OrderDetails.propTypes = {
  activeOrderId: PropTypes.number.isRequired
}
