import React, { useEffect, useState, useMemo } from 'react'
import { Formik } from 'formik'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import ImageUploading from 'react-images-uploading'
import { withTheme } from 'styled-components'
import dayjs from 'dayjs'
import { isNull } from 'lodash/lang'
import {
  useLazyGetOrganizationsQuery,
  useLazyGetOrganizationPostQuery,
  usePostOrganizationPostMutation,
  usePutOrganizationPostMutation
} from '../../../api/api.generated'
import { getImageFromServer, getImageBase64, getErrorMessage } from '../../../utils/helpers'
import { ErrorMessageWrap, InputError } from '../../../global/styles'
import UploadImagePreview from '../../../components/UploadImagePreview'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import TextInput from '../../../components/UI/Inputs/TextInput'
import TextAreaInput from '../../../components/UI/Inputs/TextAreaInput'
import DateInput from '../../../components/UI/Inputs/DateInput'
import SwitchInput from '../../../components/UI/Inputs/SwitchInput'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import FormSelectOneInput from '../../../components/UI/Inputs/SelectOneInput/FormSelectOneInput'
import {
  TitleWrap,
  OrganisationWrapper,
  ActionButtonsSection,
  ButtonWrap,
  DateWrap,
  FormInputsWrap,
  FormWrap,
  ImageControlButtonsWrap,
  ImageUploadContent,
  ImageUploadWrap,
  LeftHalf,
  RightHalf,
  StyledForm
} from './styles'

const emptyPost = {
  deadline: [],
  dateStart: '',
  dateEnd: '',
  timeOver: '',
  title: '',
  description: '',
  image: '',
  organization: ''
}

const AddOrganizationPostModal = ({ isOpen, onClose, editPostId, theme, organizationId, showOrganization }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [initialData, setInitialData] = useState(emptyPost)
  const [error, setError] = useState('')
  const [getOrganizations, { data: organizationsData }] = useLazyGetOrganizationsQuery()
  const [getOrganizationPost, { data: shopPost }] = useLazyGetOrganizationPostQuery()
  const [postOrganizationPost] = usePostOrganizationPostMutation()
  const [updateOrganizationPost] = usePutOrganizationPostMutation()

  const action = (isNull(editPostId)) ? 'Add' : 'Edit';

  const organizationList = useMemo(
    () =>
      organizationsData
        ? organizationsData?.organizations?.map((organization) => ({value: organization.id, label: organization.name })) 
        : [],
    [organizationsData]
  )

  useEffect(() => {
    if (showOrganization) {
      getOrganizations()
      .unwrap()
      .then(() => {
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })      
    }
    if (!isNull(editPostId)) {
      getOrganizationPost(editPostId)
    } else {
      setInitialData({
        title: '',
        description: '',
        organization: organizationId,
        deadline: [],
        dateStart: '',
        dateEnd: '',
        timeOver: '',
        image: ''
      })      
      if (!showOrganization) {
        setIsSuccess(true)
      }
    }
  }, [])

  useEffect(() => {
    if (shopPost && (organizationsData || !showOrganization)) {
      setInitialData({
        title: shopPost.title,
        description: shopPost.description,
        organization: shopPost.organizationId,
        deadline: shopPost.deadline ? ['1'] : [],
        dateStart: shopPost.dateStart ?? '',
        dateEnd: shopPost.dateEnd ?? '',
        timeOver: shopPost.timeOver,
        image: getImageFromServer(shopPost.image)
      })
      setIsSuccess(true)
    }
    if ((isNull(editPostId)) && (organizationsData)) {
      setIsSuccess(true)
    }
  }, [shopPost, organizationsData])

  const displayError = (err) => {
    if (err instanceof Error) {
      setError(err.message)
    } else if (err instanceof Object) {
      let message = '';
      Object.keys(err).forEach((key) => {
        message += `${err[key]} `;
      });
      setError(message)
    } else {
      setError('Network error')
    }    
  }

  const handleSubmitForm = (values) => {
    const image = values?.image?.includes('data:image')
      ? values.image
      : undefined

    const hasDeadline = values.deadline?.[0] === '1'
    setError('')
    if (editPostId) {
      updateOrganizationPost({
        organizationPostId: editPostId,
        body: { 
          title: values.title,
          description: values.description,
          organization: values.organization,
          image,
          deadline: hasDeadline,
          dateStart: hasDeadline ? values.dateStart : null,
          dateEnd: hasDeadline ? values.dateEnd : null,          
        }
      })
      .unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        displayError(err);
      })
    } else {
      postOrganizationPost({ 
        body: {
          title: values.title,
          description: values.description,
          organization: values.organization,
          image,
          deadline: hasDeadline,
          dateStart: hasDeadline ? values.dateStart : null,
          dateEnd: hasDeadline ? values.dateEnd : null
        }
      })
      .unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        displayError(err);
      })      
    }
  }

  return (
    <Modal {...{ isOpen }}>
      {!isSuccess &&
      <LoadingSpinner />
      }      
      {isSuccess && (
        <FormWrap>
          <Formik
            initialValues={initialData}
            validationSchema={Yup.object({
              title: Yup.string().max(255, 'Title is too long').required('Title is required'),
              description: Yup.string().required('Description is required'),
              // image: Yup.string().required('Image is required'),
              dateStart: Yup.date(),
              dateEnd: Yup.date(),
              organization: Yup.string().required('Organization is required')
            })}
            onSubmit={(values) => {
              handleSubmitForm(values, editPostId)
            }}
          >
            {({ values, setFieldValue, errors }) => (
              <StyledForm>
                <TitleWrap>
                  <Typography align="center" variant="headingM">{ action } Post</Typography>
                </TitleWrap>
                { showOrganization &&
                  <OrganisationWrapper>
                    <FormSelectOneInput
                      inputName="organization"
                      label="Organisation"
                      options={organizationList}
                      size="100%"
                      value={initialData.organization}
                      isGrey
                    />
                  </OrganisationWrapper>
                }
                <FormInputsWrap>
                  <LeftHalf>
                    <TextInput
                      size={INPUT_SIZES.SMALL}
                      inputName="title"
                      label="Title"
                      placeholder="Enter title"
                    />
                    <TextAreaInput
                      inputName="description"
                      label="Description"
                      rows={14}
                      placeholder="Enter description"
                      labelMarginBottom={0}
                    />
                  </LeftHalf>
                  <RightHalf>
                    <ImageUploading
                      value={values.image}
                      onChange={(image) => {
                        setFieldValue(
                          'image',
                          getImageBase64({ source: image })
                        )
                      }}
                      acceptType={['jpg', 'gif', 'png']}
                      maxFileSize={4000000}
                      dataURLKey="data_url"
                    >
                      {({ onImageUpload }) => (
                        <>
                          <ImageUploadWrap>
                            <ImageUploadContent>
                              <Typography
                                variant="headingS"
                                color={theme.colors.skyNeutral_2}
                              >
                                Image
                              </Typography>
                              <Typography
                                variant="textXS"
                                color={theme.colors.skyNeutral2}
                              >
                                File type (JPG, JPEG, or PNG) max. 4MB
                              </Typography>
                              <ImageControlButtonsWrap>
                                <Button
                                  type="button"
                                  variant={BUTTON_VARIANTS.OUTLINED}
                                  size={BUTTONS_SIZES.SMALL}
                                  onClick={onImageUpload}
                                >
                                  Upload
                                </Button>
                                <Button
                                  type="button"
                                  variant={BUTTON_VARIANTS.SECONDARY}
                                  size={BUTTONS_SIZES.SMALL}
                                  onClick={() => {
                                    setFieldValue('image', '')
                                  }}
                                >
                                  Remove
                                </Button>
                              </ImageControlButtonsWrap>
                              <UploadImagePreview
                                height={176}
                                uploadedImage={values.image}
                              />
                              <InputError>{errors.image ? 'Image is required!' : null}</InputError>
                            </ImageUploadContent>
                          </ImageUploadWrap>
                        </>
                      )}
                    </ImageUploading>
                    <DateWrap>
                      <Typography variant="textM">Event</Typography>
                      <SwitchInput inputName="deadline" label="" value="1" />
                    </DateWrap>
                    {values.deadline[0] === '1' && (
                      <DateWrap>
                        <DateInput
                          label="Start"
                          inputName="dateStart"
                          placeholder="Post on"
                          minValue={dayjs().format()}
                        />
                        <DateInput
                          label="End"
                          inputName="dateEnd"
                          placeholder="Delete on"
                          minValue={values.dateStart}
                        />
                      </DateWrap>
                    )}
                  </RightHalf>
                </FormInputsWrap>
                {error && 
                  <ErrorMessageWrap>
                    <Typography variant="textS" color="red">
                    {error}
                    </Typography>
                  </ErrorMessageWrap>          
                }
                <ActionButtonsSection>
                  <ButtonWrap>
                    <Button
                      type="submit"
                      variant={BUTTON_VARIANTS.PRIMARY}
                      size={BUTTONS_SIZES.MED}
                    >
                      Save
                    </Button>
                    <Button
                      type="button"
                      variant={BUTTON_VARIANTS.SECONDARY}
                      size={BUTTONS_SIZES.MED}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                  </ButtonWrap>
                </ActionButtonsSection>
              </StyledForm>
            )}
          </Formik>
        </FormWrap>
      )}
    </Modal>
  )
}

export default withTheme(AddOrganizationPostModal)

AddOrganizationPostModal.defaultProps = {
  editPostId: null,
  organizationId: null
}

AddOrganizationPostModal.propTypes = {
  theme: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editPostId: PropTypes.string,
  organizationId: PropTypes.string,
  showOrganization: PropTypes.bool.isRequired
}
