import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  useLazyGetOrganizationProductStoresQuery,
  useDeleteOrganizationProductsToShopsMutation  
} from '../../api/api.generated'
import { getErrorMessage } from '../../utils/helpers'
import ViewStoresModal from '../ViewStoresModal'

const ViewOrganizationProductStoresModal = ({ organizationId, productId, onClose }) => {
  const [ getOrganizationProductStores, { data: shopsTableData, isLoading } ] = useLazyGetOrganizationProductStoresQuery()
  const [deleteProducts] = useDeleteOrganizationProductsToShopsMutation()
  const [error, setError] = useState('')

  const loadStores = (pageIndex, pageSize, search) => {
    setError('')
    getOrganizationProductStores({ productId, page: pageIndex, limit: pageSize, search })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  const deleteFromStores = (selectedRowIds) => {
    setError('')
    deleteProducts({ body: {
      organizationId,
      organizationProductIds: [productId],
      shopIds: selectedRowIds
    } })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })      
  }

  return (
    <ViewStoresModal
      title="Product Stores"
      shopsTableData={shopsTableData}
      isLoading={isLoading}     
      error={error}
      onLoad={loadStores} 
      onDelete={deleteFromStores} 
      onClose={onClose} 
    />
  )
}

export default ViewOrganizationProductStoresModal

ViewOrganizationProductStoresModal.defaultProps = {}

ViewOrganizationProductStoresModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}
