import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { isNull } from 'lodash/lang'
import { withTheme } from 'styled-components'
import { getErrorMessage } from '../../../utils/helpers'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import TextInput from '../../../components/UI/Inputs/TextInput'
import TextAreaInput from '../../../components/UI/Inputs/TextAreaInput'
import ShowErrorModal from '../../../components/ShowErrorModal'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import { ErrorMessageWrap } from '../../../global/styles'
import {
  useLazyGetShopQuestionQuery,
  usePostShopQuestionMutation,
  usePutShopQuestionMutation
} from '../../../api/shopQuestionsApi'
import {
  ActionButtonsSection,
  ButtonWrap,
  FormInputsWrap,
  StyledForm
} from './styles'

const emptyQuestion = {
  question: '',
  answer: ''
}
const ShopQuestionModal = ({ isOpen, onClose, editQuestionId, theme, shopId }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [initialData, setInitialData] = useState(emptyQuestion)
  const [error, setError] = useState('')
  const [getQuestion, { data: shopQuestion, error: loadError }] = useLazyGetShopQuestionQuery()
  const [postShopQuestion] = usePostShopQuestionMutation()
  const [updateShopQuestion] = usePutShopQuestionMutation()

  const title = (isNull(editQuestionId)) ? 'Add Question' : 'Edit Question';

  useEffect(() => {
    if (!isNull(editQuestionId)) {
      getQuestion(editQuestionId)
    } else {
      setInitialData(emptyQuestion)
      setIsSuccess(true)
    }
  }, [])
  
  useEffect(() => {
    if (shopQuestion) {
      setInitialData({
        question: shopQuestion.question,
        answer: shopQuestion.answer
      })
      setIsSuccess(true)
    }
  }, [shopQuestion])  

  const handleSubmitForm = (values) => {
    setError('')

    if (editQuestionId) {
      updateShopQuestion({
        questionId: editQuestionId,
        data: {
          ...values
        }
      })
      .unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })      
    } else {
      postShopQuestion({
        ...values, shopId
      })
      .unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })       
    }
  }

  return (
    <Modal {...{ isOpen }}>
      {!isSuccess && !loadError && 
      <LoadingSpinner />
      }      
      {loadError && 
        <ShowErrorModal
          title={title}
          error="Network Error"
          onClose={onClose}
        />
      }
      {isSuccess &&     
        <Formik
          initialValues={initialData}
          validationSchema={Yup.object({
            question: Yup.string().required('Question is required'),
            answer: Yup.string().required('Answer is required')
          })}
          onSubmit={(values) => {
            handleSubmitForm(values)
          }}
        >
          <StyledForm>
            <FormInputsWrap>
              <Typography variant="headingM" color={theme.colors.skyNeutral_2}>{title}</Typography>
              <TextInput
                size={INPUT_SIZES.SMALL}
                inputName="question"
                label="Question"
                placeholder="Enter question"
              />
              <TextAreaInput
                inputName="answer"
                label="Answer"
                rows={12}
                placeholder="Enter answer"
              />   
            </FormInputsWrap>
            {error && 
              <ErrorMessageWrap>
                <Typography variant="textS" color="red">
                {error}
                </Typography>
              </ErrorMessageWrap>          
            }              
            <ActionButtonsSection>
              <ButtonWrap>
                <Button
                  type="submit"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                >
                  Save
                </Button>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTONS_SIZES.MED}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </ButtonWrap>
            </ActionButtonsSection>
          </StyledForm>
        </Formik>
      }
    </Modal>
  )
}

export default withTheme(ShopQuestionModal)

ShopQuestionModal.defaultProps = {
  editQuestionId: null,
  shopId: null
}

ShopQuestionModal.propTypes = {
  theme: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editQuestionId: PropTypes.number,
  shopId: PropTypes.string
}
