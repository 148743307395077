import React from 'react'
import { useSelector } from 'react-redux'
import { selectShopId } from '../../../app/selectors'
import ShopAbout from '../../../components/ShopAbout'

const AboutShop = () => {
  const shopId = useSelector(selectShopId)

  return (
    <ShopAbout shopId={shopId} />
  )
}

export default AboutShop

AboutShop.propTypes = {}
