import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  BadgeWrap,
  ButtonWrap,
  PostDetailsHeader,
  PostImage,
  PostDetailsWrap
} from './styles'
import {
  useGetOrganizationPostQuery,
  useDeleteOrganizationPostMutation
} from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner'
import {
  BADGE_SIZES,
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../../const/UIvariants'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsInfoBlock
} from '../../../global/styles'
import { formatPostDeadline, getImageFromServer } from '../../../utils/helpers'
import Badge from '../../../components/UI/Badge'
import Button from '../../../components/UI/Buttons/Button/Button'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import Typography from '../../../components/UI/Typography'

const PostDetails = ({ activePostId, closePanel }) => {
  const [error, setError] = useState('')
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const { data: postData, isLoading, error: isPostError } = useGetOrganizationPostQuery(activePostId)
  const [deletePost, { isLoading: deletingPost }] = useDeleteOrganizationPostMutation()

  const displayError = (err) => {
    if (err instanceof Error) {
      setError(err.message)
    } else if (err instanceof Object) {
      let message = '';
      Object.keys(err).forEach((key) => {
        message += `${err[key]} `;
      });
      setError(message)
    } else {
      setError('Network error')
    }    
  }

  const toggleConfirmationModal = () => {
    setShowConfirmDelete((prev) => !prev)
  }

  const handleDeletePost = () => {
    deletePost({ postId: activePostId })
      .unwrap()
      .then(() => {
        closePanel()
      })
      .catch((err) => {
        toggleConfirmationModal()
        displayError(err);
      })      
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {isPostError && 
        <Typography variant="textS" color="red">Network Error</Typography>
      }        
      {postData && (
        <>
          <PostDetailsHeader>
            {postData.image && (
              <PostImage
                src={getImageFromServer(postData.image)}
                alt="omg"
              />
            )}
            <AsideDetailsTitle align="center">
              {postData.title}
            </AsideDetailsTitle>
          </PostDetailsHeader>

          <PostDetailsWrap>
            <AsideDetailsTitle>Post Details</AsideDetailsTitle>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
              Organisation
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {postData.organizationName}
              </AsideDetailsDescription>
            </DetailsInfoBlock>

            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Description
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {postData.description}
              </AsideDetailsDescription>
            </DetailsInfoBlock>

            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Event</AsideDetailsLabel>
              <BadgeWrap>
                <Badge
                  size={BADGE_SIZES.SMALL}
                  variant={
                    postData.startTime && postData.endTime
                      ? BADGE_VARIANTS.PINK
                      : BADGE_VARIANTS.LIGHT
                  }
                  label={formatPostDeadline(
                    postData.startTime,
                    postData.endTime
                  )}
                />
              </BadgeWrap>
            </DetailsInfoBlock>
            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTONS_SIZES.MED}
                onClick={toggleConfirmationModal}
                isLoading={deletingPost}
                isFullWidth
              >
                Delete
              </Button>
            </ButtonWrap>
            {error && 
              <Typography variant="textS" color="red">
              {error}
              </Typography>
            }            
          </PostDetailsWrap>
          <DeleteConfirmationModal
            isOpen={showConfirmDelete}
            onClose={toggleConfirmationModal}
            entityName={postData.title}
            entityType="Post"
            handleDelete={handleDeletePost}
          />
        </>
      )}
    </>
  )
}

export default PostDetails
PostDetails.defaultProps = {
  activePostId: null
}
PostDetails.propTypes = {
  activePostId: PropTypes.string,
  closePanel: PropTypes.func.isRequired
}
