import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash/lang'
import { withTheme } from 'styled-components'
import { useLazyGetOrganizationDashboardQuery } from '../../../api/dashboardApi'
import ROUTES from '../../../const/routes'
import { getArraySlice } from '../../../utils/helpers'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import Button from '../../../components/UI/Buttons/Button'
import ShopPostItem from '../../../components/ShopPostItem'
import Paginator from '../../../components/Paginator'
import SelectOneInput from '../../../components/UI/Inputs/SelectOneInput'
import DateRangeModal from '../../../components/DateRangeModal/DateRangeModal'
import DashboardStatistics from '../../../components/DashboardStatistics'

import { 
  Footer, 
  ErrorMessageTopWrap
} from '../../../global/styles'
import {
  ContentWrap,
  HeadlineWrap,
  LatestPosts,
  LatestPostsHeadline,
  ManagePostsLink,
  PostsWrap,
  ButtonWrap
} from './styles'

const TIME_FRAME_OPTIONS = [
  { value: 1, label: '1 Month summary' },
  { value: 3, label: '3 Month summary' },
  { value: 6, label: '6 Month summary' },
  { value: 12, label: '12 Month summary' }
]
const ITEMS_PER_PAGE = 50

const OrganizationDashboardMainSection = ({ theme }) => {
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(1)
  const [pageData, setPageData] = useState([])
  const [dateRange, setDateRange] = useState({from: "", to: ""})
  const [dateRangeModalIsOpen, setDateRangeModalIsOpen] = useState(false)
  const [getOrganizationDashboard, { data: dashboardData, isLoading, error: loadError }] = useLazyGetOrganizationDashboardQuery()

  const onDateRangeSubmit = (values) => {
    setDateRangeModalIsOpen(false)
    setDateRange({from: values.dateFrom, to: values.dateTo})
    getOrganizationDashboard({ timeFrame: 0, from: dayjs(values.dateFrom).format('YYYY-MM-DD'), to: dayjs(values.dateTo).format('YYYY-MM-DD') })
  }

  useEffect(() => {
    getOrganizationDashboard({ timeFrame: selectedTimeFrame, from: "", to: "" })
  }, [])

  useEffect(() => {
    getOrganizationDashboard({ timeFrame: selectedTimeFrame, from: "", to: "" })
  }, [selectedTimeFrame])

  useEffect(() => {
    setPageData(
      getArraySlice(dashboardData?.latestPosts, {
        page: currentPage,
        perPage: ITEMS_PER_PAGE
      })
    )
  }, [currentPage, dashboardData?.latestPosts])

  return (
    <ContentWrap>
      <HeadlineWrap>
        <Typography variant="headingL" color={theme.colors.skyNeutral_2}>
          Activity of your organisation
        </Typography>
        
        {!isEmpty(dashboardData) &&
        <ButtonWrap>
          <SelectOneInput
            options={TIME_FRAME_OPTIONS}
            selected={selectedTimeFrame}
            setSelected={setSelectedTimeFrame}
            size="250px"
          />
          <Button
            type="button"
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.SMALL}
            onClick={() => setDateRangeModalIsOpen(true)}
          >
            Date Range
          </Button>          
        </ButtonWrap>
        }
      </HeadlineWrap>

      { isLoading && 
        <LoadingSpinner />
      }
      { loadError &&
        <ErrorMessageTopWrap>
          <Typography variant="textS" color="red">Network Error</Typography>
        </ErrorMessageTopWrap>
      }  
      {!isEmpty(dashboardData) && (
        <DashboardStatistics dashboardData={dashboardData} />
      )}
      {dashboardData && (
        <LatestPosts>
          <DateRangeModal
            isOpen={dateRangeModalIsOpen}
            onClose={() => setDateRangeModalIsOpen(false)}
            onSubmit={onDateRangeSubmit}
            dateFrom={dateRange.from}
            dateTo={dateRange.to}
          />          
          <LatestPostsHeadline>
            <Typography variant="textL" color={theme.colors.skyNeutral_2}>
              Latest Posts
            </Typography>
            { false &&
            <ManagePostsLink
              variant="textS"
              color={theme.colors.skyNeutral1}
              onClick={() => {
                history.push(ROUTES.SHOP_PROFILE_POSTS)
              }}
            >
              Manage Posts
            </ManagePostsLink>
            }
          </LatestPostsHeadline>
          <PostsWrap>
            {pageData.map((post) => (
              <ShopPostItem
                key={post.id}
                postId={post.id}
                postImage={post.postImage}
                postDeadline={post.deadline}
                postLikeCount={post.likeCount}
                postInterestedCount={post.interestedCount}
                postAttendedCount={post.attendedCount}
                postDescription={post.description}
                postTitle={post.title}
                dateEnd={post.dateEnd}
                dateStart={post.dateStart}
                showCounts={false}
                showStore
                storeName={post.shopName}
                storeImage={post.shopImage}
                storeBgColor={post.avatarBackground}
                deadlineText={post.deadlineText}
                canEdit={false}
                canDelete={false}
              />
            ))}
          </PostsWrap>
          <Footer>
            <Typography variant="textM" color={theme.colors.skyNeutral2}>
              {`${pageData?.length} from ${dashboardData?.latestPosts?.length} Posts`}
            </Typography>
            <Paginator
              amountOfPages={Math.ceil(
                dashboardData?.latestPosts?.length / ITEMS_PER_PAGE
              )}
              {...{ currentPage, setCurrentPage }}
            />
          </Footer>
        </LatestPosts>
      )}
    </ContentWrap>
  )
}

export default withTheme(OrganizationDashboardMainSection)

OrganizationDashboardMainSection.propTypes = {
  theme: PropTypes.object.isRequired
}
