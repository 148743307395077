import React, { useEffect, useMemo, useState } from 'react'
import {
  useTable,
  useRowSelect,
  usePagination,
  useGlobalFilter
} from 'react-table'
import dayjs from 'dayjs'
import { Search } from '@styled-icons/material'
import { getErrorMessage } from '../../../utils/helpers'
import { getMetrics, tableColumns } from '../helpers/shopsHelpers'
import { useLazyGetOrdersQuery } from '../../../api/api.generated'
import { BUTTON_VARIANTS, BUTTONS_SIZES, INPUT_SIZES } from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import MetricItem from '../../../components/MetricItem'
import LoadingSpinner from '../../../components/LoadingSpinner'
import DashboardHeader from '../../../components/DashboardHeader'
import TableButtonCell from '../../../components/TableButtonCell'
import PaginatedEntityTable from '../../../components/EntityTable/PaginatedEntityTable'
import CustomTextInput from '../../../components/UI/Inputs/TextInput/CustomTextInput'
import SelectOneInput from '../../../components/UI/Inputs/SelectOneInput'
import Button from '../../../components/UI/Buttons/Button'
import DateRangeModal from '../../../components/DateRangeModal/DateRangeModal'
import DetailsAsidePanel from '../../../components/DetailsAsidePanel'
import OrderTableRow from '../OrderTableRow'
import OrderDetails from '../OrderDetails'
import {
  Toolbar,
  ButtonWrap
} from '../../../global/styles'
import { MetricsItem, ShopsDashboardWrap, ShopsWrap, DateButtonWrap } from './styles'

const TIME_FRAME_OPTIONS = [
  { value: 1, label: '1 Month summary' },
  { value: 3, label: '3 Month summary' },
  { value: 6, label: '6 Month summary' },
  { value: 12, label: '12 Month summary' }
]

const ManageOrders = () => {
  const [metrcis, setMetrics] = useState([])
  const [dateRangeModalIsOpen, setDateRangeModalIsOpen] = useState(false)
  const [error, setError] = useState('')
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(1)
  const [dateRange, setDateRange] = useState({from: "", to: ""})
  const [search, setSearch] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [showDetails, setShowDetails] = useState(false)
  const [activeId, setActiveId] = useState(null)
  const [getOrders, { data: ordersTableData, isLoading }] = useLazyGetOrdersQuery()

  const columns = React.useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
      ordersTableData
        ? ordersTableData?.orders?.map((orderItem) => OrderTableRow(orderItem))
        : [],
    [ordersTableData]
  )

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { pageIndex, pageSize },
    pageOptions,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.id,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
        },
        ...columnsList,
        {
          id: 'buttons',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableButtonCell
              cell={cell}
              canEdit={false}
              showView={false}
              showDelete={false}
            />
          )
        }
      ])
    }
  )

  const loadOrders = () => {
    let timeFrame = selectedTimeFrame
    if (dateRange.from !== "") {
      timeFrame = 0
    }
    setError('')
    getOrders({ page: pageIndex, limit: pageSize, search, timeFrame, from: dateRange.from, to: dateRange.to })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  const onTimeframeChange = (value) => {
    setSelectedTimeFrame(value)
    setDateRange({from: "", to: ""})
  }

  const onDateRangeSubmit = (values) => {
    setDateRangeModalIsOpen(false)
    setDateRange({from: dayjs(values.dateFrom).format('YYYY-MM-DD'), to: dayjs(values.dateTo).format('YYYY-MM-DD')})
  }  

  const handleSearch = () => {
    if (pageIndex === 0) {
      loadOrders()
    } else {
      gotoPage(0)
    }
  }
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const handleShowDetails = (id) => {
    if (id !== activeId) {
      setShowDetails(true)
      setActiveId(id)
    } else {
      setShowDetails(false)
      setActiveId(null)
    }
  }

  const handleCloseDetails = () => {
    setShowDetails(false)
  }

  useEffect(() => {
    loadOrders()
  }, [])

  useEffect(() => {
    if (ordersTableData) {
      setTotalPages(ordersTableData.pagination.pages)
      setTotalRows(ordersTableData.pagination.total)
      if (pageIndex >= ordersTableData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [ordersTableData])

  useEffect(() => {
    handleSearch()
  }, [selectedTimeFrame, dateRange])

  useEffect(() => {
    if (ordersTableData) {
      loadOrders()
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (ordersTableData) {
      setMetrics(getMetrics(ordersTableData?.dashboardInfo))
    }
  }, [ordersTableData])

  return (
    <ShopsDashboardWrap>
      <ShopsWrap>
        <DashboardHeader
          headline="Orders"
          disableDelete
        />
        {isLoading && <LoadingSpinner />}
        {error && 
          <Toolbar>
            <Typography variant="textS" color="red">{error}</Typography>
          </Toolbar>
        }
        {ordersTableData && (
          <>
            <MetricsItem>
              {metrcis.map((metric) => (
                <MetricItem
                  key={metric.metricName}
                  description={metric.description}
                  icon={metric.icon}
                  iconBgColor={metric.iconBgColor}
                  id={metric.metricName}
                  isActive={metric.isActive}
                  metricName={metric.metricName}
                  numberAndIconColor={metric.numberAndIconColor}
                  value={metric.value}
                />
              ))}
            </MetricsItem>
            <Toolbar>
              <DateButtonWrap>
                <SelectOneInput
                  options={TIME_FRAME_OPTIONS}
                  selected={selectedTimeFrame}
                  setSelected={onTimeframeChange}
                  size="250px"
                />
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  onClick={() => setDateRangeModalIsOpen(true)}
                >
                Date Range
                </Button>
              </DateButtonWrap>
              <CustomTextInput
                handleKeyPress={handleKeyPress}
                handleChange={(e) => {
                  setSearch(e.target.value)
                }}
                placeholder="Search by code"
                inputName="search"
                size={INPUT_SIZES.SMALL}
                value={search}
                leftIcon={<Search />}
                isWhite
              />
              <ButtonWrap>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </ButtonWrap>
            </Toolbar>

            <PaginatedEntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                pageOptions,
                pageIndex,
                gotoPage,
                totalRows
              }}
              handleShowDetails={handleShowDetails}
              activeItemId={activeId}             
            />
            <DateRangeModal
              isOpen={dateRangeModalIsOpen}
              onClose={() => setDateRangeModalIsOpen(false)}
              onSubmit={onDateRangeSubmit}
              dateFrom={dateRange.from}
              dateTo={dateRange.to}
            />            
          </>
        )}
      </ShopsWrap>
      { showDetails &&
      <DetailsAsidePanel isOpen={showDetails} closePanel={handleCloseDetails}>
        <OrderDetails
          activeId={activeId}
          showStore
        />
      </DetailsAsidePanel>
      }      
    </ShopsDashboardWrap>
  )
}

export default ManageOrders

ManageOrders.propTypes = {}
