import styled from 'styled-components'
import { HStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'

export const EntityDashboardHeadline = styled(HStack)`
  width: 100%;
  margin-bottom: 30px;
  background: none;
  justify-content: space-between;
  align-items: center;
`
export const SectionName = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_2};
`
export const ButtonWrap = styled(HStack)`
  justify-content: space-between;
  button:first-child {
    margin-right: 10px;
  }
  button:nth-child(2) {
    margin-right: 10px;
  }
  button:nth-child(3) {
    margin-right: 10px;
  }
  button:nth-child(4) {
    margin-right: 10px;
  }
  //flex-basis: 400px;
`
export const AddButton = styled(Button).attrs(({ theme }) => ({
  fillColor: theme.colors.green_1
}))``
