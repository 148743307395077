import React from 'react'
import PropTypes from 'prop-types'
import { Schedule } from '@styled-icons/material'
import { HoursWrap, OpenHours } from './styles'
import Typography from '../UI/Typography'
import { globalTheme } from '../../global/theme'
import { getHourAMPM } from '../../utils/helpers'

// eslint-disable-next-line no-unused-vars
const EditWorkingHours = ({ open, close, day, isOpen, setDay, is24h }) => {
  const handleSetDay = () => {
    setDay(day)
  }

  const getTimeStr = () => {
    if (is24h) {
      return "24h"
    }
    return `${getHourAMPM(open).toUpperCase()} - ${getHourAMPM(close).toUpperCase()}`
  }

  return (
    <>
      {isOpen ? (
        <HoursWrap alignItems="center" onClick={handleSetDay}>
          <OpenHours
            variant="textS"
            color={globalTheme.colors.skyNeutral2}
          >
            {getTimeStr()}
          </OpenHours>
          <Schedule size={20} color={globalTheme.colors.skyBase} />
        </HoursWrap>
      ) : (
        <Typography
          align="right"
          color={globalTheme.colors.skyNeutral2}
          variant="textM"
        >
          Closed
        </Typography>
      )}
    </>
  )
}

export default EditWorkingHours 
EditWorkingHours.defaultProps = {
  is24h: false
}
EditWorkingHours.propTypes = {
  open: PropTypes.string.isRequired,
  close: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  is24h: PropTypes.bool,
  setDay: PropTypes.func.isRequired
}
