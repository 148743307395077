import styled from 'styled-components'
import { HStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const EntityDashboardHeadline = styled(HStack)`
  width: 100%;
  margin-bottom: 30px;
  background: none;
  justify-content: space-between;
  align-items: center;
`
export const SectionName = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_2};
`
export const MetricsWrap = styled(HStack)`
  justify-content: space-between;
  width: 100%;
`
export const DateButtonWrap = styled(HStack)`
  & > :nth-child(2) {
    margin-left: 10px;
    height: 44px;
  }
`