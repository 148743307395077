import React, { useState } from 'react'
import {
  Add,
  Album,
  Bathtub,
  Checkroom,
  Devices,
  House,
  RestaurantMenu,
  ShoppingBasket
} from '@styled-icons/material'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { isEmpty } from 'lodash/lang'
import { find } from 'lodash/collection'
import { useGetShopDonationsQuery, useDeleteShopDonationMutation } from '../../../api/shopDonationsApi'
import { useGetCategoriesQuery } from '../../../api/generalApi'
import { getErrorMessage } from '../../../utils/helpers'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import Typography from '../../../components/UI/Typography'
import { BUTTON_VARIANTS, BUTTONS_SIZES, BADGE_SIZES, BADGE_VARIANTS } from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button'
import EditShopDonationsModal from '../EditShopDonationsModal'
import ShopDonationModal from '../ShopDonationModal'
import EditDeleteMenu from '../../../components/EditDeleteMenu'
import Badge from '../../../components/UI/Badge'
import { ErrorMessageTopWrap } from '../../../global/styles'
import {
  DonationIconWrap,
  DonationsWrapper,
  DonationWrap,
  EditShopDonations,
  IconWrap,
  LabelWrap,
  NoDataWrap,
  DonationDetailsWrap,
  DonationNameWrap,
  DonationDataWrap
} from './styles'

const donationsIcons = {
  checkroom: <Checkroom />,
  bathtub: <Bathtub />,
  house: <House />,
  devices: <Devices />,
  restaurant_menu: <RestaurantMenu />,
  album: <Album />,
  local_mall: <ShoppingBasket />
}
const ShopDonations = ({ theme, shopId }) => {
  const [editDonations, setEditDonations] = useState(false)
  const [editDonation, setEditDonation] = useState(false)
  const [editDonationId, setEditDonationId] = useState(null)
  const [error, setError] = useState('')
  const { data: shopDonations, isLoading, error: loadError } = useGetShopDonationsQuery(shopId)
  const { data: categories } = useGetCategoriesQuery()
  const [deleteShopDonation] = useDeleteShopDonationMutation()

  const handleToggleEditDonations = () => {
    setError("")
    setEditDonations((state) => !state)
  }

  const handleEditDonation = (donationId) => {
    setError("")
    setEditDonationId(parseInt(donationId, 10));
    setEditDonation(true)
  }

  const handleDeleteDonation = (donationId) => {
    setError("")
    deleteShopDonation(donationId)
      .unwrap()
      .then(() => {
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }
  
  const handleCloseEditDonation = () => {
    setEditDonationId(null);
    setEditDonation(false);
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {loadError && 
        <ErrorMessageTopWrap>
          <Typography variant="textS" color="red">Network Error</Typography>
        </ErrorMessageTopWrap>
      }
      {error &&
        <ErrorMessageTopWrap>
          <Typography variant="textS" color="red">{error}</Typography>
        </ErrorMessageTopWrap>
      }       
      {categories && (
        <>
          <EditShopDonations>
            <LabelWrap>
              <IconWrap>
                <ShoppingBasket size={19} color={theme.colors.greenBase} />
              </IconWrap>
              <Typography
                variant="textS"
                color={theme.colors.greenCreator(7, 46)}
              >
                Edit Your Store Donations
              </Typography>
            </LabelWrap>
            <div>
              <Button
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.SMALL}
                fillColor={theme.colors.green_1}
                type="button"
                onClick={handleToggleEditDonations}
              >
                <Add size={18} color={theme.colors.white} />
                Edit Store Donations
              </Button>
            </div>
          </EditShopDonations>
        </>
      )}
      {categories && shopDonations && (isEmpty(shopDonations.categories) ? (
        <NoDataWrap>
          <Typography variant="headingS">No Donation Added Yet</Typography>
        </NoDataWrap>
        ) : (
        <DonationsWrapper>
          {shopDonations.donations.map((donation) => {
            const categoryDetails = find(
              categories,
              (category) => category.id === donation.tag_id.toString()
            )
            return (
              <DonationWrap key={donation.id}>
                <DonationIconWrap
                  backgroundColor={categoryDetails.backgroundColor}
                >
                  {React.cloneElement(donationsIcons[categoryDetails.icon], {
                    width: 28,
                    height: 28,
                    color: theme.colors.white
                  })}
                </DonationIconWrap>
                <DonationDetailsWrap>
                  <DonationNameWrap>
                    <DonationDataWrap>
                      <Typography variant="textM" color={theme.colors.skyNeutral1}>
                        {categoryDetails.tagName}
                      </Typography>
                      <Badge size={BADGE_SIZES.SMALL} variant={BADGE_VARIANTS.PRIMARY} label='Taken' />
                    </DonationDataWrap>
                    <EditDeleteMenu
                      onEdit={() => {
                        handleEditDonation(donation.id)
                      }}
                      onDelete={() => {
                        handleDeleteDonation(donation.id)
                      }}
                    />
                  </DonationNameWrap>
                </DonationDetailsWrap>
              </DonationWrap>
            )
          })}
        </DonationsWrapper>
      ))}
      {editDonations &&
      <EditShopDonationsModal
        isOpen={editDonations}
        onClose={handleToggleEditDonations}
        shopId={shopId}
      />
      }
      {editDonation &&
      <ShopDonationModal
        isOpen={editDonation}
        onClose={handleCloseEditDonation}
        editDonationId={editDonationId}
        shopId={shopId}
      />
      }
    </>
  )
}

export default withTheme(ShopDonations)

ShopDonations.propTypes = {
  theme: PropTypes.object.isRequired,
  shopId: PropTypes.string.isRequired
}
