import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../const/UIvariants'
import Button from '../UI/Buttons/Button'
import {
  ButtonWrap,
  Headline,
  ModalHeader,
  ModalWrap,
  ErrorWrap
} from './styles'

const ShowMessageModal = ({
  onClose,
  title,
  message
}) => (
  <Modal isOpen >
    <ModalWrap>
      <ModalHeader>
        <Headline bold>
          { title }
        </Headline>
        <ErrorWrap>
          {message}
        </ErrorWrap>          
      </ModalHeader>
      <ButtonWrap>
        <Button
          variant={BUTTON_VARIANTS.SECONDARY}
          size={BUTTONS_SIZES.MED}
          onClick={onClose}
        >
          Close
        </Button>
      </ButtonWrap>
    </ModalWrap>
  </Modal>
)

export default ShowMessageModal

ShowMessageModal.defaultProps = {
  title: "",
  message: ""
}

ShowMessageModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired
}
