import React from 'react'
import PropTypes from 'prop-types'
import { Add, Edit, Stop, DeleteOutline } from '@styled-icons/material'
import { withTheme } from 'styled-components'
import {
  ButtonWrap,
  EntityDashboardHeadline,
  SectionName,
  AddButton
} from './styles'
import Button from '../../../components/UI/Buttons/Button/Button'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'

const ShopDashboardHeader = ({
  headline,
  handleDelete,
  handleEdit,
  handleEditTag,
  handleDeactivate,
  handleAddNew,
  noActions,
  showAdd,
  showEditTag,
  theme
}) => (
  <EntityDashboardHeadline>
    <SectionName variant="headingM" bold>
      {headline}
    </SectionName>
    <ButtonWrap>
      { showAdd &&
      <AddButton
        type="button"
        variant={BUTTON_VARIANTS.PRIMARY}
        size={BUTTONS_SIZES.SMALL}
        leftIcon={<Add />}
        onClick={handleAddNew}
        isFullWidth={false}
      >
        Add Store
      </AddButton>
      }    
      {!noActions && (
        <>
          <Button
            type="button"
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.SMALL}
            leftIcon={<Edit />}
            onClick={handleEdit}
            isFullWidth={false}
            fillColor={theme.colors.blue_1}
          >
            Edit Selected
          </Button>
          {showEditTag &&
          <Button
            type="button"
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.SMALL}
            leftIcon={<Edit />}
            onClick={handleEditTag}
            isFullWidth={false}
            fillColor={theme.colors.blue_1}
          >
            Category Hours
          </Button>
          }
          <Button
            type="button"
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.SMALL}
            leftIcon={<Stop />}
            onClick={handleDeactivate}
            isFullWidth={false}
            fillColor={theme.colors.blue_1}
          >
            Deactivate Selected
          </Button>
          <Button
            type="button"
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.SMALL}
            leftIcon={<DeleteOutline />}
            onClick={handleDelete}
            isFullWidth={false}
            fillColor="#f44336"
          >
            Delete Selected
          </Button>
        </>
      )}
    </ButtonWrap>
  </EntityDashboardHeadline>
)

export default withTheme(ShopDashboardHeader)

ShopDashboardHeader.defaultProps = {
  showAdd: true,
  showEditTag: false,
  noActions: false,
  handleDelete: () => {},
  handleEdit: () => {},
  handleEditTag: () => {},
  handleDeactivate: () => {},
  handleAddNew: () => {}
}
ShopDashboardHeader.propTypes = {
  showAdd: PropTypes.bool,
  showEditTag: PropTypes.bool,
  noActions: PropTypes.bool,
  headline: PropTypes.string.isRequired,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleEditTag: PropTypes.func,
  handleDeactivate: PropTypes.func,
  handleAddNew: PropTypes.func,
  theme: PropTypes.object.isRequired
}
