import React from 'react'
import PropTypes from 'prop-types'
import {
  useGetStoreReportQuery
} from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsInfoBlock,
  DetailsPanelTopSection
} from '../../../global/styles'
import {
  TicketDetailsWrap
} from './styles'

const StoreReportDetails = ({ activeReportId }) => {
  const { data: reportData, isLoading } = useGetStoreReportQuery(activeReportId)

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {reportData && (
        <>
          <DetailsPanelTopSection>
            <AsideDetailsTitle align="center">
              {reportData.storeName}
            </AsideDetailsTitle>
            <AsideDetailsLabel align="center">
              {reportData.userName}
            </AsideDetailsLabel>
          </DetailsPanelTopSection>
          <TicketDetailsWrap>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Reason</AsideDetailsLabel>
              <AsideDetailsDescription>
                {reportData.reasonName}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Issue Description
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {reportData.description}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
          </TicketDetailsWrap>
        </>
      )}
    </>
  )
}

export default StoreReportDetails

StoreReportDetails.defaultProps = {
  activeReportId: ''
}

StoreReportDetails.propTypes = {
  activeReportId: PropTypes.any
}
