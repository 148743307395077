import styled from 'styled-components'
import { HStack } from '../../global/styles'

export const ButtonWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  & > :nth-child(2) {
    margin-left: 12px;
    margin-right: 12px;
  }
`

export default ButtonWrap