import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash/lang'
import { withTheme } from 'styled-components'
import { Add, VolunteerActivism } from '@styled-icons/material'
import {
  useLazyGetShopVolunteersQuery,
  useDeleteShopVolunteerMutation
} from '../../../api/shopVolunteersApi'
import { useGetShopDetailsQuery } from '../../../api/shopApi'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import { getErrorMessage } from '../../../utils/helpers'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button'
import Typography from '../../../components/UI/Typography'
import Paginator from '../../../components/Paginator'
import ShopVolunteerModal from '../ShopVolunteerModal'
import ShopVolunteerItem from '../../../components/ShopVolunteerItem'
import { Footer, ErrorMessageTopWrap } from '../../../global/styles'
import {
  AddShopVolunteers,
  LabelWrap,
  IconWrap,
  VolunteersList,
  MessageWrap,
  IsEmptyText
} from './styles'

const ITEMS_PER_PAGE = 50

const ShopVolunteers = ({ theme, shopId }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)  
  const [error, setError] = useState('')
  const [addNewModalIsOpen, setAddNewModalIsOpen] = useState(false)
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [editVolunteerId, setEditVolunteerId] = useState(null)
  const [getVolunteers, { data: shopVolunteers, isLoading, error: loadError }] = useLazyGetShopVolunteersQuery()
  const [deleteShopVolunteer] = useDeleteShopVolunteerMutation()
  const { data: shopDetails } = useGetShopDetailsQuery(shopId)

  const handleOpenAddVolunteer = () => {
    setError("")
    setAddNewModalIsOpen(true)
  }
  const handleCloseAddVolunteer = () => {
    setAddNewModalIsOpen(false)
    setEditVolunteerId(null)
  }
  const handleEditVolunteer = (volunteerId) => {
    setError("")
    setEditModalIsOpen(true)
    setEditVolunteerId(volunteerId)
  }
  const handleCloseEditVolunteer = () => {
    setEditModalIsOpen(false)
    setEditVolunteerId(null)
  }
  const handleDeleteVolunteer = (volunteerId) => {
    setError("")
    deleteShopVolunteer(volunteerId)
      .unwrap()
      .then(() => {
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })     
  }
  
  const loadVolunteers = () => {
    setError('')
    getVolunteers({ shopId, page: currentPage, limit: ITEMS_PER_PAGE })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  useEffect(() => {
    loadVolunteers()
  }, [])

  useEffect(() => {
    loadVolunteers()
  }, [currentPage])

  useEffect(() => {
    if (shopVolunteers) {
      setTotalPages(shopVolunteers.pagination.pages)
      setTotalRows(shopVolunteers.pagination.total)
      if (currentPage >= shopVolunteers.pagination.pages) {
        setCurrentPage(0)
      }
    }
  }, [shopVolunteers])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {error &&
        <ErrorMessageTopWrap>
          <Typography variant="textS" color="red">{error}</Typography>
        </ErrorMessageTopWrap>
      }      
      {isNil(shopVolunteers) && !isLoading && !loadError && <p>No volunteers added yet </p>}
      {addNewModalIsOpen &&
        <ShopVolunteerModal
          isOpen={addNewModalIsOpen}
          onClose={handleCloseAddVolunteer}
          shopId={shopId}
        />
      }
      {editModalIsOpen &&
        <ShopVolunteerModal
          isOpen={editModalIsOpen}
          onClose={handleCloseEditVolunteer}
          editVolunteerId={editVolunteerId}
          shopId={shopId}
        />
      }
      {shopVolunteers && (
        <>
          <AddShopVolunteers>
            <LabelWrap>
              <IconWrap>
                <VolunteerActivism size={19} color={theme.colors.greenBase} />
              </IconWrap>
              <Typography variant="textS" color={theme.colors.greenCreator(7, 46)}>
                Add your volunteers here
              </Typography>
            </LabelWrap>
            <div>
              <Button
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.SMALL}
                fillColor={theme.colors.green_1}
                type="button"
                onClick={handleOpenAddVolunteer}
              >
                <Add size={18} color={theme.colors.white} />
                Add Volunteer
              </Button>
            </div>
          </AddShopVolunteers>
          {shopDetails?.volunteerTitle &&
          <MessageWrap>
            <IsEmptyText>{shopDetails?.volunteerTitle}</IsEmptyText>
          </MessageWrap>
          }
          {shopDetails?.volunteerMessage &&
          <MessageWrap>
            <IsEmptyText>{shopDetails?.volunteerMessage}</IsEmptyText>
          </MessageWrap>
          }
          <VolunteersList>
            {shopVolunteers.volunteers.map((item) => (
            <ShopVolunteerItem
              key={item.id}
              volunteerId={item.id}
              volunteerName={item.name}
              volunteerEmail={item.email}
              volunteerPhone={item.phone}
              volunteerMessage={item.message}
              volunteerUser={item.userName}
              {...{ handleEditVolunteer, handleDeleteVolunteer }}
            />
            ))}
          </VolunteersList>
        </>
      )}
      {shopVolunteers && (
        <Footer>
          <Typography variant="textM" color={theme.colors.skyNeutral2}>
            {`${shopVolunteers.volunteers.length} from ${totalRows} Volunteers`}
          </Typography>
          <Paginator
            amountOfPages={totalPages}
            {...{ currentPage, setCurrentPage }}
          />
        </Footer>
      )}
    </>
  )
}

export default withTheme(ShopVolunteers)

ShopVolunteers.propTypes = { 
  theme: PropTypes.object.isRequired,
  shopId: PropTypes.string.isRequired
}
