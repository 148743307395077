import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const ShopImagesWrap = styled(HStack)`
  flex-wrap: wrap;
  margin: 16px 0;
`

export const ShopImage = styled(VStack)`
  position: relative;
  background-position: center;
  flex-basis: calc(16.66% - 2px);
  margin-right: 2px;
  ::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

export const TimeStampAndMenuWrap = styled(HStack)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: rgb(41, 41, 41);
  background: linear-gradient(
    0deg,
    rgba(41, 41, 41, 0.304359243697479) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`

export const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  width: 100%;
`
export const AddImageWrap = styled(ShopImage).attrs({ as: 'button' })`
  background-color: ${({ theme }) => theme.colors.green4};
  cursor: pointer;
  outline: none;
  max-width: 16%;
  border: none;
  align-items: center;
  justify-content: center;
  & > * {
    position: absolute;
  }
`
export const ErrorMessage = styled(Typography)`
  margin-top: 30px;
  position: absolute;
  bottom: 5px;
  line-height: 16px;
`
