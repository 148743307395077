import React, { useState } from 'react'
import { Create, LocationOn } from '@styled-icons/material'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import {
  ButtonWrap,
  ShopAddress,
  ShopAddressWrap,
  ShopDetailsHead,
  ShopName,
  ShopNameAndAddress,
  ShopNameAndLogo,
  TopBannerWrapper
} from './styles'
import ShopCoverPhoto from '../../../components/ShopCoverPhoto'
import { BUTTONS_SIZES, BUTTON_VARIANTS } from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button'
import { useGetShopDetailsQuery } from '../../../api/shopApi'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ErrorBoundary from '../../errorBoundaries/ErrorBoundary'
import ShopLogo from '../../../components/ShopLogo'
import EditShop from '../EditShop'

const ShopTopBanner = ({ shopId }) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const { data: shopDetails, isLoading } = useGetShopDetailsQuery(shopId)

  const handleSetEdit = () => {
    setShowEditModal(true)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
  }

  return (
    <TopBannerWrapper>
      <ErrorBoundary>
        <ShopCoverPhoto shopId={shopId} coverPhoto={shopDetails?.coverImage} />
        {isLoading && <LoadingSpinner />}

        {showEditModal && 
          <EditShop
            shopId={shopId}
            isOpen={showEditModal}
            onClose={handleCloseEditModal}
          />
        } 

        {shopDetails &&
          <ShopDetailsHead>
            <ShopNameAndLogo>
              <ShopLogo
                errors={false}
                logoUrl={shopDetails?.logotype}
                size={72}
              />
              <ShopNameAndAddress>
                <ShopName variant="headingM">
                  {shopDetails?.shopName}
                </ShopName>
                {shopDetails?.displayAddress && (
                  <ShopAddressWrap>
                    <LocationOn size={16} />
                    <ShopAddress variant="TextS">
                      {shopDetails?.displayAddress}
                    </ShopAddress>
                  </ShopAddressWrap>
                )}
              </ShopNameAndAddress>
            </ShopNameAndLogo>
            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                type="button"
                size={BUTTONS_SIZES.SMALL}
                leftIcon={<Create />}
                onClick={handleSetEdit}
              >
                Edit Store
              </Button>
            </ButtonWrap>
          </ShopDetailsHead>
        }
      </ErrorBoundary>
    </TopBannerWrapper>
  )
}

export default withTheme(ShopTopBanner)

ShopTopBanner.propTypes = { 
  shopId: PropTypes.string.isRequired 
}
