import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { capitalize } from 'lodash/string'
import { useFormikContext } from 'formik'
import { withTheme } from 'styled-components'
import TimeInput from '../../../components/UI/Inputs/TimeInput'
import {
  ActionButtonsSection,
  ButtonWrap,
  ContentWrap,
  Headline
} from './styles'
import Button from '../../../components/UI/Buttons/Button'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'

const ShopOperationHoursModal = ({
  isOpen,
  setSelectedDay,
  selectedDay,
  theme
}) => {
  const { values, setFieldValue } = useFormikContext()
  const [initOpen, setInitOpen] = useState(values[`${selectedDay}Open`])
  const [initClose, setInitClose] = useState(values[`${selectedDay}Close`])

  useEffect(() => {
    setInitOpen(values[`${selectedDay}Open`])
    setInitClose(values[`${selectedDay}Close`])
  }, [selectedDay])

  const handleCloseAndSave = () => {
    setSelectedDay(null)
  }

  const handleCloseAndCancel = () => {
    setFieldValue(`${selectedDay}Open`, initOpen)
    setFieldValue(`${selectedDay}Close`, initClose)
    setSelectedDay(null)
  }

  return (
    <Modal {...{ isOpen }}>
      <ContentWrap>
        <Headline variant="headingL" color={theme.colors.skyNeutral_2}>
          Hours Operational on {capitalize(selectedDay)}
        </Headline>
        <TimeInput
          inputName={`${selectedDay}Open`}
          label="Open Time"
          minTime={new Date('2021-07-15T00:00:00.0000')}
          maxTime={new Date(values[`${selectedDay}Close`])}
        />
        <TimeInput
          inputName={`${selectedDay}Close`}
          minTime={new Date(values[`${selectedDay}Open`])}
          label="Close Time"
        />
      </ContentWrap>

      <ActionButtonsSection>
        <ButtonWrap>
          <Button
            type="button"
            variant={BUTTON_VARIANTS.SECONDARY}
            size={BUTTONS_SIZES.MED}
            onClick={handleCloseAndCancel}
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.MED}
            onClick={handleCloseAndSave}
          >
            Save
          </Button>
        </ButtonWrap>
      </ActionButtonsSection>
    </Modal>
  )
}

export default withTheme(ShopOperationHoursModal)
ShopOperationHoursModal.defaultProps = {
  selectedDay: null
}
ShopOperationHoursModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setSelectedDay: PropTypes.func.isRequired,
  selectedDay: PropTypes.string,
  theme: PropTypes.object.isRequired
}
