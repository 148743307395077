import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { USER_AVATAR_SIZES } from '../../const/UIvariants'
import UserAvatar from '../UI/UserAvatar'
import { getImageFromServer } from '../../utils/helpers'
import { useDeleteChatMessageMutation } from '../../api/api.generated'
import EditDeleteMenu from '../EditDeleteMenu'
import {
  ChatBubbleContainer,
  MessageImage,
  MessageText,
  MessageTextWrap,
  MessageWrap,
  TimeStamp,
  UserAvatarWrap,
  TimeAndStatusWrap
} from './styles'

const ChatBubble = ({
  id,
  messageText,
  author,
  isOwnMessage,
  messageImage,
  timeStamp,
  onDelete
}) => {
  const history = useHistory()
  const [deleteChatMessage] = useDeleteChatMessageMutation()

  const handleDelete = (messageId) => {
    deleteChatMessage(messageId)
    .unwrap()
      .then((res) => {
        if (res.roomDeleted) {
          history.go(0)
        } else {
          onDelete(messageId)
        }
      })
      .catch((err) => {
        console.log(err)
      })      
  }  

  return (
    <ChatBubbleContainer
      justify={isOwnMessage ? 'flex-end' : 'flex-start'}
      {...{ isOwnMessage }}
    >
      <MessageWrap
        justify={isOwnMessage ? 'flex-end' : 'flex-start'}
        {...{ isOwnMessage }}
      >
        {!isOwnMessage && (
          <UserAvatarWrap>
            <UserAvatar size={USER_AVATAR_SIZES.SMALL} userName={author} />
          </UserAvatarWrap>
        )}
        <MessageTextWrap {...{ isOwnMessage }}>
          {messageText && (
            <MessageText variant="textXS" {...{ isOwnMessage }}>
              {messageText}
            </MessageText>
          )}

          {messageImage && (
            <MessageImage src={getImageFromServer(messageImage)} alt="asd" />
          )}

          <TimeAndStatusWrap>
            <TimeStamp variant="textXS" {...{ isOwnMessage }}>
              {dayjs(timeStamp).fromNow()}
            </TimeStamp>

            {isOwnMessage &&
            <EditDeleteMenu
              onDelete={() => {
                handleDelete(id)
              }}
              isRight={isOwnMessage}
            />              
            }    
          </TimeAndStatusWrap>
        </MessageTextWrap>
      </MessageWrap>
    </ChatBubbleContainer>
  )
}

export default ChatBubble
ChatBubble.defaultProps = {
  isOwnMessage: false,
  messageText: '',
  messageImage: null
}
ChatBubble.propTypes = {
  id: PropTypes.string.isRequired,
  messageText: PropTypes.string,
  messageImage: PropTypes.string,
  author: PropTypes.string.isRequired,
  isOwnMessage: PropTypes.bool,
  timeStamp: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired
}
