import { findIndex } from 'lodash/array'
import { api as apiSlice} from './api.generated'

const shopDonationsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getShopDonations: build.query({
      query: (shopId) => ({
        url: `/shop/${shopId}/donations`,
        method: 'get'
      }),
      providesTags: ['ShopDonations']
    }),
    getShopDonation: build.query({
      query: (donationId) => ({ url: `/shop/donations/${donationId}` }),
      providesTags: ['ShopDonation']
    }),    
    postShopDonations: build.mutation({
      query: ({ data }) => ({
        url: `/shop/donations`,
        method: 'post',
        data
      }),
      invalidatesTags: ['ShopDonations'],
      onQueryStarted: async ({ data }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            'getShopDonations',
            undefined,
            (draft) => {
              // eslint-disable-next-line no-param-reassign,no-unused-vars
              draft = data
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    putShopDonation: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/shop/donations/${donationId}`,
        method: 'put',
        data
      }),
      invalidatesTags: ['ShopDonations', 'ShopDonation'],
      onQueryStarted: async ({ data }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            'getShopDonations',
            undefined,
            (draft) => {
              // eslint-disable-next-line no-param-reassign,no-unused-vars
              draft = data
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    deleteShopDonation: build.mutation({
      query: (donationId) => ({
        url: `/shop/donations/${donationId}`,
        method: 'delete'
      }),
      invalidatesTags: ['ShopDonations'],
      async onQueryStarted(donationId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getShopDonations', undefined, (draft) => {
            const index = findIndex(draft.donations, (donation) => donation.id === donationId)
            draft.donations.splice(index, 1)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    })
  })
})

export const { 
  useGetShopDonationsQuery, 
  useGetShopDonationQuery, 
  useLazyGetShopDonationQuery, 
  usePostShopDonationsMutation, 
  usePutShopDonationMutation, 
  useDeleteShopDonationMutation 
} =
  shopDonationsApi
