import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import ImageUploading from 'react-images-uploading'
import { Formik } from 'formik'
import { withTheme } from 'styled-components'
import * as Yup from 'yup'
import { isNull } from 'lodash/lang'
import { useGetCategoriesQuery } from '../../../api/generalApi'
import {
  useLazyGetOrganizationsQuery,
  useLazyGetOrganizationProductQuery,
  usePostOrganizationProductMutation,
  usePutOrganizationProductMutation
} from '../../../api/api.generated'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import { getImageFromServer, getImageBase64, getErrorMessage  } from '../../../utils/helpers'
import { ErrorMessageWrap, InputError } from '../../../global/styles'
import Button from '../../../components/UI/Buttons/Button'
import TextInput from '../../../components/UI/Inputs/TextInput'
import SwitchInput from '../../../components/UI/Inputs/SwitchInput'
import SelectManyInput from '../../../components/UI/Inputs/SelectManyInput'
import Typography from '../../../components/UI/Typography'
import FormSelectOneInput from '../../../components/UI/Inputs/SelectOneInput/FormSelectOneInput'
import UploadImagePreview from '../../../components/UploadImagePreview'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import {
  FormWrap,
  StyledForm,
  FormInputsWrap,
  TitleWrap,
  OrganisationWrapper,
  LeftHalf,
  PriceWrap,
  ImageUploadWrap,
  ImageUploadContent,
  ImageControlButtonsWrap,
  RightHalf,
  ActionButtonsSection,
  ButtonWrap,
  SwitchWrap
} from './styles'

const emptyProduct = {
  image: '',
  name: '',
  price: '',
  shippingCost: '',
  isShippingEnabled: [],
  isCollectEnabled: [],
  categories: [],
  organization: ''
}

const AddOrganizationProductModal = ({ isOpen, onClose, editProductId, theme, organizationId, showOrganization }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [initialData, setInitialData] = useState(emptyProduct)
  const [error, setError] = useState('')
  const { data: categories } = useGetCategoriesQuery()
  const [getOrganizations, { data: organizationsData }] = useLazyGetOrganizationsQuery()
  const [getOrganizationProduct, { data: shopProduct }] = useLazyGetOrganizationProductQuery()
  const [postOrganizationProduct] = usePostOrganizationProductMutation()
  const [updateOrganizationProduct] = usePutOrganizationProductMutation()

  const action = (isNull(editProductId)) ? 'Add' : 'Edit';

  const organizationList = useMemo(
    () =>
      organizationsData
        ? organizationsData?.organizations?.map((organization) => ({value: organization.id, label: organization.name })) 
        : [],
    [organizationsData]
  )

  const displayError = (err) => {
    if (err instanceof Error) {
      setError(err.message)
    } else if (err instanceof Object) {
      let message = '';
      Object.keys(err).forEach((key) => {
        message += `${err[key]} `;
      });
      setError(message)
    } else {
      setError('Network error')
    }    
  }

  const handleSubmitForm = (values) => {
    const image = values?.image?.includes('data:image')
      ? values.image
      : undefined

    const isShippingEnabled = values.isShippingEnabled?.[0] === 'isShippingEnabled'
    const isCollectEnabled = values.isCollectEnabled?.[0] === 'isCollectEnabled'

    if (!isShippingEnabled && !isCollectEnabled) {
      setError('Shipping or Click & Collect must be enabled')
      return
    }

    setError('')
    if (editProductId) {
      updateOrganizationProduct({
        organizationProductId: editProductId,
        body: { 
          name: values.name,
          price: values.price,
          shippingCost: values.shippingCost,
          isShippingEnabled, 
          isCollectEnabled, 
          image,
          categories: values.categories,
          organization: values.organization
        }
      })
      .unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        displayError(err);
      })
    } else {
      postOrganizationProduct({ 
        body: { 
          name: values.name,
          price: values.price,
          shippingCost: values.shippingCost,
          isShippingEnabled, 
          isCollectEnabled, 
          image: values.image,
          categories: values.categories,
          organization: values.organization
        }
      
      })
      .unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        displayError(err);
      })      
    }
  }

  useEffect(() => {
    if (showOrganization) {
      getOrganizations()
      .unwrap()
      .then(() => {
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })      
    }
    if (!isNull(editProductId)) {
      getOrganizationProduct(editProductId)
    } else {
      setInitialData({
        image: '',
        name: '',
        price: '',
        shippingCost: '',
        isShippingEnabled: [],
        isCollectEnabled: [],
        categories: [],
        organization: organizationId
      })
      if (!showOrganization) {
        setIsSuccess(true)
      }
    }
  }, [])

  useEffect(() => {
    if (shopProduct && (organizationsData || !showOrganization)) {
      setInitialData({
        image: getImageFromServer(shopProduct.image),
        name: shopProduct.name,
        price: shopProduct.price,
        shippingCost: shopProduct.shippingCost,
        isShippingEnabled: shopProduct.isShippingEnabled ? ['isShippingEnabled'] : [],
        isCollectEnabled: shopProduct.isCollectEnabled ? ['isCollectEnabled'] : [],
        categories: shopProduct.tagIds,
        organization: shopProduct.organizationId
      })
      setIsSuccess(true)
    }
    if ((isNull(editProductId)) && (organizationsData)) {
      setIsSuccess(true)
    }
  }, [shopProduct, organizationsData])

  return (
    <Modal {...{ isOpen }}>
      {!(categories && isSuccess) &&
      <LoadingSpinner />
      }
      {categories && isSuccess && (
        <FormWrap>
          <Formik
            initialValues={initialData}
            validationSchema={Yup.object({
              image: Yup.string().required('Image is required'),
              name: Yup.string().max(50, 'Name is too long').required('Name is required'),
              price: Yup.number().required('Price is required'),
              shippingCost: Yup.number().required('Shipping cost is required'),
              categories: Yup.array()
                .min(1, 'At lease one category should be selected')
                .max(5, 'Only 5 categories per product allowed'),
              organization: Yup.string().required('Organization is required')
            })}
            onSubmit={(values) => {
              handleSubmitForm(values)
            }}
          >
            {({ values, setFieldValue, errors }) => (
              <StyledForm>
                <TitleWrap>
                  <Typography align="center" variant="headingM">{ action } Product</Typography>
                </TitleWrap>
                { showOrganization &&
                  <OrganisationWrapper>
                    <FormSelectOneInput
                      inputName="organization"
                      label="Organisation"
                      options={organizationList}
                      size="100%"
                      value={initialData.organization}
                      isGrey
                    />
                  </OrganisationWrapper>
                }
                <FormInputsWrap>
                  <LeftHalf>
                    <TextInput
                      size={INPUT_SIZES.SMALL}
                      inputName="name"
                      label="Name"
                      placeholder="Enter name"
                    />
                    <PriceWrap>
                      <TextInput
                        type="number"
                        size={INPUT_SIZES.SMALL}
                        inputName="price"
                        label="Price"
                        placeholder="$00.00"
                      />
                      <TextInput
                        type="number"
                        size={INPUT_SIZES.SMALL}
                        inputName="shippingCost"
                        label="Shipping Cost"
                        placeholder="$00.00"
                      />
                    </PriceWrap>
                    <SelectManyInput
                      options={categories.map((category) => ({
                        value: category.id,
                        label: category.tagName
                      }))}
                      label="Categories"
                      inputName="categories"
                    />
                  </LeftHalf>
                  <RightHalf>
                    <ImageUploading
                      value={values.image}
                      onChange={(image) => {
                        setFieldValue(
                          'image',
                          getImageBase64({ source: image })
                        )
                      }}
                      acceptType={['jpg', 'gif', 'png']}
                      maxFileSize={4000000}
                      dataURLKey="data_url"
                    >
                      {({ onImageUpload }) => (
                        <>
                          <ImageUploadWrap>
                            <ImageUploadContent>
                              <Typography
                                variant="headingS"
                                color={theme.colors.skyNeutral_2}
                              >
                                Image
                              </Typography>
                              <Typography
                                variant="textXS"
                                color={theme.colors.skyNeutral2}
                              >
                                File type (JPG, JPEG, or PNG) max. 4MB
                              </Typography>
                              <ImageControlButtonsWrap>
                                <Button
                                  type="button"
                                  variant={BUTTON_VARIANTS.OUTLINED}
                                  size={BUTTONS_SIZES.SMALL}
                                  onClick={onImageUpload}
                                >
                                  Upload
                                </Button>
                                <Button
                                  type="button"
                                  variant={BUTTON_VARIANTS.SECONDARY}
                                  size={BUTTONS_SIZES.SMALL}
                                  onClick={() => {
                                    setFieldValue('image', '')
                                  }}
                                >
                                  Remove
                                </Button>
                              </ImageControlButtonsWrap>
                              <UploadImagePreview
                                height={176}
                                uploadedImage={values.image}
                              />
                              <InputError>{errors.image ? 'Image is required!' : null}</InputError>
                            </ImageUploadContent>
                          </ImageUploadWrap>
                        </>
                      )}
                    </ImageUploading>
                    <SwitchWrap>
                      <SwitchInput inputName="isShippingEnabled" label="Shipping" value="isShippingEnabled" />
                    </SwitchWrap>
                    <SwitchWrap>
                      <SwitchInput inputName="isCollectEnabled" label="Click & Collect" value="isCollectEnabled" />
                    </SwitchWrap>
                  </RightHalf>
                </FormInputsWrap>
                {error && 
                  <ErrorMessageWrap>
                    <Typography variant="textS" color="red">
                    {error}
                    </Typography>
                  </ErrorMessageWrap>          
                }
                <ActionButtonsSection>
                  <ButtonWrap>
                    <Button
                      type="submit"
                      variant={BUTTON_VARIANTS.PRIMARY}
                      size={BUTTONS_SIZES.MED}
                    >
                      Save
                    </Button>
                    <Button
                      type="button"
                      variant={BUTTON_VARIANTS.SECONDARY}
                      size={BUTTONS_SIZES.MED}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                  </ButtonWrap>
                </ActionButtonsSection>
              </StyledForm>
            )}
          </Formik>
        </FormWrap>
      )}
    </Modal>
  )
}

export default withTheme(AddOrganizationProductModal)

AddOrganizationProductModal.defaultProps = {
  editProductId: null,
  organizationId: null
}

AddOrganizationProductModal.propTypes = {
  theme: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editProductId: PropTypes.string,
  organizationId: PropTypes.string,
  showOrganization: PropTypes.bool.isRequired
}
