import React from 'react'
import { Store, Unpublished } from '@styled-icons/material'

export const getMetrics = (data) => [
  {
    description: '',
    icon: <Store />,
    iconBgColor: 'green3',
    isActive: false,
    metricName: 'Stores Active',
    numberAndIconColor: 'greenBase',
    onClick: () => {},
    value: data?.activeShops ? `${data.activeShops}` : '0'
  },
  {
    description: 'In last 60D ',
    icon: <Unpublished />,
    iconBgColor: 'skyNeutral3',
    isActive: false,
    metricName: 'Stores Non-active',
    numberAndIconColor: 'skyNeutral_1',
    onClick: () => {},
    value: data?.inactiveShops ? `${data.inactiveShops}` : '0'
  }
]

export const tableColumns = [
  {
    Header: 'Store Name',
    accessor: 'shopName'
  },
  {
    Header: 'Location',
    accessor: 'location'
  },
  {
    Header: 'Organisation',
    accessor: 'organizationName'
  },
  {
    Header: 'Email',
    accessor: 'email'
  },
  {
    Header: 'Days Operational',
    accessor: 'daysOperational'
  },
  // this column is invisible, contains all data about shop
  // since cells has custom styles react-table doesnt see a data inside it on filterring
  {
    Header: 'forSearch',
    accessor: 'forSearch'
  },
  {
    Header: '',
    accessor: 'status'
  }
]
