import React, { useMemo, useState, useEffect } from 'react'
import { usePagination, useRowSelect, useTable } from 'react-table'
import { isEmpty } from 'lodash/lang'
import { keys } from 'lodash/object'
import {
  useLazyGetStoreReportsQuery,
  usePostDeleteStoreReportsMutation
} from '../../../api/api.generated'
import { getEntityPropertyByIds, getErrorMessage } from '../../../utils/helpers'
import Typography from '../../../components/UI/Typography'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import DashboardHeader from '../../../components/DashboardHeader'
import TableCheckboxCell from '../../../components/TableCheckboxCell'
import PaginatedEntityTable from '../../../components/EntityTable/PaginatedEntityTable'
import DetailsAsidePanel from '../../../components/DetailsAsidePanel'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import TableDeleteCell from '../../../components/TableDeleteCell'
import {
  DashboardInfoWrap,
  ManageEntityDashboardWrap,
  Toolbar
} from '../../../global/styles'
import StoreReportTableRow from '../StoreReportTableRow'
import StoreReportDetails from '../StoreReportDetails'

const tableColumns = [
  {
    Header: 'Store',
    accessor: 'storeName'
  },
  {
    Header: 'User',
    accessor: 'userName'
  },
  {
    Header: 'Reason',
    accessor: 'reasonName'
  },
  {
    Header: 'Description',
    accessor: 'description'
  }
]

const ManageStoreReports = () => {
  const [showDetails, setShowDetails] = useState(false)
  const [deleteOneId, setDeleteOneId] = useState(null)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [activeReportId, setActiveReportId] = useState(null)
  const [error, setError] = useState('')
  const [getReports, { data: reportsData, isLoading }] = useLazyGetStoreReportsQuery()
  const [deleteSelectedIds, { isLoading: deletingItems }] = usePostDeleteStoreReportsMutation()
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)

  const columns = useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
      reportsData
        ? reportsData?.reports?.map((report) => StoreReportTableRow(report))
        : [],
    [reportsData]
  )

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { selectedRowIds, pageIndex, pageSize },
    pageOptions,
    toggleAllPageRowsSelected,
    toggleAllRowsSelected,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.reportId,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages      
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
          Cell: TableCheckboxCell
        },
        ...columnsList,
        {
          id: 'deleteIcon',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableDeleteCell
              cell={cell}
              handleShowConfirmModal={setShowConfirmDelete}
              handleSetIdToDelete={setDeleteOneId}
            />
          )
        }
      ])
    }
  )

  const handleShowDetails = (reportId) => {
    if (reportId !== activeReportId) {
      setShowDetails(true)
      setActiveReportId(reportId)
    } else {
      setShowDetails(false)
      setActiveReportId(null)
    }
  }

  const handleCloseDetails = () => {
    setShowDetails(false)
    setActiveReportId(null)
  }

  const handleCloseConfirmationModal = () => {
    setError("")
    toggleAllPageRowsSelected(false)
    toggleAllRowsSelected(false)
    setShowConfirmDelete(false)
    setDeleteOneId(null)
  }

  const handleDeleteSelected = () => {
    setError("")
    handleCloseConfirmationModal()
    handleCloseDetails()
    if (deleteOneId) {
      setDeleteOneId(null)
      deleteSelectedIds({ body: { idsToDelete: [deleteOneId] } })
        .unwrap()
        .then(() => {
        })
        .catch((err) => {
          setError(getErrorMessage(err))
        })        
      return
    }
    if (!isEmpty(selectedRowIds)) {
      setDeleteOneId(null)
      deleteSelectedIds({
        body: {idsToDelete: keys(selectedRowIds) }
      })
        .unwrap()
        .then(() => {
        })
        .catch((err) => {
          setError(getErrorMessage(err))
        })      
    }
  }  

  const loadReports = () => {
    setError('')
    getReports({ page: pageIndex, limit: pageSize })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  useEffect(() => {
    loadReports();
  }, [])

  useEffect(() => {
    if (reportsData) {
      setTotalPages(reportsData.pagination.pages)
      setTotalRows(reportsData.pagination.total)
      if (pageIndex >= reportsData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [reportsData])

  useEffect(() => {
    if (reportsData) {
      loadReports()
    }
  }, [pageIndex, pageSize]);

  return (
    <ManageEntityDashboardWrap>
      <DashboardInfoWrap>
        <DashboardHeader
          headline="Store Reports"
          handleDelete={() => {
            setShowConfirmDelete(true)
          }}
          disableDelete={deletingItems || isEmpty(selectedRowIds)}
        />
        {isLoading && <LoadingSpinner />}
        {error && 
          <Toolbar>
            <Typography variant="textS" color="red">{error}</Typography>
          </Toolbar>
        } 
        {reportsData && (
          <PaginatedEntityTable
            {...{
              getTableProps,
              headerGroups,
              getTableBodyProps,
              page,
              prepareRow,
              pageOptions,
              pageIndex,
              gotoPage,
              totalRows
            }}
            handleShowDetails={handleShowDetails}
            activeItemId={activeReportId}
          />
        )}
      </DashboardInfoWrap>
      {showDetails &&
      <DetailsAsidePanel isOpen={showDetails} closePanel={handleCloseDetails}>
        <StoreReportDetails
          {...{ activeReportId }}
        />
      </DetailsAsidePanel>
      }

      {reportsData && (!isEmpty(selectedRowIds) || deleteOneId) && (
        <DeleteConfirmationModal
          isOpen={showConfirmDelete}
          onClose={handleCloseConfirmationModal}
          entityName={getEntityPropertyByIds(
            selectedRowIds,
            deleteOneId,
            reportsData?.reports,
            'storeName'
          )}
          entityType="Store Report"
          handleDelete={handleDeleteSelected}
        />
      )}
    </ManageEntityDashboardWrap>
  )
}

export default ManageStoreReports

ManageStoreReports.propTypes = {}
