import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { withTheme } from 'styled-components'
import { getErrorMessage } from '../../../utils/helpers'
import { ErrorMessageWrap } from '../../../global/styles'
import { BUTTON_VARIANTS, BUTTONS_SIZES, INPUT_SIZES } from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import TextInput from '../../../components/UI/Inputs/TextInput'
import {
  usePostShopVideoMutation
} from '../../../api/shopVideosApi'
import {
  InputsWrap,
  StyledForm,
  ActionButtonsSection,
  ButtonWrap,
  FormInputsWrap
} from './styles'

const emptyVideo = {
  url: ''
}

const ShopVideoModal = ({ isOpen, onClose, theme, shopId }) => {
  const [initialData, setInitialData] = useState(emptyVideo)
  const [error, setError] = useState('')
  const [postShopVideo] = usePostShopVideoMutation()

  useEffect(() => {
    setInitialData(emptyVideo);
  }, [])
  
  const handleSubmit = (values) => {
    setError('')
    postShopVideo({...values, shopId })
    .unwrap()
    .then(() => {
      onClose()
    })
    .catch((err) => {
      setError(getErrorMessage(err))
    })        
  }

  return (
    <Modal {...{ isOpen }}>
      <Formik
        initialValues={initialData}
        validationSchema={Yup.object({
          url: Yup.string().required('URL is required')
        })}        
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        <StyledForm>
          <InputsWrap>
            <Typography variant="headingM" color={theme.colors.skyNeutral_2}>Add Video</Typography>
          </InputsWrap>
          <FormInputsWrap>
            <TextInput
              size={INPUT_SIZES.SMALL}
              inputName="url"
              label="URL"
              placeholder="Enter URL"
            />
          </FormInputsWrap>
          {error && 
            <ErrorMessageWrap>
              <Typography variant="textS" color="red">
              {error}
              </Typography>
            </ErrorMessageWrap>          
            }            
          <ActionButtonsSection>
            <ButtonWrap>
              <Button
                  type="submit"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                >
                  Update
                </Button>
              </ButtonWrap>
              <Button
                type="button"
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTONS_SIZES.MED}
                onClick={onClose}
              >
                Cancel
              </Button>
          </ActionButtonsSection>
        </StyledForm>
      </Formik>
    </Modal>
  )
}

export default withTheme(ShopVideoModal)

ShopVideoModal.propTypes = {
  theme: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired
}
