import React, { useEffect, useState, useMemo } from 'react'
import Modal from 'react-modal'
import { Formik, Form } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { isNull } from 'lodash/lang'
import dayjs from 'dayjs'
import { withTheme } from 'styled-components'
import { 
  useGetOrganizationShopsQuery
} from '../../../api/api.generated'
import {
  useLazyGetShopHolidayQuery,
  usePostShopHolidayMutation,
  usePutShopHolidayMutation
} from '../../../api/shopHolidaysApi'
import { getErrorMessage } from '../../../utils/helpers'
import { ErrorWrap } from '../../../global/styles'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import TextInput from '../../../components/UI/Inputs/TextInput'
import DateInput from '../../../components/UI/Inputs/DateInput'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import FormSelectOneInput from '../../../components/UI/Inputs/SelectOneInput/FormSelectOneInput'
import ShowErrorModal from '../../../components/ShowErrorModal'
import {
  FormWrap,
  Headline,
  DateWrap,
  ButtonWrap
} from './styles'

const emptyHoliday = {
  shopId: '',
  name: '',
  dateFrom: '',
  dateTo: ''
}

const AddHolidayModal = ({ isOpen, onClose, editHolidayId, organizationId, shopId, showShops }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [initialData, setInitialData] = useState(emptyHoliday)
  const [error, setError] = useState('')
  const [getHoliday, { data: shopHoliday, error: holidayError }] = useLazyGetShopHolidayQuery()
  const [postHoliday] = usePostShopHolidayMutation()
  const [updateHoliday] = usePutShopHolidayMutation()

  const title = (isNull(editHolidayId)) ? 'Add Holiday' : 'Edit Holiday';

  let validationSchema
  let shopList = [];
  if (showShops) {
    validationSchema = Yup.object({
      shopId: Yup.string().required('Store is required'),
      name: Yup.string().max(50, 'Name is too long').required('Name is required'),
      dateFrom: Yup.date().required('From is required'),
      dateTo: Yup.date().required('To is required')      
    })

    const { data: shopsData } = useGetOrganizationShopsQuery(organizationId)
    shopList = useMemo(
      () =>
        shopsData
          ? shopsData?.shops?.map((shop) => ({value: shop.id, label: shop.shopName })) 
          : [],
      [shopsData]
    )
  } else {
    validationSchema = Yup.object({
      name: Yup.string().max(50, 'Name is too long').required('Name is required'),
      dateFrom: Yup.date().required('From is required'),
      dateTo: Yup.date().required('To is required')      
    })
  } 

  useEffect(() => {
    if (!isNull(editHolidayId)) {
      getHoliday(editHolidayId)
    } else {
      setInitialData(emptyHoliday)
      setIsSuccess(true)
    }
  }, [])
  
  useEffect(() => {
    if (shopHoliday) {
      setInitialData({
        shopId: shopHoliday.shopId,
        name: shopHoliday.name,
        dateFrom: shopHoliday.dateFrom ?? '',
        dateTo: shopHoliday.dateTo ?? ''
      })
      setIsSuccess(true)
    }
  }, [shopHoliday])

  const handleSubmitForm = (values) => {
    setError('')
    const data = values;
    if (!isNull(shopId)) {
      data.shopId = shopId
    }    
    data.name = values.name
    data.dateFrom = dayjs(values.dateFrom).format()
    data.dateTo = dayjs(values.dateTo).format()

    if (editHolidayId) {
      updateHoliday({
        holidayId: editHolidayId,
        data
      })
      .unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })
    } else {
      postHoliday(data)
      .unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })
    }
  }

  return (
    <Modal {...{ isOpen }}>
      {!isSuccess && !holidayError &&
        <LoadingSpinner />
      } 
      {holidayError && 
        <ShowErrorModal
          title={title}
          error="Network Error"
          onClose={onClose}
        />
      }         
      {isSuccess && (
        <FormWrap>
          <Formik
            initialValues={initialData}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmitForm(values)
            }}
          >
            {({ values }) => (
              <Form>
                <Headline>{ title }</Headline>
                {showShops &&
                  <FormSelectOneInput
                    inputName="shopId"
                    label="Store"
                    options={shopList}
                    size="100%"
                    value={initialData.shopId}
                    isGrey
                  />
                }
                <TextInput
                  size={INPUT_SIZES.SMALL}
                  inputName="name"
                  label="Name"
                  placeholder="Enter name"
                />
                <DateWrap>
                  <DateInput
                    label="From"
                    inputName="dateFrom"
                    placeholder="Date from"
                  />
                  <DateInput
                    label="To"
                    inputName="dateTo"
                    placeholder="Date to"
                    minValue={values.dateFrom}
                  />
                </DateWrap>
                {error && 
                  <ErrorWrap>
                    <Typography variant="textS" color="red">
                    {error}
                    </Typography>
                  </ErrorWrap>          
                }
                <ButtonWrap>
                  <Button
                    type="submit"
                    variant={BUTTON_VARIANTS.PRIMARY}
                    size={BUTTONS_SIZES.MED}
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    variant={BUTTON_VARIANTS.SECONDARY}
                    size={BUTTONS_SIZES.MED}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </ButtonWrap>
              </Form>
            )}
          </Formik>
        </FormWrap>
      )}
    </Modal>
  )
}

export default withTheme(AddHolidayModal)

AddHolidayModal.defaultProps = {
  editHolidayId: null,
  organizationId: null,
  shopId: null,
  showShops: false
}

AddHolidayModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editHolidayId: PropTypes.number,
  organizationId: PropTypes.string,
  shopId: PropTypes.string,
  showShops: PropTypes.bool
}
