import styled from 'styled-components'
import {
  DashboardInfoWrap,
  HStack,
  ManageEntityDashboardWrap
} from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const ShopsDashboardWrap = styled(ManageEntityDashboardWrap)``
export const ShopsWrap = styled(DashboardInfoWrap)`
  overflow: scroll;
`
export const StyledTypography = styled(Typography)`
  word-wrap: anywhere;
  color: ${({ theme }) => theme.colors.skyNeutral1};
`
export const ShopNameWrap = styled(HStack)`
  & > :first-child {
    margin-right: 20px;
  }
`
export const TableText = styled(Typography).attrs({
  variant: 'textS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
export const EntityDashboardHeadline = styled(HStack)`
  width: 100%;
  margin-bottom: 30px;
  background: none;
  justify-content: space-between;
  align-items: center;
`
export const SectionName = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_2};
`

