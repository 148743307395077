import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash/lang'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { withTheme } from 'styled-components'
import { Add, VideoLibrary } from '@styled-icons/material'
import { getErrorMessage } from '../../../utils/helpers'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import EditDeleteMenu from '../../../components/EditDeleteMenu'
import Typography from '../../../components/UI/Typography'
import Paginator from '../../../components/Paginator'
import ShopVideoModal from '../ShopVideoModal'
import { Footer, VStack, ErrorMessageTopWrap } from '../../../global/styles'
import {
  useLazyGetShopVideosQuery,
  useDeleteShopVideoMutation
} from '../../../api/shopVideosApi'
import {
  ShopVideo,
  ShopVideosWrap,
  TimeStampAndMenuWrap,
  AddShopVideos,
  LabelWrap,
  IconWrap
} from './styles'

const ITEMS_PER_PAGE = 50

// todo figure out is that necessary to extend dayjs in every component
dayjs.extend(relativeTime)

const ShopVideos = ({ theme, shopId }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [error, setError] = useState('')
  const [addNewModalIsOpen, setAddNewModalIsOpen] = useState(false)
  const [getVideos, { data: shopVideos, isLoading, error: loadError }] = useLazyGetShopVideosQuery()
  const [deleteShopVideo] = useDeleteShopVideoMutation()

  const handleOpenAddVideo = () => {
    setError("")
    setAddNewModalIsOpen(true)
  }

  const handleCloseAddVideo = () => {
    setAddNewModalIsOpen(false)
  }

  const handleDeleteVideo = (videoId) => {
    setError("")
    deleteShopVideo(videoId)
      .unwrap()
      .then(() => {
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })     
  }

  const loadVideos = () => {
    setError('')
    getVideos({ shopId, page: currentPage, limit: ITEMS_PER_PAGE })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  useEffect(() => {
    loadVideos()
  }, [])

  useEffect(() => {
    loadVideos()
  }, [currentPage])

  useEffect(() => {
    if (shopVideos) {
      setTotalPages(shopVideos.pagination.pages)
      setTotalRows(shopVideos.pagination.total)
      if (currentPage >= shopVideos.pagination.pages) {
        setCurrentPage(0)
      }
    }
  }, [shopVideos])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {error &&
        <ErrorMessageTopWrap>
          <Typography variant="textS" color="red">{error}</Typography>
        </ErrorMessageTopWrap>
      }  
      {addNewModalIsOpen &&
        <ShopVideoModal
          isOpen={addNewModalIsOpen}
          onClose={handleCloseAddVideo}
          shopId={shopId}
        />
      }
      {!loadError &&
      <AddShopVideos>
        <LabelWrap>
          <IconWrap>
            <VideoLibrary size={19} color={theme.colors.greenBase} />
          </IconWrap>
          <Typography variant="textS" color={theme.colors.greenCreator(7, 46)}>
            Add your videos here
          </Typography>
        </LabelWrap>
        <div>
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.SMALL}
            fillColor={theme.colors.green_1}
            type="button"
            onClick={handleOpenAddVideo}
          >
            <Add size={18} color={theme.colors.white} />
            Add Video
          </Button>
        </div>
      </AddShopVideos>
      }
      <ShopVideosWrap>
        {shopVideos &&
          (isEmpty(shopVideos) ? (
            <VStack
              alignItems="center"
              justify="center"
              style={{ padding: '32px' }}
            >
              <p> No videos added yet</p>
            </VStack>
          ) : (
            shopVideos.videos.map((video) => (
              <ShopVideo key={video.id}>
                <ReactPlayer controls width={320} height={180} url={video.url} />
                <TimeStampAndMenuWrap>
                  <Typography variant="textXS" color={theme.colors.white}>
                    {dayjs(video.createdAt).fromNow()}
                  </Typography>
                  <EditDeleteMenu
                    onDelete={() => {
                      handleDeleteVideo(video.id)
                    }}
                  />
                </TimeStampAndMenuWrap>
              </ShopVideo>
            ))
          ))
        }
      </ShopVideosWrap>
      {shopVideos && (
        <Footer>
          <Typography variant="textM" color={theme.colors.skyNeutral2}>
            {`${shopVideos.videos.length} from ${totalRows} Videos`}
          </Typography>
          <Paginator
            amountOfPages={totalPages}
            {...{ currentPage, setCurrentPage }}
          />
        </Footer>
      )}
    </>
  )
}

export default withTheme(ShopVideos)

ShopVideos.propTypes = {
  theme: PropTypes.object.isRequired,
  shopId: PropTypes.string.isRequired
}
