import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  useLazyGetOrganizationPostStoresQuery,
  useDeleteOrganizationPostsToShopsMutation  
} from '../../api/api.generated'
import { getErrorMessage } from '../../utils/helpers'
import ViewStoresModal from '../ViewStoresModal'

const ViewOrganizationPostStoresModal = ({ organizationId, postId, onClose }) => {
  const [ getOrganizationPostStores, { data: shopsTableData, isLoading } ] = useLazyGetOrganizationPostStoresQuery()
  const [deletePosts] = useDeleteOrganizationPostsToShopsMutation()
  const [error, setError] = useState('')

  const loadStores = (pageIndex, pageSize, search) => {
    setError('')
    getOrganizationPostStores({ postId, page: pageIndex, limit: pageSize, search })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  const deleteFromStores = (selectedRowIds) => {
    setError('')
    deletePosts({ body: {
      organizationId,
      organizationPostIds: [postId],
      shopIds: selectedRowIds
    } })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })      
  }

  return (
    <ViewStoresModal
      title="Post Stores"
      shopsTableData={shopsTableData}
      isLoading={isLoading}     
      error={error}
      onLoad={loadStores} 
      onDelete={deleteFromStores} 
      onClose={onClose} 
    />
  )
}

export default ViewOrganizationPostStoresModal

ViewOrganizationPostStoresModal.defaultProps = {}

ViewOrganizationPostStoresModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}
