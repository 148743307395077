import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import {
  QuestionWrap,
  QuestionDetailsWrap,
  QuestionTitleWrap
} from './styles'
import EditDeleteMenu from '../EditDeleteMenu/EditDeleteMenu'
import Typography from '../UI/Typography'

const ShopQuestionItem = ({
  theme,
  id,
  question,
  answer,
  canEdit,
  canDelete,
  handleEditQuestion,
  handleDeleteQuestion
}) => (
  <QuestionWrap>
    <QuestionDetailsWrap>
      <QuestionTitleWrap>
        <Typography variant="textXS" color={theme.colors.skyNeutral1}>
          {question}
        </Typography>
        {canEdit && handleEditQuestion && handleDeleteQuestion && (
          <EditDeleteMenu
            onEdit={() => {
              handleEditQuestion(id)
            }}
            onDelete={() => {
              handleDeleteQuestion(id)
            }}
          />
        )}
        {!canEdit && canDelete && handleDeleteQuestion && (
          <EditDeleteMenu
            onDelete={() => {
              handleDeleteQuestion(id)
            }}
          />
        )}    
      </QuestionTitleWrap>
      <Typography variant="textXS" color={theme.colors.skyNeutral_1}>
      {answer}
      </Typography>
    </QuestionDetailsWrap>
  </QuestionWrap>
)

export default withTheme(ShopQuestionItem)

ShopQuestionItem.defaultProps = {
  canEdit: true,
  canDelete: true, 
  handleEditQuestion: null,
  handleDeleteQuestion: null
}

ShopQuestionItem.propTypes = {
  theme: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  handleEditQuestion: PropTypes.func,
  handleDeleteQuestion: PropTypes.func,
}
