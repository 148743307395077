import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { IconWrap, MetricItemWrap, MetricLabel } from './styles'

import { HStack } from '../../global/styles'
import Typography from '../UI/Typography'

const MetricItem = ({
  theme,
  numberAndIconColor,
  iconBgColor,
  metricName,
  value,
  icon,
  description,
  isActive,
  onClick
}) => (
  <MetricItemWrap {...{ isActive, onClick }}>
    <MetricLabel variant="textM" color={theme.colors.skyNeutral1}>
      {metricName}
    </MetricLabel>
    <HStack justify="flex-start" alignItems="center">
      <IconWrap bgColor={theme.colors[iconBgColor]}>
        {React.cloneElement(icon, {
          size: 24,
          color: theme.colors[numberAndIconColor]
        })}
      </IconWrap>
      <Typography variant="headingXL" color={theme.colors[numberAndIconColor]}>
        {value}
      </Typography>
    </HStack>
    <Typography variant="textS" color={theme.colors.skyNeutral2}>
      {description}
    </Typography>
  </MetricItemWrap>
)

export default withTheme(MetricItem)

MetricItem.defaultProps = {
  onClick: null,
  description: '',
}

MetricItem.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.node.isRequired,
  iconBgColor: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  metricName: PropTypes.string.isRequired,
  numberAndIconColor: PropTypes.string.isRequired,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  theme: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired
}
