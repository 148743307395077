/*eslint-disable*/
import React from 'react'
import { TableDarkText, TableText } from '../../../global/styles'

const OrderTableRow = (orderItem) => ({
  id: orderItem.id,
  code: <TableDarkText>{orderItem.code}</TableDarkText>,
  date: <TableDarkText>{orderItem.createdAt}</TableDarkText>,
  firstName: <TableText>{orderItem.firstName}</TableText>,
  lastName: <TableText>{orderItem.lastName}</TableText>,
  phone: <TableText>{orderItem.phone === null ? '' : '+' }{orderItem.phone}</TableText>,
  address: <TableText>{orderItem.address}</TableText>,
  amount: <TableText>${orderItem.amount}</TableText>,
  fee: <TableText>${orderItem.fee}</TableText>
})

export default OrderTableRow

OrderTableRow.propTypes = {}
