import React from 'react'
import PropTypes from 'prop-types'
import { StyledLink, MenuItemLabel } from './styles'
import ROUTES from '../../../const/routes'
import { globalTheme } from '../../../global/theme'

const MainMenuItem = ({ icon, label, destination, isActive }) => (
  <StyledLink to={destination} $isActive={isActive}>
    {React.cloneElement(icon, {
      size: 18,
      color: isActive
        ? globalTheme.colors.white
        : globalTheme.colors.skyCreator(78, 89)
    })}
    <MenuItemLabel variant="textS" {...{ isActive }}>
      {label}
    </MenuItemLabel>
  </StyledLink>
)

export default MainMenuItem

MainMenuItem.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  destination: PropTypes.oneOf(Object.values(ROUTES)).isRequired,
  isActive: PropTypes.bool.isRequired
}
