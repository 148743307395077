const ROUTES = {
  LOGIN: '/auth/login',
  FORGOT_PASSWORD: '/auth/forgotPassword',
  CREATE_NEW_PASSWORD: '/reset-password',
  DASHBOARD: '/main/dashboard',
  STORE_ORDER_PRODUCTS: '/main/store-order/:orderId/products',
  TRANSFER_ORDERS: '/main/transfer/:transferId/orders',
  SETTINGS: '/main/settings',
  LOGOUT: '/main/logout',

  // shop
  INDEX: '/',
  CREATE_ACCOUNT: '/auth/createAccount',
  STORE_ORDER_HISTORY: '/main/orders',
  SHOP_TRANSFERS: '/main/transfers',
  SHOP_PROFILE: '/main/storeProfile',
  SHOP_PROFILE_PRODUCTS: '/main/storeProfile/products',
  SHOP_PROFILE_POSTS: '/main/storeProfile/posts',
  SHOP_PROFILE_DONATIONS: '/main/storeProfile/donations',
  SHOP_PROFILE_VOLUNTEERS: '/main/storeProfile/volunteers',
  SHOP_PROFILE_QUESTIONS: '/main/storeProfile/faq',
  SHOP_PROFILE_GALLERY: '/main/storeProfile/gallery',
  SHOP_PROFILE_VIDEOS: '/main/storeProfile/videos',
  SHOP_PROFILE_HOLIDAYS: '/main/storeProfile/holidays',
  MESSAGES: '/main/messages',
  CONTACTS: '/main/contacts',
  STRIPE_CONNECT: '/main/stripe/:shopId/connect',

  // admin
  ORGANIZATIONS: '/main/organizations',
  ORGANIZATION_PRODUCTS: '/main/organization/products',
  ORGANIZATION_POSTS: '/main/organization/posts',
  ORGANIZATION_QUESTIONS: '/main/organization/faq',
  ORGANIZATION_HOLIDAYS: '/main/organization/holidays',
  SHOPS: '/main/stores',
  SHOP: '/main/store/:shopId',
  SHOP_PRODUCTS: '/main/store/:shopId/products',
  SHOP_POSTS: '/main/store/:shopId/posts',
  SHOP_DONATIONS: '/main/store/:shopId/donations',
  SHOP_VOLUNTEERS: '/main/store/:shopId/volunteers',
  SHOP_QUESTIONS: '/main/store/:shopId/faq',
  SHOP_GALLERY: '/main/store/:shopId/gallery',
  SHOP_VIDEOS: '/main/store/:shopId/videos',
  SHOP_HOLIDAYS: '/main/store/:shopId/holidays',
  SHOP_DASHBOARD: '/main/store/:shopId/dashboard',
  PRODUCTS: '/main/products',
  POSTS: '/main/posts',
  QUESTIONS: '/main/faq',
  HOLIDAYS: '/main/holidays',
  MANAGERS: '/main/managers',
  CUSTOMERS: '/main/customers',
  DONATIONS: '/main/donations',
  TAGS: '/main/categories',
  ORDERS: '/main/orders',
  STORE_ORDERS: '/main/store-orders',
  BANK_TRANSFERS: '/main/transfers',
  TEMPLATES: '/main/templates',
  SUPPORT: '/main/support',
  SHOP_REPORTS: '/main/reports',
}
export default ROUTES
