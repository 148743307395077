import React from 'react'
import { BADGE_VARIANTS } from '../../../const/UIvariants'
import Badge from '../../../components/UI/Badge'
import { TableDarkText } from '../../../global/styles'

const OrganizationsTableRow = (organization) => ({
  name: <TableDarkText>{organization.name}</TableDarkText>,
  id: organization.id,
  forSearch: `${organization.name}`,
  status: (
    <Badge
      label={organization.isActive ? 'Active' : 'Inactive'}
      variant={organization.isActive ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
    />
  )
})

export default OrganizationsTableRow

OrganizationsTableRow.propTypes = {}
