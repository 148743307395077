import React, { useState } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { some } from 'lodash/collection'
import { keys, pickBy } from 'lodash/object'
import { capitalize } from 'lodash/string'
import { isNull } from 'lodash/lang'
import { withTheme } from 'styled-components'
import { VStack, HStack } from '../../global/styles'
import Button from '../UI/Buttons/Button'
import {
  BUTTONS_SIZES,
  BUTTON_VARIANTS
} from '../../const/UIvariants'
import {
  useGetShopTagQuery,
  usePutShopTagMutation,
} from '../../api/shopTagsApi'
import LoadingSpinner from '../LoadingSpinner'
import SwitchInput from '../UI/Inputs/SwitchInput'
import {
  DEFAULT_CLOSE_TIME,
  DEFAULT_OPEN_TIME,
  WEEK_DAYS
} from '../../const/app'
import EditWorkingHours from '../EditWorkingHours'
import { getErrorMessage } from '../../utils/helpers'
import ShopOperationHoursModal from '../../features/shop/ShopOperationHoursModal'
import Typography from '../UI/Typography'
import { globalTheme } from '../../global/theme'
import ShowErrorModal from '../ShowErrorModal'
import {
  ModalBody,
  Headline,
  ButtonWrap,
  HoursWrapper,
  OpenHoursWrap,
  OperationalHoursErrorWrap,
  WorkingDayWrap,
  ErrorWrap,
  SwitchInputWrapper
} from './styles'

const shopProperties = {
  shopOpenDays: 'openDays',
  // open hours
  mondayOpen: 'mondayOpen',
  mondayClose: 'mondayClose',
  tuesdayOpen: 'tuesdayOpen',
  tuesdayClose: 'tuesdayClose',
  wednesdayOpen: 'wednesdayOpen',
  wednesdayClose: 'wednesdayClose',
  thursdayOpen: 'thursdayOpen',
  thursdayClose: 'thursdayClose',
  fridayOpen: 'fridayOpen',
  fridayClose: 'fridayClose',
  saturdayOpen: 'saturdayOpen',
  saturdayClose: 'saturdayClose',
  sundayOpen: 'sundayOpen',
  sundayClose: 'sundayClose',
}
const EditShopTag = ({ tagId, tagLabel, isOpen, onClose }) => {
  const [selectedDay, setSelectedDay] = useState(null)
  const [error, setError] = useState('')
  const { data: tagData, isLoading, error: shopError } = useGetShopTagQuery(tagId)
  const [ putShopTag, { isLoading: isUpdating }] = usePutShopTagMutation()

  const customStyles = {
    content: {
      marginTop: '0',
    },
  };

  const submitStoreChanges = (values) => {
    const data = {
      schedule: WEEK_DAYS.reduce(
        (result, DAY) => ({
          ...result,
          [DAY]: {
            isOpen: some(values.openDays, (openDay) => openDay === DAY),
            open: values[`${DAY}Open`],
            close: values[`${DAY}Close`]
          }
        }),
        {}
      )
    }

    putShopTag({tagId, data})
    .unwrap()
    .then(() => {
      setError('');
      onClose()
    })
    .catch((err) => {
      setError(getErrorMessage(err))
    })    
  }

  return (
    <Modal {...{ isOpen }} style={customStyles}>
      <ModalBody>
        {isLoading && <LoadingSpinner />}
        {shopError && 
          <ShowErrorModal
            title={`Edit ${tagLabel} availability`}
            error="Network Error"
            onClose={onClose}
          />
        }  
        {tagData && (
          <Formik
            initialValues={{
              [shopProperties.shopOpenDays]: keys(pickBy(tagData.schedule, (day) => day.isOpen)),
              monday24h: tagData.schedule.monday.is24h ?? true,
              [shopProperties.mondayOpen]: tagData.schedule.monday.open ?? DEFAULT_OPEN_TIME,
              [shopProperties.mondayClose]: tagData.schedule.monday.close ?? DEFAULT_CLOSE_TIME,
              tuesday24h: tagData.schedule.tuesday.is24h ?? true,
              [shopProperties.tuesdayOpen]: tagData.schedule.tuesday.open ?? DEFAULT_OPEN_TIME,
              [shopProperties.tuesdayClose]: tagData.schedule.tuesday.close ?? DEFAULT_CLOSE_TIME,
              wednesday24h: tagData.schedule.wednesday.is24h ?? true,
              [shopProperties.wednesdayOpen]: tagData.schedule.wednesday.open ?? DEFAULT_OPEN_TIME,
              [shopProperties.wednesdayClose]: tagData.schedule.wednesday.close ?? DEFAULT_CLOSE_TIME,
              thursday24h: tagData.schedule.thursday.is24h ?? true,
              [shopProperties.thursdayOpen]: tagData.schedule.thursday.open ?? DEFAULT_OPEN_TIME,
              [shopProperties.thursdayClose]: tagData.schedule.thursday.close ?? DEFAULT_CLOSE_TIME,
              friday24h: tagData.schedule.friday.is24h ?? true,
              [shopProperties.fridayOpen]: tagData.schedule.friday.open ?? DEFAULT_OPEN_TIME,
              [shopProperties.fridayClose]: tagData.schedule.friday.close ?? DEFAULT_CLOSE_TIME,
              saturday24h: tagData.schedule.saturday.is24h ?? true,
              [shopProperties.saturdayOpen]: tagData.schedule.saturday.open ?? DEFAULT_OPEN_TIME,
              [shopProperties.saturdayClose]: tagData.schedule.saturday.close ?? DEFAULT_CLOSE_TIME,
              sunday24h: tagData.schedule.sunday.is24h ?? true,
              [shopProperties.sundayOpen]: tagData.schedule.sunday.open ?? DEFAULT_OPEN_TIME,
              [shopProperties.sundayClose]: tagData.schedule.sunday.close ?? DEFAULT_CLOSE_TIME
            }}
            validationSchema={Yup.object({[shopProperties.shopOpenDays]: Yup.array().min(1, 'Category should be open at least one day per week')})}
            onSubmit={submitStoreChanges}
          >
            {({ values, errors, isValid, setFieldValue }) => (
              <Form>
                <Headline>Edit {tagLabel} availability</Headline>
                <ShopOperationHoursModal
                  isOpen={!isNull(selectedDay)}
                  setSelectedDay={setSelectedDay}
                  selectedDay={selectedDay}
                />
                  <HoursWrapper>
                    <VStack>
                      <OpenHoursWrap>
                        {WEEK_DAYS.map((dayOfWeek) => (
                          <WorkingDayWrap key={dayOfWeek}>
                            <SwitchInputWrapper>
                              <SwitchInput
                                inputName={shopProperties.shopOpenDays}
                                value={dayOfWeek}
                                label={capitalize(dayOfWeek)}
                              />
                            </SwitchInputWrapper>
                            <EditWorkingHours
                              open={values[`${dayOfWeek}Open`]}
                              close={values[`${dayOfWeek}Close`]}
                              day={dayOfWeek}
                              isOpen={some(
                                values[shopProperties.shopOpenDays],
                                (dayShopIsOpened) => dayShopIsOpened === dayOfWeek
                              )}
                              is24h={values[`${dayOfWeek}24h`]}
                              setDay={setSelectedDay}
                            />
                            <HStack>
                              <Button
                                type="button"
                                variant={BUTTON_VARIANTS.PRIMARY}
                                size={BUTTONS_SIZES.SMALL}
                                onClick={() => setFieldValue(`${dayOfWeek}24h`, true)}
                              >
                                24h
                              </Button>
                            </HStack>
                          </WorkingDayWrap>
                      ))}
                      </OpenHoursWrap>

                      {errors[shopProperties.shopOpenDays] && (
                        <OperationalHoursErrorWrap>
                          <Typography
                            variant="textXS"
                            color={globalTheme.colors.skyNeutral1}
                          >
                            {errors[shopProperties.shopOpenDays]}
                          </Typography>
                        </OperationalHoursErrorWrap>
                      )}
                    </VStack>
                  </HoursWrapper>
                {!isValid && (
                  <p>
                    Please check form for errors in: {keys(errors).join(', ')}
                  </p>
                )}
                {error && 
                  <ErrorWrap>
                    <Typography variant="textS" color="red">
                    {error}
                    </Typography>
                  </ErrorWrap>          
                }                
                <ButtonWrap>
                  <Button
                    error={!isValid}
                    type="submit"
                    variant={BUTTON_VARIANTS.PRIMARY}
                    size={BUTTONS_SIZES.MED}
                    isLoading={isUpdating}
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    variant={BUTTON_VARIANTS.SECONDARY}
                    size={BUTTONS_SIZES.MED}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </ButtonWrap>
              </Form>
            )}
          </Formik>
        )}
      </ModalBody>
    </Modal>
  )
}

export default withTheme(EditShopTag)

EditShopTag.propTypes = {
  theme: PropTypes.object.isRequired, 
  tagId: PropTypes.number.isRequired,
  tagLabel: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

