import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../const/UIvariants'
import Button from '../UI/Buttons/Button'
import {
  ButtonWrap,
  ConfirmButton,
  Description,
  Headline,
  ModalHeader,
  ModalWrap,
  ErrorWrap
} from './styles'

const DeleteConfirmationModal = ({
  handleDelete,
  isOpen,
  onClose,
  entityType,
  entityName,
  headline,
  description,
  error,
  isLoading
}) => (
  <Modal {...{ isOpen }}>
    <ModalWrap>
      <ModalHeader>
        {entityName && entityType ? (
          <>
            <Headline bold>
              Are you Sure want delete this {entityType}{entityName.split(',').length > 1 ? 's' : ''}?
            </Headline>
            <Description>
              Remove {entityName} {entityType}{entityName.split(',').length > 1 ? 's' : ''}?
            </Description>
          </>
        ) : (
          <>
            <Headline bold>
              { headline }
            </Headline>
            <Description>
              { description }
            </Description>
          </>
        )}
        {error && 
          <ErrorWrap>
            {error}
          </ErrorWrap>          
        }            
      </ModalHeader>
      <ButtonWrap>
        <ConfirmButton size={BUTTONS_SIZES.MED} onClick={handleDelete} isLoading={isLoading}>
          Delete
        </ConfirmButton>
        <Button
          variant={BUTTON_VARIANTS.SECONDARY}
          size={BUTTONS_SIZES.MED}
          onClick={onClose}
        >
          Cancel
        </Button>
      </ButtonWrap>
    </ModalWrap>
  </Modal>
)

export default DeleteConfirmationModal

DeleteConfirmationModal.defaultProps = {
  entityType: "",
  entityName: "",
  headline: "",
  description: "",
  error: "",
  isLoading: false
}

DeleteConfirmationModal.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  entityType: PropTypes.string,
  entityName: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
  error: PropTypes.string,
  isLoading: PropTypes.bool
}
