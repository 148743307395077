import React, { useState } from 'react'
import { keys } from 'lodash/object'
import { capitalize } from 'lodash/string'
import PropTypes from 'prop-types'
import {
  BADGE_SIZES,
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES,
  USER_AVATAR_SIZES
} from '../../../const/UIvariants'
import { getHourAMPM, getErrorMessage } from '../../../utils/helpers'
import Typography from '../../../components/UI/Typography'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Button from '../../../components/UI/Buttons/Button'
import EntityPicture from '../../../components/EntityPicture'
import Badge from '../../../components/UI/Badge'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import {
  usePatchShopsByShopIdMutation,
  useGetShopQuery, 
  usePostDeleteShopsMutation
} from '../../../api/api.generated'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsPanelTopSection,
  ErrorMessageWrap
} from '../../../global/styles'
import {
  ButtonWrap,
  DayItem,
  DayLabel,
  ScheduleWrap,
  ShopDetailsWrap,
  ShopInfoBlock,
  TagsWrap
} from './styles'

const ShopDetails = ({ activeShopId, afterDeleteCleanUp }) => {
  const [error, setError] = useState('')
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const { data: shopData, isLoading } = useGetShopQuery(activeShopId)
  const [setShopStatus, { isLoading: changingStatus }] = usePatchShopsByShopIdMutation()
  const [deleteShop, { isLoading: deletingShop }] = usePostDeleteShopsMutation()

  const handleChangeShopStatus = () => {
    setError("")
    const newStatus = !shopData.isActive
    setShopStatus({ shopId: activeShopId, body: { isActive: newStatus } })
      .unwrap()
      .then(() => {
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })     
  }

  const handleDeleteShop = () => {
    setError("")
    setShowConfirmDelete(false)
    deleteShop({ body: { shopsIdsToDelete: [activeShopId] } })
      .unwrap()
      .then(() => {
        afterDeleteCleanUp()
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })     
  }
  
  const toggleConfirmationModal = () => {
    setShowConfirmDelete((prev) => !prev)
  }
  
  return (
    <>
      {isLoading && <LoadingSpinner />}
      {shopData && (
        <>
          <DetailsPanelTopSection>
            <EntityPicture
              size={USER_AVATAR_SIZES.LARGE}
              image={shopData.logotype}
              entityName={shopData.shopName}
            />
            <AsideDetailsTitle align="center">
              {shopData.shopName}
            </AsideDetailsTitle>
            <AsideDetailsLabel align="center">
              {shopData.displayAddress}
            </AsideDetailsLabel>
            <AsideDetailsLabel align="center">
              {shopData.organizationName}
            </AsideDetailsLabel>
            <AsideDetailsLabel align="center">
              {shopData.email}
            </AsideDetailsLabel>
          </DetailsPanelTopSection>
          <ShopDetailsWrap>
            <AsideDetailsTitle>Store Details</AsideDetailsTitle>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">
                Description about your store
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {shopData.description}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Store Categories</AsideDetailsLabel>
              <TagsWrap>
                {shopData.tags.map((category) => (
                  <Badge
                    key={category}
                    variant={BADGE_VARIANTS.LIGHT}
                    sie={BADGE_SIZES.SMALL}
                    label={category}
                  />
                ))}
              </TagsWrap>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">
                Hours Operational
              </AsideDetailsLabel>
              <ScheduleWrap>
                {keys(shopData.schedule).map((day) => (
                  <DayItem key={day}>
                    <DayLabel variant="textXS">{capitalize(day)}</DayLabel>
                    <Badge
                      variant={BADGE_VARIANTS.GREEN}
                      label={
                        shopData.schedule[day].isOpen
                          ? `${getHourAMPM(
                              shopData.schedule[day].open
                            ).toUpperCase()} - ${getHourAMPM(
                              shopData.schedule[day].close
                            ).toUpperCase()}`
                          : 'Closed'
                      }
                    />
                  </DayItem>
                ))}
              </ScheduleWrap>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Contact Details</AsideDetailsLabel>
              <AsideDetailsDescription>
                {shopData.website}
              </AsideDetailsDescription>
              <AsideDetailsDescription>
                {shopData.onlineShopUrl}
              </AsideDetailsDescription>
              <AsideDetailsDescription>
                {shopData.phone === null ? '' : '+' }{shopData.phone}
              </AsideDetailsDescription>
              <AsideDetailsDescription>
                {shopData.facebook}
              </AsideDetailsDescription>
              <AsideDetailsDescription>
                {shopData.instagram}
              </AsideDetailsDescription>
              <AsideDetailsDescription>
                {shopData.twitter}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            <ShopInfoBlock>
              {shopData.volunteerTitle &&
              <AsideDetailsDescription>
                {shopData.volunteerTitle}
              </AsideDetailsDescription>
              }
              {shopData.volunteerMessage &&
              <AsideDetailsDescription>
                {shopData.volunteerMessage}
              </AsideDetailsDescription>
              }
            </ShopInfoBlock>
            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTON_SIZES.MED}
                onClick={toggleConfirmationModal}
                isLoading={changingStatus || deletingShop}
              >
                Delete
              </Button>
              <Button
                variant={
                  shopData.isActive
                    ? BUTTON_VARIANTS.OUTLINED
                    : BUTTON_VARIANTS.PRIMARY
                }
                size={BUTTON_SIZES.MED}
                onClick={handleChangeShopStatus}
                isLoading={changingStatus || deletingShop}
              >
                {shopData.isActive ? 'Deactivate' : 'Activate'}
              </Button>
            </ButtonWrap>
            {error &&
              <ErrorMessageWrap>
                <Typography variant="textS" color="red">{error}</Typography>
              </ErrorMessageWrap>
            }             
          </ShopDetailsWrap>
          <DeleteConfirmationModal
            isOpen={showConfirmDelete}
            onClose={toggleConfirmationModal}
            entityName={shopData.shopName}
            entityType="Store"
            handleDelete={handleDeleteShop}
          />
        </>
      )}
    </>
  )
}

export default ShopDetails
ShopDetails.defaultProps = {
  activeShopId: null
}
ShopDetails.propTypes = {
  activeShopId: PropTypes.string,
  afterDeleteCleanUp: PropTypes.func.isRequired
}
