import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'
import { ShopLogoImage } from '../../../components/ShopLogo/styles'

// eslint-disable-next-line import/prefer-default-export
export const Headline = styled(Typography).attrs({
  variant: 'headingM',
  bold: true
})`
  margin-bottom: 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
export const ModalBody = styled(VStack)`
  align-items: stretch;
  width: 90vw;
  justify-content: flex-start;
  padding: 32px;
`
export const InputsWrapper = styled(VStack)`
  height: calc(100vh - 250px);
  overflow-y: auto;
  padding-left: 15px;
  padding-right: 15px;
`
export const ShopDetailsHeadlineWrap = styled(VStack)`
  margin-bottom: 32px;
  margin-top: 0;
`
export const ShopDetailsDescription = styled(Typography).attrs(({ theme }) => ({
  variant: 'textXS',
  color: theme.colors.skyNeutral2
}))`
`
export const HoursWrapper = styled(VStack)`
  height: 280px;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
`
export const OpenHoursWrap = styled(VStack)`
  height: 204px;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
`
export const WorkingDayWrap = styled(HStack)`
  margin-bottom: 20px;
  max-width: 50%;
  flex-wrap: wrap;
  align-items: center;
  min-width: 46%;
  justify-content: space-between;
  &:nth-child(-n + 4) {
    padding-right: 24px;
  }
  &:nth-child(n + 5) {
    padding-left: 24px;
  }
`
export const OperationalHoursErrorWrap = styled.div`
  padding-bottom: 20px;
`
export const TextInputWrapper = styled.div`
  padding-top: 12px;
  width: 100%;
`
export const SwitchInputWrapper = styled.div`
  margin-left: 20px;
`
export const ErrorWrap = styled(HStack)`
  padding: 0;
  max-width: 50vw;
  align-items: stretch;
`
export const ImageButtonWrap = styled(HStack)`
  width: 100%;
  margin-top: 0;
  justify-content: space-between;
  & > :first-child {
    margin-right: 12px;
  }
`
export const ButtonWrap = styled(HStack)`
  width: 100%;
  margin-top: 24px;
  justify-content: space-between;
  & > :first-child {
    margin-right: 12px;
  }
`
export const DividerLine = styled.hr`
  border: none;
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.skyNeutral4};
`
export const ShopDetailsHeadline = styled(Typography).attrs(({ theme }) => ({
  variant: 'textL',
  color: theme.colors.skyNeutral_2,
  bold: true
}))`
  margin-bottom: 4px;
`
export const SwitchWrap = styled(HStack)`
  margin-bottom: 20px;
`
export const ShopDetailsHead = styled.div`
  width: 100%;
  max-height: 104px;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export const ShopNameAndLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export const EmptyShopLogo = styled(ShopLogoImage).attrs({ as: 'div' })`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ShopNameAndAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 24px;
`
export const ImageRestrictions = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral2};
  margin-top: 8px;
  font-weight: 400;
`
