import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGlobalFilter, usePagination, useRowSelect, useTable } from 'react-table'
import { useSelector } from 'react-redux'
import { Search, LocalMall, LocalOffer } from '@styled-icons/material'
import { isEmpty } from 'lodash/lang'
import { keys } from 'lodash/object'
import {
  selectOrganizationId
} from '../../../app/selectors'
import {
  useLazyGetOrganizationsQuery
} from '../../../api/api.generated'
import {
  useLazyGetOrganizationShopProductsQuery,
  usePostDeleteProductsMutation
} from '../../../api/shopProductsApi'
import TableCheckboxCell from '../../../components/TableCheckboxCell/TableCheckboxCell'
import TableButtonCell from '../../../components/TableButtonCell'
import {
  DashboardInfoWrap,
  ManageEntityDashboardWrap,
  Toolbar,
  ErrorMessageTopWrap,
  ButtonWrap
} from '../../../global/styles'
import { BUTTON_VARIANTS, BUTTONS_SIZES, INPUT_SIZES } from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import DashboardHeader from '../../../components/DashboardHeader'
import SelectOneInput from '../../../components/UI/Inputs/SelectOneInput'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import { MetricsItem } from '../../customers/ManageCustomers/styles'
import MetricItem from '../../../components/MetricItem/MetricItem'
import PaginatedEntityTable from '../../../components/EntityTable/PaginatedEntityTable'
import DetailsAsidePanel from '../../../components/DetailsAsidePanel'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import CustomTextInput from '../../../components/UI/Inputs/TextInput/CustomTextInput'
import { getEntityPropertyByIds, getErrorMessage } from '../../../utils/helpers'
import ProductDetails from '../ProductDetails'
import ProductTableRow from '../ProductsTableRow'
import AddNewProductModal from '../../shop/AddNewProductModal'

const tableColumns = [
  {
    Header: 'Product Name',
    accessor: 'productName'
  },
  {
    Header: 'Store',
    accessor: 'shop'
  },
  {
    Header: 'Price',
    accessor: 'price'
  },
  {
    Header: 'Shipping Cost',
    accessor: 'shippingCost'
  },
  {
    Header: 'Shipping',
    accessor: 'isShippingEnabled'
  },
  {
    Header: 'Click & Collect',
    accessor: 'isCollectEnabled'
  },
  {
    Header: 'Quantity',
    accessor: 'quantity'
  },
  {
    Header: 'Product Categories',
    accessor: 'productTags'
  },
  {
    Header: 'Organisation',
    accessor: 'isOrganizationProduct'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'forSearch',
    accessor: 'forSearch'
  }  
]

const ManageProducts = () => {
  const organizationId = useSelector(selectOrganizationId)
  const [metrics, setMetrics] = useState([])
  const [activeOrganizationId, setActiveOrganizationId] = useState(null)
  const [activeProductId, setActiveProductId] = useState(null)
  const [deleteOneId, setDeleteOneId] = useState(null)
  const [error, setError] = useState('')
  const [deleteError, setDeleteError] = useState('')
  const [showDetails, setShowDetails] = useState(false)
  const [showAddNewModal, setShowAddNewModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [ getOrganizations, { data: organizationsData } ] = useLazyGetOrganizationsQuery()
  const [getProducts, { data: productsData, isLoading }] = useLazyGetOrganizationShopProductsQuery()
  const [deleteSelectedIds, { isLoading: deletingItems }] = usePostDeleteProductsMutation()
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState('');

  const getMetrics = useCallback(
    (data) => [
      {
        description: '',
        icon: <LocalMall />,
        iconBgColor: 'green3',
        isActive: false,
        metricName: 'Products Posted',
        numberAndIconColor: 'greenBase',
        onClick: () => {},
        value: data?.productsPosted
      },
      {
        description: '',
        icon: <LocalOffer />,
        iconBgColor: 'skyNeutral3',
        isActive: false,
        metricName: 'Product Categories',
        numberAndIconColor: 'skyNeutral_1',
        onClick: () => {},
        value: data?.productsTags
      },
    ],
    []
  )

  const columns = useMemo(() => tableColumns, [])
  
  const data = useMemo(
    () =>
      productsData
        ? productsData?.products?.map((product) => ProductTableRow(product))
        : [],
    [productsData]
  )

  const organizationList = useMemo(
    () =>
      organizationsData
        ? organizationsData?.organizations?.map((organization) => ({value: organization.id, label: organization.name })) 
        : [],
    [organizationsData]
  )

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { selectedRowIds, pageIndex, pageSize },
    pageOptions,
    toggleAllPageRowsSelected,
    toggleAllRowsSelected,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.productId,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
          Cell: TableCheckboxCell
        },
        ...columnsList,
        {
          id: 'deleteIcon',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableButtonCell
              cell={cell}
              handleSetIdToDelete={setDeleteOneId}
              handleShowEditModal={setShowEditModal}
              handleShowConfirmModal={setShowConfirmDelete}
              canEdit
            />
          )
        }
      ])
    }
  )

  const loadProducts = (orgId) => {
    setError('')
    getProducts({ organizationId: orgId, page: pageIndex, limit: pageSize, search })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  const handleChangeSelectedOrganization = (value) => {
    setActiveProductId(null)
    setShowDetails(false)
    setActiveOrganizationId(value)
    if (pageIndex === 0) {
      loadProducts(value)
    } else {
      gotoPage(0)
    }
  }

  const handleShowProductDetails = (productId) => {
    if (productId !== activeProductId) {
      setShowDetails(true)
      setActiveProductId(productId)
    } else {
      setShowDetails(false)
      setActiveProductId(null)
    }
  }

  const handleCloseDetails = () => {
    setShowDetails(false)
    setActiveProductId(null)
  }

  const handleShowAddNewModal = () => {
    setError("");
    setShowAddNewModal(true)
  }

  const handleCloseAddNewModal = () => {
    setShowAddNewModal(false)
  }

  const handleCloseEditModal = () => {
    setError("");
    setShowEditModal(false)
    setDeleteOneId(null)
  }

  const handleCloseConfirmationModal = () => {
    toggleAllPageRowsSelected(false)
    toggleAllRowsSelected(false)
    setShowConfirmDelete(false)
    setDeleteOneId(null)
  }

  const deleteSelectedProducts = (ids) => {
    deleteSelectedIds({ body: { productsIdsToDelete: ids } })
      .unwrap()
      .then(() => {
        handleCloseDetails()
        setDeleteOneId(null)
        setShowConfirmDelete(false)
      })
      .catch((err) => {
        setDeleteError(getErrorMessage(err))
      })    
  }

  const handleDeleteSelected = () => {
    setError("");
    setDeleteError("");
    if (deleteOneId) {
      deleteSelectedProducts([deleteOneId])
      return
    }
    if (!isEmpty(selectedRowIds)) {
      deleteSelectedProducts(keys(selectedRowIds))
    }
  }

  const handleSearch = () => {
    if (pageIndex === 0) {
      loadProducts(activeOrganizationId)
    } else {
      gotoPage(0)
    }
  }
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    if (organizationId) {
      setActiveOrganizationId(organizationId);
      loadProducts(organizationId)
    } else {
      getOrganizations()
        .unwrap()
        .then(() => {
        })
        .catch((err) => {
          setError(getErrorMessage(err))
        })      
    }
  }, [])

  useEffect(() => {
    if (productsData) {
      setTotalPages(productsData.pagination.pages)
      setTotalRows(productsData.pagination.total)
      if (pageIndex >= productsData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [productsData])

  useEffect(() => {
    if (organizationsData && organizationsData.organizations.length > 0) {
      setActiveOrganizationId(organizationsData.organizations[0].id);
      loadProducts(organizationsData.organizations[0].id)
    }
  }, [organizationsData])

  useEffect(() => {
    if (productsData) {
      loadProducts(activeOrganizationId)
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (productsData) {
      setMetrics(getMetrics(productsData.dashboardInfo))
    }
  }, [productsData])

  return (
    <ManageEntityDashboardWrap>
      <DashboardInfoWrap>
        <DashboardHeader
          headline="Manage Products"
          handleDelete={() => {
            setShowConfirmDelete(true)
          }}
          disableDelete={deletingItems || isEmpty(selectedRowIds)}
          addNewLabel="Add Product"
          showAddButton={organizationsData && organizationsData.organizations.length > 0}
          handleAddNew={handleShowAddNewModal}
          showSelectPageButton={productsData && productsData.products.length > 0}
          handleSelectPage={() => toggleAllPageRowsSelected(true)}
          handleSelectAll={() => toggleAllRowsSelected(true)}
          handleDeselectAll={() => toggleAllRowsSelected(false)}
          showSelectButton={productsData && productsData.products.length > 0}
        />
        {isLoading && <LoadingSpinner />}
        {error && 
          <ErrorMessageTopWrap>
            <Typography variant="textS" color="red">{error}</Typography>
          </ErrorMessageTopWrap>
        }       
        {productsData && (
          <>
            <MetricsItem>
              {metrics.map((metric) => (
                <MetricItem
                  key={metric.metricName}
                  description={metric.description}
                  icon={metric.icon}
                  iconBgColor={metric.iconBgColor}
                  id={metric.metricName}
                  isActive={metric.isActive}
                  metricName={metric.metricName}
                  numberAndIconColor={metric.numberAndIconColor}
                  onClick={metric.onClick}
                  value={metric.value}
                />
              ))}
            </MetricsItem>
            <Toolbar>
              {!organizationId && organizationList && (
                <SelectOneInput
                  options={organizationList}
                  setSelected={handleChangeSelectedOrganization}
                  selected={activeOrganizationId}
                />
              )}                      
              <CustomTextInput
                handleKeyPress={handleKeyPress}
                handleChange={(e) => {
                  setSearch(e.target.value)
                  // setGlobalFilter(e.target.value)
                }}
                placeholder="Search products by name"
                inputName="search"
                size={INPUT_SIZES.SMALL}
                value={search}
                leftIcon={<Search />}
                isWhite
              />
              <ButtonWrap>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </ButtonWrap>                
            </Toolbar> 
            <PaginatedEntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                rows: data,
                pageOptions,
                pageIndex,
                gotoPage,
                totalRows
              }}
              handleShowDetails={handleShowProductDetails}
              activeItemId={activeProductId}
            />
          </>
        )}
      </DashboardInfoWrap>
      { showDetails &&
      <DetailsAsidePanel isOpen={showDetails} closePanel={handleCloseDetails}>
        <ProductDetails
          {...{ activeProductId }}
          closePanel={handleCloseDetails}
        />
      </DetailsAsidePanel>
      }
      {showConfirmDelete && (
        <DeleteConfirmationModal
          isOpen={showConfirmDelete}
          onClose={handleCloseConfirmationModal}
          entityName={getEntityPropertyByIds(
            selectedRowIds,
            deleteOneId,
            productsData?.products,
            'productName'
          )}
          entityType="Product"
          handleDelete={handleDeleteSelected}
          error={deleteError}
        />
      )}
      {showAddNewModal &&
      <AddNewProductModal
        isOpen={showAddNewModal}
        onClose={handleCloseAddNewModal}
        showShops
        organizationId={activeOrganizationId}
        showOrganization={organizationId == null}
      />
      }
      {showEditModal && 
      <AddNewProductModal
        isOpen={showEditModal}
        onClose={handleCloseEditModal}
        showShops={false}
        editProductId={deleteOneId}
        showOrganization={false}
      />
      }      
    </ManageEntityDashboardWrap>
  )
}

export default ManageProducts

ManageProducts.propTypes = {}
