import React from 'react'
import { find } from 'lodash/collection'
import PropTypes from 'prop-types'
import { EmailNameWrap, TemplateDetailsWrap } from './styles'
import { useGetEmailTemplatesQuery } from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../../const/UIvariants'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsInfoBlock
} from '../../../global/styles'
import Button from '../../../components/UI/Buttons/Button/Button'

const EmailDetails = ({ activeEmailTemplateId, toggleEditing }) => {
  const { templateData, isLoading } = useGetEmailTemplatesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      templateData: find(
        data?.templates,
        (template) => template.id === activeEmailTemplateId
      )
    })
  })

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {templateData && (
        <>
          <EmailNameWrap>
            <AsideDetailsTitle align="left">
              {templateData.emailName}
            </AsideDetailsTitle>
          </EmailNameWrap>
          <TemplateDetailsWrap>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Subject</AsideDetailsLabel>
              <AsideDetailsDescription>
                {templateData.subject}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Intended for</AsideDetailsLabel>
              <AsideDetailsDescription>
                {templateData.intended}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Description</AsideDetailsLabel>
              <AsideDetailsDescription>
                {templateData.message}
              </AsideDetailsDescription>
            </DetailsInfoBlock>

            <DetailsInfoBlock>
              <Button
                variant={BUTTON_VARIANTS.OUTLINED}
                size={BUTTON_SIZES.MED}
                onClick={toggleEditing}
              >
                Edit Template
              </Button>
            </DetailsInfoBlock>
          </TemplateDetailsWrap>
        </>
      )}
    </>
  )
}

export default EmailDetails

EmailDetails.defaultProps = {
  activeEmailTemplateId: null
}

EmailDetails.propTypes = {
  activeEmailTemplateId: PropTypes.any,
  toggleEditing: PropTypes.func.isRequired
}
