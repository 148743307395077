import React from 'react'
import { useParams } from 'react-router-dom'
import ShopProducts from '../../shop/ShopProducts'

const ManageShopProducts = () => {
  const { shopId } = useParams()

  return (
    <ShopProducts shopId={shopId}/>
  )
}

export default ManageShopProducts

ManageShopProducts.propTypes = {}
