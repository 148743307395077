import styled from 'styled-components'
import Typography from '../UI/Typography'
import { HStack } from '../../global/styles'

export const OpenHours = styled(Typography)`
  margin-right: 12px;
  :hover {
    text-decoration: underline;
  }
`
export const HoursWrap = styled(HStack)`
  cursor: pointer;
`
