import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import {
  useGetSettingsQuery
} from '../../../api/api.generated'
import { getHourAMPM } from '../../../utils/helpers'
import DashboardHeader from '../../../components/DashboardHeader'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import Typography from '../../../components/UI/Typography'
import EditSettingsModal from '../editSettingsModal'
import {
  DashboardInfoWrap,
  ManageEntityDashboardWrap,
} from '../../../global/styles'
import {
  SettingsSection,
  SettingSection
} from './styles'

const Settings = ({ theme }) => {
  const DAY_OPTIONS = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ]
  
  const [showEditModal, setShowEditModal] = useState(false)
  const { data: settingsData, isLoading } = useGetSettingsQuery()

  const handleToggleEditModal = () => {
    setShowEditModal((p) => !p)
  }

  const createTimeStr = () => {
    const d = new Date(`2021-07-15T${settingsData.transferTime}.0000`);
    return getHourAMPM(d).toUpperCase();
  }

  return (
    <ManageEntityDashboardWrap>
      <DashboardInfoWrap>
        <DashboardHeader
          headline="Settings"
          disableDelete
          addNewLabel="Edit Settings"
          handleAddNew={handleToggleEditModal}
        />

        {isLoading && <LoadingSpinner />}
        {settingsData &&
        <SettingsSection>
          <SettingSection>
            <Typography variant="textL" color={theme.colors.skyNeutral_2}>Stripe</Typography>
            <Typography variant="textS" color={theme.colors.skyNeutral_2}>{settingsData.stripeSecretKey}</Typography>
          </SettingSection>
          <SettingSection>
            <Typography variant="textL" color={theme.colors.skyNeutral_2}>Payment Day</Typography>
            <Typography variant="textS" color={theme.colors.skyNeutral_2}>{DAY_OPTIONS[settingsData.transferDay - 1]}</Typography>
          </SettingSection>
          <SettingSection>
            <Typography variant="textL" color={theme.colors.skyNeutral_2}>Payment Time</Typography>
            <Typography variant="textS" color={theme.colors.skyNeutral_2}>{createTimeStr(settingsData.transferTime)}</Typography>
          </SettingSection>
          <SettingSection>
            <Typography variant="textL" color={theme.colors.skyNeutral_2}>Location Override</Typography>
            <Typography variant="textS" color={theme.colors.skyNeutral_2}>{settingsData.locationOverride ? 'Yes' : 'No'}</Typography>
          </SettingSection>
          <SettingSection>
            <Typography variant="textL" color={theme.colors.skyNeutral_2}>Location Override Visible</Typography>
            <Typography variant="textS" color={theme.colors.skyNeutral_2}>{settingsData.isLocationOverrideVisible ? 'Yes' : 'No'}</Typography>
          </SettingSection>
        </SettingsSection>
        }
      </DashboardInfoWrap>

      {settingsData &&
      <EditSettingsModal
        isOpen={showEditModal}
        {...{
          handleClose: handleToggleEditModal,
          stripeSecretKey: settingsData.stripeSecretKey,
          transferDay: settingsData.transferDay,
          transferTime: new Date(`2021-07-15T${settingsData.transferTime}.0000`),
          locationOverride: settingsData.locationOverride,
          isLocationOverrideVisible: settingsData.isLocationOverrideVisible
        }}
      />
      }
    </ManageEntityDashboardWrap>
  )
}

export default withTheme(Settings)

Settings.propTypes = {
  theme: PropTypes.object.isRequired
}
