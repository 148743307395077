import React from 'react'
import { useParams } from 'react-router-dom'
import ShopGallery from '../../shop/ShopGallery'

const ManageShopGallery = () => {
  const { shopId } = useParams()

  return (
    <ShopGallery shopId={shopId}/>
  )
}

export default ManageShopGallery

ManageShopGallery.propTypes = {}
