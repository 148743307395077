import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  useLazyGetOrganizationHolidayStoresQuery,
  useDeleteOrganizationHolidaysToShopsMutation  
} from '../../api/api.generated'
import { getErrorMessage } from '../../utils/helpers'
import ViewStoresModal from '../ViewStoresModal'

const ViewOrganizationHolidayStoresModal = ({ organizationId, holidayId, onClose }) => {
  const [ getOrganizatioHolidayStores, { data: shopsTableData, isLoading } ] = useLazyGetOrganizationHolidayStoresQuery()
  const [deleteHolidays] = useDeleteOrganizationHolidaysToShopsMutation()
  const [error, setError] = useState('')

  const loadStores = (pageIndex, pageSize, search) => {
    setError('')
    getOrganizatioHolidayStores({ holidayId, page: pageIndex, limit: pageSize, search })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  const deleteFromStores = (selectedRowIds) => {
    setError('')
    deleteHolidays({ body: {
      organizationId,
      organizationHolidayIds: [holidayId],
      shopIds: selectedRowIds
    } })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })      
  }

  return (
    <ViewStoresModal
      title="Holiday Stores"
      shopsTableData={shopsTableData}
      isLoading={isLoading}     
      error={error}
      onLoad={loadStores} 
      onDelete={deleteFromStores} 
      onClose={onClose} 
    />
  )
}

export default ViewOrganizationHolidayStoresModal

ViewOrganizationHolidayStoresModal.defaultProps = {}

ViewOrganizationHolidayStoresModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  holidayId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
}
