import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  useTable,
  useRowSelect,
  usePagination,
  useGlobalFilter
} from 'react-table'
import { MetricsItem, ShopsDashboardWrap, ShopsWrap, IsEmptyText } from './styles'
import { useGetShopOrderProductsQuery } from '../../../api/orderProductsApi'
import MetricItem from '../../../components/MetricItem'
import DashboardHeader from '../../../components/DashboardHeader'
import LoadingSpinner from '../../../components/LoadingSpinner'
import OrderProducsTableRow from '../OrderProducsTableRow'
import EntityTable from '../../../components/EntityTable'
import { getMetrics, tableColumns } from '../helpers/shopsHelpers'

const ManageOrderProducts = () => {
  const { orderId } = useParams()
  const { data: tableData, isLoading, error } = useGetShopOrderProductsQuery( { orderId } ) 
  const [metrcis, setMetrics] = useState([])

  const handleShowShopDetails = () => {
  }  

  const columns = React.useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
      tableData
        ? tableData?.products?.map((product) => OrderProducsTableRow(product))
        : [],
    [tableData]
  )

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    page,
    headerGroups,
    prepareRow,
    state: { pageIndex },
    pageOptions,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'first'
        },
        ...columnsList,
        {
          last: 'last'
        },
      ])
    }
  )

  useEffect(() => {
    if (tableData) {
      setMetrics(getMetrics(tableData?.order))
    }
  }, [tableData])
  
  return (
    <ShopsDashboardWrap>
      <ShopsWrap>
        <DashboardHeader
          headline="Order Products"
          disableDelete
        />
        {isLoading && <LoadingSpinner />}

        {error &&
          <IsEmptyText variant="textM">Network Error!</IsEmptyText>
        } 

        {tableData && (
          <>
            <MetricsItem>
              {metrcis.map((metric) => (
                <MetricItem
                  key={metric.metricName}
                  description={metric.description}
                  icon={metric.icon}
                  iconBgColor={metric.iconBgColor}
                  id={metric.metricName}
                  isActive={metric.isActive}
                  metricName={metric.metricName}
                  numberAndIconColor={metric.numberAndIconColor}
                  value={metric.value}
                />
              ))}
            </MetricsItem>

            <EntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                rows,
                pageOptions,
                pageIndex,
                gotoPage
              }}
              handleShowDetails={handleShowShopDetails}
            />
          </>
        )}
      </ShopsWrap>
    </ShopsDashboardWrap>
  )
}

export default ManageOrderProducts

ManageOrderProducts.propTypes = {}
