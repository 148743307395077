import React from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import { some } from 'lodash/collection'
import {
  Switch,
  SwitchBody,
  SwitchFalseLabel,
  SwitchKnob,
  SwitchLabel
} from './styles'

const SwitchInput = ({ inputName, label, value, falseLabel, labelVariant }) => {
  const [field, meta] = useField({
    name: inputName,
    type: 'checkbox',
    multiple: true
  })
  const { name, onBlur, onChange, multiple } = field
  return (
    <Switch htmlFor={value}>
      {falseLabel && (
        <SwitchFalseLabel variant="textM" isActive={meta.value[0] !== value}>
          {falseLabel}
        </SwitchFalseLabel>
      )}
      <SwitchBody isActive={some(meta.value, (item) => item === value)}>
        <SwitchKnob isActive={some(meta.value, (item) => item === value)} />
      </SwitchBody>
      <input
        hidden
        type="checkbox"
        id={value}
        value={value}
        {...{ name, onBlur, onChange, multiple }}
      />
      <SwitchLabel variant={labelVariant}>{label ?? 'Test label'}</SwitchLabel>
    </Switch>
  )
}

export default SwitchInput

SwitchInput.defaultProps = {
  label: null,
  falseLabel: null,
  labelVariant: "textM"
}

SwitchInput.propTypes = {
  inputName: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  falseLabel: PropTypes.string,
  labelVariant: PropTypes.string
}
