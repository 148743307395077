import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { isNull } from 'lodash/lang'
import { keys } from 'lodash/object'
import TextInput from '../../../components/UI/Inputs/TextInput'
import Typography from '../../../components/UI/Typography'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button/Button'
import SelectMarker from '../../../components/UI/SelectMarker'
import { InputError } from '../../../components/UI/Inputs/TextInput/styles'
import { ORGANIZATION_ICONS } from '../../../assets/OrganizationIcon/OrganizationIcon'
import ShowErrorModal from '../../../components/ShowErrorModal'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import { useLazyGetOrganizationQuery } from '../../../api/api.generated'
import { HStack } from '../../../global/styles'
import {
  ButtonWrap,
  Headline,
  ModalBody,
  ErrorWrap,
  IconsWrap,
  Label
} from './styles'

const AddOrganizationModal = ({
  isOpen,
  isCreate,
  organizationId,
  handleCreateNew,
  handleCloseAddNewModal,
  addingNewOrganization,
  error
}) => {
  const emptyOrganization = {
    name: '',
    icon: ''
  }

  const [getOrganization, { data: organizationData, error: loadingError }] = useLazyGetOrganizationQuery()
  const [isSuccess, setIsSuccess] = useState(false)
  const [initialData, setInitialData] = useState(emptyOrganization)

  const title = (isNull(isCreate)) ? 'Add Organization' : 'Edit Organization';

  useEffect(() => {
    if (!isCreate) {
      getOrganization(organizationId)
    } else {
      setIsSuccess(true)
    }
  }, [])
  
  useEffect(() => {
    if (organizationData) {
      setInitialData({
        name: organizationData.name,
        icon: organizationData.icon,
      })
      setIsSuccess(true)
    }
  }, [organizationData])  

  return (
    <Modal {...{ isOpen }}>
      {!isSuccess && !loadingError &&
        <LoadingSpinner />
      }
      {loadingError && 
        <ShowErrorModal
          title={title}
          error="Network Error"
          onClose={handleCloseAddNewModal}
        />
      }                
    {isSuccess && (
    <ModalBody>
      <Formik
          initialValues={initialData}
          validationSchema={Yup.object({
            name: Yup.string().required('Name is required'),
            icon: Yup.string().required('Please select icon'),
          })}
          onSubmit={handleCreateNew}
        >
          {({ errors, touched }) => (
          <Form>
            <Headline>{title}</Headline>
            <TextInput
              label="Name"
              placeholder="Enter name"
              inputName="name"
              size={INPUT_SIZES.SMALL}
            />
            <IconsWrap>
              <Label> Select Icon </Label>
              <HStack justify="flex-start">
                {keys(ORGANIZATION_ICONS).map((icon) => (
                  <SelectMarker icon={icon} key={icon} name="icon" />
                ))}
              </HStack>
              <InputError>{errors && touched.icon ? errors.icon : null}</InputError>
            </IconsWrap>
            {error && 
              <ErrorWrap>
                <Typography variant="textS" color="red">
                {error}
                </Typography>
              </ErrorWrap>          
            }
            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.MED}
                type="submit"
                isLoading={addingNewOrganization}
              >
                Save
              </Button>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTONS_SIZES.MED}
                onClick={handleCloseAddNewModal}
                type="button"
              >
                Cancel
              </Button>
            </ButtonWrap>
          </Form>
          )}
        </Formik>
      </ModalBody>
      )}
    </Modal>
  )
}

export default AddOrganizationModal


AddOrganizationModal.defaultProps = {
  organizationId: null
}

AddOrganizationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isCreate: PropTypes.bool.isRequired,
  organizationId: PropTypes.string,
  handleCreateNew: PropTypes.func.isRequired,
  handleCloseAddNewModal: PropTypes.func.isRequired,
  addingNewOrganization: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired
}
