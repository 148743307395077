import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  useLazyGetOrganizationQuestionStoresQuery,
  useDeleteOrganizationQuestionsToShopsMutation  
} from '../../api/api.generated'
import { getErrorMessage } from '../../utils/helpers'
import ViewStoresModal from '../ViewStoresModal'

const ViewOrganizationQuestionStoresModal = ({ organizationId, questionId, onClose }) => {
  const [ getOrganizationQuestionStores, { data: shopsTableData, isLoading } ] = useLazyGetOrganizationQuestionStoresQuery()
  const [deleteQuestions] = useDeleteOrganizationQuestionsToShopsMutation()
  const [error, setError] = useState('')

  const loadStores = (pageIndex, pageSize, search) => {
    setError('')
    getOrganizationQuestionStores({ questionId, page: pageIndex, limit: pageSize, search })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  const deleteFromStores = (selectedRowIds) => {
    setError('')
    deleteQuestions({ body: {
      organizationId,
      organizationQuestionIds: [questionId],
      shopIds: selectedRowIds
    } })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })      
  }

  return (
    <ViewStoresModal
      title="Post Questions"
      shopsTableData={shopsTableData}
      isLoading={isLoading}     
      error={error}
      onLoad={loadStores} 
      onDelete={deleteFromStores} 
      onClose={onClose} 
    />
  )
}

export default ViewOrganizationQuestionStoresModal

ViewOrganizationQuestionStoresModal.defaultProps = {}

ViewOrganizationQuestionStoresModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}
