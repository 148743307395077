import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Mail, TaskAlt } from '@styled-icons/material'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { AuthFormWrap, AuthWelcomeMessage } from '../../../global/styles'
import TextInput from '../../../components/UI/Inputs/TextInput'
import { BUTTONS_SIZES, INPUT_SIZES } from '../../../const/UIvariants'
import OphopLogo from '../../../assets/icons/OphopLogo'
import Button from '../../../components/UI/Buttons/Button'
import Typography from '../../../components/UI/Typography'
import LinkButton from '../../../components/UI/Buttons/LinkButton'
import ROUTES from '../../../const/routes'
import { setWelcomeMessage } from '../authSlice'
import { RegisterWrap } from './styles'
import { useForgotPasswordMutation } from '../../../api/api.generated'
// import { useForgotPasswordMutation } from '../../../api/authApi'

const ForgotPassword = ({ theme }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  
  const [requestPasswordReset] = useForgotPasswordMutation()
  useEffect(() => {
    dispatch(setWelcomeMessage('Forgot password for'))
  }, [])
  useEffect(() => {
    if (isSuccess) {
      dispatch(setWelcomeMessage('Check your email!'))
    }
  }, [isSuccess])
  const submitRestorePasswordRequest = (values) => {
    setIsSuccess(true)
    requestPasswordReset(values)
  }
  return (
    <Formik
      initialValues={{
        email: ''
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is required')
      })}
      onSubmit={submitRestorePasswordRequest}
    >
      <AuthFormWrap>
        <OphopLogo fill={theme.colors.skyBase} />
        <AuthWelcomeMessage variant="textM" color={theme.colors.skyNeutral_1}>
          Enter the email address associated with your account
        </AuthWelcomeMessage>
        <TextInput
          type="email"
          placeholder="Enter your Email"
          leftIcon={<Mail />}
          size={INPUT_SIZES.LARGE}
          inputName="email"
          label="Email"
        />

        <Button isLoading={false} size={BUTTONS_SIZES.LARGE} type="submit" disabled={isSuccess}>
         
           { isSuccess ?  <TaskAlt color='blue' size={24} /> : 'Send Request' }
        </Button>
        <RegisterWrap justify="center" alignItems="center">
          <Typography variant="textS" color={theme.colors.skyNeutral1}>
            Already remember the password?
          </Typography>
          <LinkButton
            onClick={() => {
              history.push(ROUTES.LOGIN)
            }}
            type="button"
          >
            Login
          </LinkButton>
        </RegisterWrap>
      </AuthFormWrap>
    </Formik>
  )
}

export default withTheme(ForgotPassword)

ForgotPassword.propTypes = {
  theme: PropTypes.object.isRequired
}
