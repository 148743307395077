// button const
export const BUTTONS_SIZES = {
  SMALL: 32,
  MED: 48,
  LARGE: 64
}
export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  OUTLINED: 'outlined'
}
export const BUTTON_LABEL_SIZE = {
  [BUTTONS_SIZES.LARGE]: 'textL',
  [BUTTONS_SIZES.MED]: 'textM',
  [BUTTONS_SIZES.SMALL]: 'textS'
}

// input const
export const INPUT_SIZES = {
  LARGE: 56,
  SMALL: 44
}

// badge const
export const BADGE_SIZES = {
  SMALL: 28,
  LARGE: 32
}

export const BADGE_VARIANTS = {
  DARK: 'dark',
  LIGHT: 'light',
  GREEN: 'green',
  GOLD: 'gold',
  PINK: 'pink'
}
export const USER_AVATAR_SIZES = {
  SMALL: 30,
  MED: 40,
  LARGE: 54,
  EXTRA_LARGE: 112
}

export const WIZARD_LIST_ITEM_VARIANTS = {
  NUMBER: 'NUMBER',
  CHECK: 'CHECK'
}
