import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  useGetOrganizationQuestionQuery,
  useDeleteOrganizationQuestionMutation
} from '../../../api/api.generated'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../../const/UIvariants'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Button from '../../../components/UI/Buttons/Button/Button'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import Typography from '../../../components/UI/Typography'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsInfoBlock
} from '../../../global/styles'
import {
  ButtonWrap,
  PostDetailsWrap
} from './styles'

const QuestionDetails = ({ activeQuestionId, closePanel }) => {
  const [error, setError] = useState('')
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const { data: questionData, isLoading, error: isQuestionError } = useGetOrganizationQuestionQuery(activeQuestionId)
  const [deleteQuestion, { isLoading: deletingQuestion }] = useDeleteOrganizationQuestionMutation()

  const displayError = (err) => {
    if (err instanceof Error) {
      setError(err.message)
    } else if (err instanceof Object) {
      let message = '';
      Object.keys(err).forEach((key) => {
        message += `${err[key]} `;
      });
      setError(message)
    } else {
      setError('Network error')
    }    
  }

  const toggleConfirmationModal = () => {
    setShowConfirmDelete((prev) => !prev)
  }

  const handleDeleteQuestion = () => {
    deleteQuestion({ questionId: activeQuestionId })
      .unwrap()
      .then(() => {
        closePanel()
      })
      .catch((err) => {
        toggleConfirmationModal()
        displayError(err);
      })      
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {isQuestionError && 
        <Typography variant="textS" color="red">Network Error</Typography>
      }        
      {questionData && (
        <>
          <PostDetailsWrap>
            <AsideDetailsTitle>Details</AsideDetailsTitle>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
              Organisation
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {questionData.organizationName}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Question</AsideDetailsLabel>
              <AsideDetailsDescription>
                {questionData.question}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Answer</AsideDetailsLabel>
              <AsideDetailsDescription>
                {questionData.answer}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTONS_SIZES.MED}
                onClick={toggleConfirmationModal}
                isLoading={deletingQuestion}
                isFullWidth
              >
                Delete
              </Button>
            </ButtonWrap>
            {error && 
              <Typography variant="textS" color="red">
              {error}
              </Typography>
            }            
          </PostDetailsWrap>
          <DeleteConfirmationModal
            isOpen={showConfirmDelete}
            onClose={toggleConfirmationModal}
            entityName={questionData.question}
            entityType="FAQ"
            handleDelete={handleDeleteQuestion}
          />
        </>
      )}
    </>
  )
}

export default QuestionDetails

QuestionDetails.defaultProps = {
  activeQuestionId: null
}

QuestionDetails.propTypes = {
  activeQuestionId: PropTypes.number,
  closePanel: PropTypes.func.isRequired
}
