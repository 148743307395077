import React from 'react'
import { useParams } from 'react-router-dom'
import ShopPosts from '../../shop/ShopPosts'

const ManageShopPosts = () => {
  const { shopId } = useParams()

  return (
    <ShopPosts shopId={shopId}/>
  )
}

export default ManageShopPosts

ManageShopPosts.propTypes = {}
