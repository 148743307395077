import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { isEmpty } from 'lodash/lang'
import { withTheme } from 'styled-components'
import { useGetCategoriesQuery } from '../../../api/generalApi'
import {
  useGetShopDonationsQuery,
  usePostShopDonationsMutation
} from '../../../api/shopDonationsApi'
import { getErrorMessage } from '../../../utils/helpers'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import SelectManyInput from '../../../components/UI/Inputs/SelectManyInput'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { ErrorMessageWrap } from '../../../global/styles'
import {
  InputsWrap,
  StyledForm,
  ActionButtonsSection,
  ButtonWrap
} from './styles'

const EditShopDonationsModal = ({ isOpen, onClose, theme, shopId }) => {
  const [initialData, setInitialData] = useState({ categories: [] })
  const [error, setError] = useState('')
  const { data: shopDonations, isLoading } = useGetShopDonationsQuery(shopId)
  const { data: categories } = useGetCategoriesQuery()
  const [updateShopDonations, { isLoading: updatingDonations }] = usePostShopDonationsMutation()

  useEffect(() => {
    if (shopDonations && !isEmpty(shopDonations.categories)) {
      setInitialData({
        categories: shopDonations.categories.map((category) =>
          category.toString()
        )
      })
    }
  }, [shopDonations, isLoading])

  const handleSubmit = (values) => {
    updateShopDonations({ data: { ...values, shopId } })
      .unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })      
  }

  return (
    <Modal {...{ isOpen }}>
      <Formik
        initialValues={initialData}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        <StyledForm>
          <InputsWrap>
            <Typography variant="headingM" color={theme.colors.skyNeutral_2}>
              Edit Store Donations
            </Typography>
            {categories && (
              <SelectManyInput
                options={categories.map((category) => ({
                  value: category.id,
                  label: category.tagName
                }))}
                label="Store Donations"
                inputName="categories"
              />
            )}
          </InputsWrap>
          {error && 
            <ErrorMessageWrap>
              <Typography variant="textS" color="red">
              {error}
              </Typography>
            </ErrorMessageWrap>          
          }           
          <ActionButtonsSection>
            <ButtonWrap>
              {updatingDonations ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Button
                    type="submit"
                    variant={BUTTON_VARIANTS.PRIMARY}
                    size={BUTTONS_SIZES.MED}
                  >
                    Update
                  </Button>
                  <Button
                    type="button"
                    variant={BUTTON_VARIANTS.SECONDARY}
                    size={BUTTONS_SIZES.MED}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </ButtonWrap>
          </ActionButtonsSection>
        </StyledForm>
      </Formik>
    </Modal>
  )
}

export default withTheme(EditShopDonationsModal)

EditShopDonationsModal.defaultProps = {
  shopId: null
}

EditShopDonationsModal.propTypes = {
  theme: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shopId: PropTypes.string
}
